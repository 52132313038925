import * as React from "react"
import Rating from "@mui/material/Rating"

export default function StarRating() {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Rating
        name="read-only"
        value={4.9}
        precision={0.5}
        style={{ marginTop: "10px" }}
        readOnly
      />

      <h4 style={{ marginTop: "10px" }}>4.9/5</h4>
    </div>
  )
}
