"use client"
import React, { useState } from "react"
import "./bookTherapy.css"
import Button from "react-bootstrap/Button"
import AppDownloadModal from "../App_Download_Modal"
import ReactGA from 'react-ga4';


export function BookTherapy(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    
    
    
    
    setShow(true);
  
   

      ReactGA.event({
        category: 'student_wellness_book_session_button',
        action: 'Click',
        label: window.location
        
    });
    
  
  
  
  }

  return (
    <>
      <AppDownloadModal
        show={show}
        handleClose={handleClose}
        heading="Discover the right treatment for your mental health journey with personalized recommendations from our Mental Health Coaches."
      />
      <div className="book-therapy">
        <div className="row">
          <div className="col-md-6" style={{ margin: "6% 0" }}>
            <h2>Book a session now</h2>
            <p>
              Connect with 30+ mental health experts in 10+ languages, and find the
              perfect match for your needs.
            </p>
            <Button
              variant="success"
              onClick={() => handleShow()}
              size="lg"
              style={{
                backgroundColor: "#E77C40",
                borderColor: "transparent",
                borderRadius: "20px",
                marginTop: "6%",
                fontSize: "1.1rem",
              }}
            >
              Book Session
            </Button>
          </div>
          <div className="col-md-6 therapists-group-img">
            <img
              loading="lazy"
              className="col-md-6 "
              src="/img/personal-wellness/therapists-group.png"
              alt="assessment feature"
            />
          </div>
        </div>
      </div>
    </>
  )
}
