import "./styles.css"

import React from "react"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import ReactGA from 'react-ga4';


export function Discover(props) {
  return (
    <>
      <div className="discover">
        <h2 className="discover-header">
          Built by <span>self-care experts</span>
        </h2>
        <p className="discover-subheading">
          Everything on the WellM app is carefully curated by qualified therapists
          and psychiatrists, based on extensive research and science.
          <br /> Explore our library of resources containing articles, videos,
          quotes, tips, recommendations, and activities for your mental health.
        </p>
        <div className="row discover-main-points" style={{ marginTop: "8%" }}>
          <div className="col-md-4">
            <div className="row">
              <div className="col-4">
                <img
                  src="/img/personal-wellness/moon-sleep.png"
                  alt="night"
                  className="dicover-icon"
                />
              </div>
              <div className="col-8 discover-type">
                Get more
                <br />
                goodnights
              </div>
            </div>
            <p className="discover-description">
              Access our entire library of meditations, sleep sounds, yoga and
              fitness, and focus music.
            </p>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-4">
                <img
                  src="/img/personal-wellness/joy_icon.png"
                  alt="night"
                  className="dicover-icon"
                />
              </div>
              <div className="col-8 discover-type">Find more joy</div>
            </div>
            <p className="discover-description">
              Catch your breath, relax your mind, and feel 14% less stressed in just
              10 days.{" "}
            </p>
          </div>
          <div className="col-md-4">
            <div className="row ">
              <div className="col-4 ">
                <img
                  src="/img/personal-wellness/meditate.png"
                  alt="night"
                  className="dicover-icon"
                />
              </div>
              <div className="col-8 discover-type">
                Make every day
                <br /> happier
              </div>
            </div>
            <p className="discover-description">
              Do it for yourself, and everyone you love. It only takes a few minutes
              to find some headspace.{" "}
            </p>
          </div>
        </div>
        <p className="discover-content-heading">
          Try one of our <span>audio activities</span> right here
        </p>
        <div
          className="row discover-item-suggestion"
          style={{ width: "70%", margin: "2% auto" }}
        >
          <div
            className="col-md-6"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <div className="row">
              <div className="col-2 dicover-content-icon-container">
                <img
                  src="/img/personal-wellness/headphones.png"
                  alt="headphones"
                  className="dicover-content-icon"
                />
              </div>
              <div className="col-10" style={{ paddingLeft: "6%" }}>
                Wear earphones to enhance your experience
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <div className="row">
              <div className="col-2">
                <img
                  src="/img/personal-wellness/meditation.png"
                  alt="headphones"
                  className="dicover-content-icon"
                />
              </div>
              <div className="col-10" style={{ paddingLeft: "6%" }}>
                Find a quiet and distraction-free space
              </div>
            </div>
          </div>
        </div>
        <AudioPlayer
          src="https://firebasestorage.googleapis.com/v0/b/covid19-coupon-game.appspot.com/o/open-files%2Fwellm-app%2Fjoycare_plan%2Femotional%20experience%2FProgressive%20Muscle%20Relaxation.mp4?alt=media&token=b1301d1f-b4d3-43b5-84b3-211c83ed6d3e"
          onPlay={(e) =>{
            
            console.log("onPlay");

            ReactGA.event({
              category: 'personal_wellness_audio_play_button',
              action: 'Click',
              label: window.location
              
          });
          
          
          } }
          header={
            <div className="dicover-audio-heading">
              Progressive Muscle Relaxation{" "}
            </div>
          }
          // other props here
        />
        {/* <Player url="" /> */}
      </div>
    </>
  )
}
