"use client"
import React from "react"
import "./hero.css"
import Button from "react-bootstrap/Button"
import { HashLink } from "react-router-hash-link"
import ReactGA from 'react-ga4';


export function PersonalWellnessHero(props) {

const onExploreClick= event =>{

  ReactGA.event({
    category: 'personal_wellness_hero_explore_button',
    action: 'Click',
    label: window.location
    
});





}

  return (
    <>
      <div className="personal-wellness-hero-section">
        <div className="container p-md-5 p-3 align-middle">
          <h2 className="heading2">Welcome to</h2>
          <h1 className="heading1">Personal Wellness</h1>

          <p className="subheading">
            Take control of your mental health journey with WellM's personalized
            solutions at your fingertips.
          </p>
          <div className="div-9">
            <Button
              variant="success"
              size="lg"
              style={{
                backgroundColor: "#E77C40",
                borderColor: "transparent",
                borderRadius: "8px",
                
              }}
              onClick={onExploreClick}
            >
              <HashLink
                smooth
                to="/personal-wellness#explore-pw"
                style={{ color: "white", textDecoration: "none" }}
                
              >
                Explore
              </HashLink>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
