"use client"
import React from "react"
import "./styles.css"

export function CorporateWellnessReasonSection(props) {
  return (
    <>
      <div className="container reasons-section" id="explore-cw">
        <div className="div">
          <div className="reasons-section-subheading1">
            Did you know that a substantial 86% of employees across India experience
            mental turmoil? This not only affects the well-being of employees but
            also poses challenges for companies, including
          </div>

          <div className="reasons-section-reasons-list">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-4  col-6 reasons-section-reason">
                <img
                  src="/img/absenteeism.png"
                  style={{ backgroundColor: "#DBEDF1" }}
                  alt=""
                />
                <h3 className="reasons-section-subheading2">
                  Increased absenteeism
                </h3>
              </div>
              <div className="col-md-4  col-6 reasons-section-reason">
                <img
                  src="/img/productivity.png"
                  style={{ backgroundColor: "#DCBFE6" }}
                  alt=""
                />
                <h3 className="reasons-section-subheading2">Reduced productivity</h3>
              </div>
              <div className="col-md-4 col-6 reasons-section-reason">
                <img
                  src="/img/turnover.png"
                  style={{ backgroundColor: "#DFF1DB" }}
                  alt=""
                />
                <h3 className="reasons-section-subheading2">Increased turnover</h3>
              </div>
            </div>
            <div
              className="row"
              style={{ justifyContent: "center", marginTop: "6%" }}
            >
              <div className="col-md-4 col-6 reasons-section-reason">
                <img
                  src="/img/reputation_damage.png"
                  style={{ backgroundColor: "#FEFFCB" }}
                  alt=""
                />
                <h3 className="reasons-section-subheading2">Damage to reputation</h3>
              </div>
              <div className="col-md-4 col-6 reasons-section-reason">
                <img
                  src="/img/safety_risk.png"
                  style={{ backgroundColor: "#FFDECB" }}
                  alt=""
                />
                <h3 className="reasons-section-subheading2">
                  Increased safety risks
                </h3>
              </div>
            </div>
          </div>

          <div className="reasons-section-subheading1">
            Sadly, 39% of companies have yet to acknowledge this pressing issue. At
            WellM, we understand the far-reaching implications of these challenges on
            both employees and the companies. 
            <br />
            This recognition has driven us to craft a solution that addresses the
            mental crisis and provides comprehensive support to mitigate its
            effects. 
          </div>
        </div>
      </div>
    </>
  )
}
