import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import "./styles.css"
import Form from "react-bootstrap/Form"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactGA from 'react-ga4';


export function Newsletter(props) {
  const [validated, setValidated] = useState(false)

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  const handleSubmit = async (event) => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      event.preventDefault()
    }
    setValidated(true)
    event.stopPropagation()
    event.preventDefault()

    if (form.checkValidity()) {
      await delay(1000)
      toast("Congratulations, you have successfully subscribed to our newsletters")
      ReactGA.event({
        category: 'newsletter_subscribe_button_onSuccess',
        action: 'Click',
        label: window.location
        
    });
    }
    

    
  }

  return (
    <>
      <div className="newsletter">
        <div className="row">
          <div className="col-md-3">
            <img loading="lazy" src="/img/newsletter.png" alt="" />
          </div>
          <div className="col-md-4" style={{ margin: "auto" }}>
            <h2 className="newsletter-heading">Subscribe to our newsletter</h2>
            <p className="newsletter-subheading">
              Our newsletters deliver valuable insights, expert tips, and the latest
              updates on WellM. Stay in the know for better mental health.
            </p>
          </div>
          <div className="col-md-5">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="email-input" style={{ marginBottom: "16px" }}>
                <Form.Group className="emailInputBox" controlId="contactForm.name">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Your email address"
                    id="typeEmail"
                    style={{
                      borderColor: "transparent",
                      borderRadius: "10px",
                      height: "6.2vh",
                      fontSize: "16px",
                      backgroundColor: "white",
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="subscribe-button" style={{ margin: "auto" }}>
                <Button
                  variant="primary"
                  size="lg"
                  style={{
                    borderRadius: "10px",
                    height: "6vh",
                    fontSize: "16px",
                    borderColor: "transparent",
                    backgroundColor: "#274C5B",
                  }}
                  type="submit"
                >
                  Subscribe
                </Button>{" "}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}
