import React, { Component } from "react";
import { FeatureGeneratedCode } from "./FeatureGeneratedCode";


class FeaturesTop extends Component {
  render() {
    return (
      <section className="features-top">
        <FeatureGeneratedCode />
      </section>
    );
  }
}

export default FeaturesTop;
