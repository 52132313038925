"use client";
import React from "react";
import "./hero.css";
import Button from "react-bootstrap/Button";
import { HashLink } from "react-router-hash-link";
import ReactGA from 'react-ga4';


export function StudentWellnessHeroSection(props) {

  const onExploreClick= event =>{

    ReactGA.event({
      category: 'student_wellness_hero_explore_button',
      action: 'Click',
      label: window.location
      
  });

}
  
  return (
    <>
      <div className="student-wellness-hero-section">
        <div className="container align-middle">
          <h2 className="heading2">Welcome to</h2>
          <h1 className="heading1">Student Wellness</h1>
          <p className="subheading">
            Supporting Students on Their Journey to Mental Well-Being with WellM's Personalized Solutions.{" "}
          </p>

          <div className="div-9" style={{ marginTop: "10%" }}>
            <Button
              variant="success"
              size="lg"
              style={{
                backgroundColor: "#E77C40",
                borderColor: "transparent",
                borderRadius: "8px",
                padding: "0",
                
              }}
              onClick={onExploreClick}
            >
              <HashLink smooth to="/student-wellness#explore-sw" style={{ color: "white", textDecoration: "none" }}>
                Explore
              </HashLink>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
