import React, { Component } from "react"
import { StudentWellnessHeroBelow } from "../components/partComponents/studentWellness/part1"
import { StudentWellnessHeroSection } from "../components/SW_Hero_Section/hero"
import { BookTherapy } from "../components/Book_Therapy/bookTherapy"
import "./studentWellness.css"
import { Testimonial } from "../components/Testimonial/testimonial"
import { AppDownload } from "../components/App_Download/app_download"
import { TherapyStatistics } from "../components/Therapy_Statistics/therapyStatistics"
import { Newsletter } from "../components/Newsletter"

class StudentWellness extends Component {
  render() {
    return (
      <div>
        <StudentWellnessHeroSection />
        <StudentWellnessHeroBelow />
        <TherapyStatistics heading="Our impact on Gen Z's" />

        <BookTherapy />
        <div className="container community">
          {" "}
          <div className="community-header">
            What is WellM <span className="gradient-text">Community?</span>
          </div>
          <p className="community-subheader">
            We're a judgement-free, virtual support group for different mental health
            concerns where members support each other on their mental health
            journeys.
          </p>
          <div className="row community-row community-row-first">
            <div className="col-md-6">
              <div className="row">
                <div className="col-2">
                  <img src="/img/community_people.png" alt="" />
                </div>
                <div className="col-10">
                  <h3>Connect</h3>
                  <p>with 3,000+ peers who can support and listen to you </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-2">
                  <img src="/img/social-media.png" alt="" />
                </div>
                <div className="col-10">
                  <h3>Share</h3>
                  <p>your story and talk about your struggles, freely & openly.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row community-row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-2">
                  <img src="/img/trust.png" alt="" />
                </div>
                <div className="col-10">
                  <h3>Build</h3>
                  <p>a safe space where people can grow together</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-2">
                  <img src="/img/cope.png" alt="" />
                </div>
                <div className="col-10">
                  <h3>Cope</h3>
                  <p>with life’s challenges and share your advice with others.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row community-info">
            <div className="col-md-4 community-info-box">
              <div className="community-info-box-inner">
                <img src="/img/secure.png" alt="" />
                <h3 className="community-info-heading">
                  Students chats are 100% anonymous, confidential and won’t be shared
                  with anyone.
                </h3>
              </div>
            </div>
            <div className="col-md-4 community-info-box">
              <div className="community-info-box-inner">
                <img src="/img/risk.png" alt="" />

                <h3 className="community-info-heading">
                  Mechanism to report risk escalation to concerned authorities of
                  college in case of emergency situations.
                </h3>
              </div>
            </div>
            <div className="col-md-4 community-info-box">
              <div className="community-info-box-inner">
                <img src="/img/free-counselling.png" alt="" />

                <h3 className="community-info-heading">
                  Students gets free mental health counselling calls with our
                  certified experts 24/7.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <Testimonial
          testimonials={[
            `I can't thank WellM enough for helping me manage the stress of exams and deadlines. It's a game-changer for anyone struggling with anxiety. It's now a must-have in my student toolkit.`,
            `WellM has made a noticeable difference in our students' well-being. It's an invaluable addition to our support system. Mental health matters, and WellM proves it.`,
            `The social support features of WellM are fantastic. It's great to connect with fellow students who share similar challenges. It's a safe space for personal growth and healing.`,
            `WellM's guided meditations have been a blessing for me. They've transformed my ability to focus and stay calm in high-pressure situations. This app is a true friend to college students.`,
            `I've recommended WellM to my students as a valuable mental health resource. Its self-assessment tools and coping strategies are a game-changer for college life.`,
          ]}
          author={[
            "3rd year student",
            "Student Body Advisor",
            "PHD Student",
            "2nd year student",
            "Psychology Faculty",
          ]}
        />
        <AppDownload />
        <Newsletter />
      </div>
    )
  }
}

export default StudentWellness
