"use client"
import React from "react"
import "./hero.css"
import Button from "react-bootstrap/Button"
import { HashLink } from "react-router-hash-link"
import ReactGA from 'react-ga4';


export function CorporateWellnessHero(props) {
  const onExploreClick= event =>{

    ReactGA.event({
      category: 'corporate_wellness_hero_explore_button',
      action: 'Click',
      label: window.location
      
  });
  
  
  
  
  
  }
  return (
    <>
      <div className="corporate-wellness-hero-section">
        <div className="container align-middle">
          <h2 className="heading2">Welcome to</h2>
          <h1 className="heading1">Corporate Wellness</h1>

          <p className="subheading">
            Take care of your employess mental health <br /> journey with WellM's
            corporate program.
          </p>
          <div className="div-9" style={{ marginTop: "10%" }}>
            <Button
              variant="success"
              size="lg"
              style={{
                backgroundColor: "#E77C40",
                borderColor: "transparent",
                borderRadius: "8px",

              }}
              onClick={onExploreClick}
            >
              <HashLink
                smooth
                to="/corporate-wellness#explore-cw"
                style={{ color: "white", textDecoration: "none" }}
              >
                Explore
              </HashLink>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
