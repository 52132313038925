"use client"
import React, { useState } from "react"
import "./style.css"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import ReactGA from 'react-ga4';


export function ContactUsModal({ handleClose, show, heading }) {
  const [validated, setValidated] = useState(false)

  const handleSubmit = async (event) => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      event.preventDefault()
    } else {
      event.preventDefault()
      event.stopPropagation()
      const formData = {
        name: form[0].value,
        companyName: form[3].value,
        email: form[1].value,
        phone: form[2].value,
        message: form[4].value,
      }

      await axios
        .post(
          "https://us-central1-covid19-coupon-game.cloudfunctions.net/authentication/send-email-contactus",
          formData
        )
        .then((message) => {
          console.log("Message to be shown ---- ", message)
        })
        .catch((error) => {
          console.log("Error to be shown ---- ", error)
        })


        ReactGA.event({
          category: 'contact_us_form_submit_button',
          action: 'Click',
          label: window.location
          
      });


      toast(
        "Your query has been successfully submited, our team will get back to you shortly."
      )
      handleClose()
    }

    setValidated(true)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>

        <Modal.Body className="container-fluid">
          <h2 className="contact-us-modal-heading">Contact Us</h2>
          <h4 className="contact-us-modal-subheading">{heading}</h4>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="contactForm.email">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="John Doe"
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your full name
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactForm.name">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="name@example.com"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="contactForm.phone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control required type="text" placeholder="" />
                  <Form.Control.Feedback type="invalid">
                    Please enter your phone number
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactForm.query">
                  <Form.Label>Topic</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.message">
              <Form.Label>Message</Form.Label>
              <Form.Control required as="textarea" rows={3} />
              <Form.Control.Feedback type="invalid">
                Please enter your message
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              style={{
                backgroundColor: "#e77c40",
                border: "none",
              }}
              type="submit"
            >
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="liveToast"
          className="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <img src="..." className="rounded me-2" alt="..." />
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div className="toast-body">Hello, world! This is a toast message.</div>
        </div>
      </div>
    </>
  )
}

export default ContactUsModal
