"use client";
import React from "react";
import "./style.css";

export function Disclaimer(props) {
  return (
    <>
      <section id="legal" className="head">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div clasName="col-lg-12 col-md-12 text-center">
              <h2>DISCLAIMER</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <ol className="legalPoints">
                <li>
                  Your correspondence or business dealings with, or participation in promotions of, advertisers found on
                  or through the service or of vendors on the marketplace, including payment and delivery of related
                  goods or services, and any other terms, conditions, warranties or representations associated with such
                  dealings, are solely between you and such advertiser or vendor. You agree that, save as set out
                  herein, wellness mandala shall not be responsible or liable for any loss or damage of any sort
                  incurred as the result of any such dealings or as the result of the presence of such advertisers or
                  the vendors on the services/the marketplace.
                </li>
                <li>
                  The services may provide, or third parties may provide, links to other web sites or resources. Given
                  that we have no control over such sites and resources, you acknowledge and agree that we are not
                  responsible for the availability of such external sites or resources, and do not endorse and are not
                  responsible or liable for any content, advertising, products or other materials on or available from
                  such sites or resources. You further acknowledge and agree that we shall not be responsible or liable,
                  directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
                  use of or reliance on any such content, goods or services available on or through any such site or
                  resource.
                </li>
                <li>
                  You agree that your use of the website/app/marketplace and the services shall be at your sole risk.
                  You expressly understand and agree that, to the maximum extent permitted by applicable law, the
                  website/app/marketplace and the services are provided on an “as is” and “as available” basis for your
                  use, without warranties of any kind, express or implied, including without limitation the warranties
                  of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from
                  course of dealing or usage of trade. Wellness mandala shall use all reasonable endeavors to ensure
                  that the services are uninterrupted but it does not guarantee or warrant that: (i) the services will
                  meet your specific requirements; (ii) the services will be uninterrupted, timely, secure, or
                  error-free; (iii) the results that may be obtained from the use of the services will be accurate or
                  reliable; and (iv) the quality of any products, services, information, or other material purchased or
                  obtained by you through the services will meet your expectations; and (v) any errors in the services
                  will be corrected.
                </li>
                <li>
                  It is not advised for certain individuals with certain conditions to take the Vital-Care assessment.
                  They are as follows: Pregnant women, individuals with metal/stent/pacemaker insertions, individuals
                  with dermatological lesions or calluses in contact with the electrodes, or excessive perspiration,
                  individuals with an absence of one or more limbs, etc.
                </li>
                <li>
                  It is not advised for certain individuals with certain conditions to take the Vital-Care assessment as
                  the report might not be generated, may be inaccurate or the machine itself might not work. This is
                  specifically for elderly patients or subjects with a weak pulse due to shock, low ambient/body
                  temperature, major bleeding, or use of a vascular contracting drug, the SpO2 waveform will be
                  decreased or absent.
                </li>
                <li>
                  If you are regularly taking any medicine please be in touch with your medical practitioner as you may
                  require an adjustment in your medical treatment.
                </li>
                <li>
                  You should discontinue exercise in cases where it causes pain or severe discomfort, and should consult
                  a medical expert prior to returning to exercise in such cases. We reserve the right to deny you access
                  to the services for any reason or no reason, including if we determine, in our sole discretion, that
                  you have certain medical conditions.
                </li>
                <li>
                  The content of the website/app/marketplace, including without limitation, text, copy, audio, video,
                  photographs, illustrations, graphics and other visuals, is for informational purposes only and does
                  not constitute/substitute professional medical advice, diagnosis, treatment or recommendations of any
                  kind. You should always seek the advice of your qualified healthcare professionals with any questions
                  or concerns you may have regarding your individual needs and any medical conditions. You agree that
                  you will not under any circumstances disregard any professional medical advice or delay in seeking
                  such advice in reliance on any content provided on or through the website/app/marketplace. Reliance on
                  any such content is solely at your own risk.
                </li>
                <li>
                  The content provided on or through this website/ app regarding dietary supplements or products for
                  sale on the marketplace are not necessarily evaluated/approved by regulatory authorities.
                </li>
                <li>
                  You expressly acknowledge and agree that you are installing and using the app at your own risk. The
                  app and all data and content provided through the app is provided “as is”, “with all faults” and
                  without warranty, terms or conditions of any kind. The company and each of its licensees, affiliates
                  and authorized representatives (the “parties” or “party’) expressly and specifically disclaim all
                  warranties, terms or conditions, whether express or implied, including, but not limited to, any
                  implied warranties of merchantability, satisfactory quality, non-infringement and fitness for a
                  general or particular purpose with respect to the app and content or those arising from course of
                  dealing or usage of trade. The company cannot and does not warrant that the app will operate without
                  interruptions, that it will be free from viruses and errors, or that the app will not interfere with
                  the functioning of other software or programs installed on your cellular mobile device and will meet
                  your requirements. No oral, written or electronic information or advice given by any party shall
                  create any warranty, term or condition with respect to the app or otherwise.
                </li>
                <li>
                  The app may be subject to breaches of security and the company shall not be held responsible for any
                  resulting damage to any user’s device from any such breach including but not limited to any virus,
                  bugs, tampering, unauthorized, intervention, omission, deletion, defect.
                </li>
                <li>The company provides no assurance that any specific errors or discrepancies will be corrected.</li>
                <li>
                  The company is not liable for any network faults or downtime in network services. You agree and
                  acknowledge that relay of text messages is network dependent and the company is not responsible for
                  any messages failing to reach their intended recipients.
                </li>
                <li>
                  The app uses the read only memory (rom) of your cellular mobile phone to function efficiently and
                  effectively. You agree and acknowledge that the app might automatically hibernate or go into standby
                  mode is the room on your cellular mobile phone is insufficient to run the oem apps and functions. The
                  company is not responsible for the app hibernating and you agree that you are solely responsible to
                  keep your cellular mobile phone optimized.
                </li>
                <li>
                  It is possible that the app may get locked or stop working if the data on your cellular mobile phone
                  causes your phone to crash or requires the operating software to be reinstalled. The company is not
                  liable or responsible to you in any manner whatsoever for such an occurrence.
                </li>
                <li>
                  While we do provide mental health services, we do not engage in crisis situations, especially those
                  that involve harm to self or others, such as in cases of suicide, domestic violence, etc. In such
                  cases, referrals might be made to clinical psychologists/psychiatrists. Information regarding certain
                  helplines have already been provided in the FAQ section.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Disclaimer;
