"use client"
import React from "react"
import "./style.css"
import Modal from "react-bootstrap/Modal"
import ReactGA from 'react-ga4';
 

export function AppDownloadModal({ handleClose, show, heading }) {


const onIosClick=event=>{


  ReactGA.event({
    category: 'ios_download_app_button',
    action: 'Click',
    label: window.location
    
});
}
const onAndroidClick=event=>{


  ReactGA.event({
    category: 'android_download_app_button',
    action: 'Click',
    label: window.location
    
});
}

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
        <Modal.Body className="container-fluid app-download-modal">
          <div className="row">
            <div className="col-md-6 app-download-modal-left">
              <img src="/img/app-download-main.png" alt="" />
            </div>
            <div className="col-md-6 app-download-modal-right">
              <h2>Download Our App</h2>
              <p>{heading}</p>
              <div className="row">
                <div className="col-md-6 ">
                  <img
                    src="/img/app-qr-code.png"
                    className="app-download-modal-qr"
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                  <div className={`container p-0 download`}>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.asha.happy_minds"
                      rel="noreferrer"
                      onClick={onAndroidClick}
                    >
                      <span className="me-4">
                        <img
                          src="/img/googleplay.png"
                          className="appStore"
                          alt="app store"
                        />
                      </span>
                    </a>

                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/wellm/id1628972794"
                      rel="noreferrer"
                      onClick={onIosClick}
                    >
                      <span>
                        <img
                          className="appStore"
                          src="/img/appstore.png"
                          alt="Download button for the ios app on the apple play store"
                          
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AppDownloadModal
