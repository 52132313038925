"use client"
import * as React from "react"

export function FeatureGeneratedCode(props) {
  return (
    <>
      <div className="div01">
        <div className="div-02">
          <div className="div-03">
            Igniting your mental and emotional flames to illuminate the path towards
            holistic well-being.
          </div>
          <div className="div-04">
            <div className="div-05">
              <div className="column-04">
                <div className="div-013">
                  <img
                    loading="lazy"
                    srcSet="/img/assess_emotions.png"
                    className="img-03"
                    style={{ position: "relative" }}
                    alt=""
                  />
                  <div className="div-014">Assess your emotions</div>
                  <div className="div-015">
                    Gain insights into your emotions with our expert-backed happiness
                    quiz and address areas that need attention.
                  </div>
                </div>
              </div>
              {/* <div className="column-02">
                <div className="div-09" />
              </div> */}
              <div className="column-03">
                <div className="div-010">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d38ca4be-7d24-4c98-9b45-47313600fbe1?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-02"
                    alt=""
                  />
                  <div className="div-011">Get Help - Anytime, Anywhere</div>
                  <div className="div-012">
                    Seek professional assistance and access exclusive content -
                    anytime you need, anywhere you go!
                  </div>
                </div>
              </div>
              <div className="column-04">
                <div className="div-013">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d95cba90-e4e7-4d54-8d2d-bd60ffab25c8?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-03"
                    alt=""
                  />
                  <div className="div-014">Share with Community</div>
                  <div className="div-015">
                    Engage openly or anonymously within our non-judgmental community
                    and connect with people who get it!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .div01 {
          display: flex;
          flex-direction: column;
          margin-top: 15vw;
        }
        .div-02 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1187px;
          flex-grow: 1;
          padding-right: 20px;
          padding-left: 20px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-02 {
            max-width: 100%;
          }
          .div01 {
            margin-top: 68vw;
          }
        }
        .div-03 {
          color: #646464;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 300;
          align-self: center;
          margin-left: -21px;
          max-width: 1028px;
          width: 75%;
        }
        @media (max-width: 991px) {
          .div-03 {
            max-width: 100%;
            font-size: 1.5rem;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 0vh;
          }
        }
        .div-04 {
          align-self: stretch;
          margin-top: 6%;
        }
        @media (max-width: 991px) {
          .div-04 {
            max-width: 100%;
          }
        }
        .div-05 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-05 {
            flex-direction: column;
            align-items: stretch;
            gap: 40px;
          }
        }
        .column01 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column01 {
            width: 100%;
          }
        }
        .div06 {
          border-radius: 36px;
          box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: var(--white, #fff);
          display: flex;
          margin-top: 7px;
          margin-left: auto;
          margin-right: auto;
          width: 309px;
          max-width: 100%;
          padding-top: 13px;
          padding-right: 20px;
          padding-bottom: 24px;
          padding-left: 20px;
          flex-direction: column;
        }
        .img01 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 114px;
          overflow: hidden;
          align-self: center;
        }
        .div-07 {
          color: #404040;
          text-align: center;
          font-family: Open Sans, sans-serif;
          font-size: 18px;
          font-weight: 600;
          align-self: center;
          margin-top: 15px;
          margin-left: -10px;
          width: 234px;
        }
        .div-08 {
          color: var(--TEXT-CLR, #5e6282);
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.5px;
          align-self: center;
          margin-top: 36px;
          max-width: 252px;
        }
        .column-02 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 8%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-02 {
            width: 100%;
          }
        }
        .div-09 {
          border-radius: 30px 0px 10px 0px;
          background-color: #bfd8e6;
          display: flex;
          margin-top: 316px;
          margin-left: auto;
          margin-right: auto;
          width: 100px;
          height: 100px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-09 {
            margin-top: 200px;
          }
        }
        .column-03 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-03 {
            width: 100%;
            margin-left: 0px;
          }
        }
        .div-010 {
          border-radius: 36px;
          box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: var(--white, #fff);
          display: flex;
          margin-top: 7px;
          margin-left: auto;
          margin-right: auto;
          width: 309px;
          max-width: 100%;
          padding-top: 4px;
          padding-right: 15px;
          padding-bottom: 40px;
          padding-left: 16px;
          flex-direction: column;
        }
        .img-02 {
          aspect-ratio: 1.01;
          object-fit: cover;
          object-position: center;
          width: 134px;
          overflow: hidden;
          align-self: center;
        }
        .div-011 {
          color: #404040;
          text-align: center;
          font-family: Open Sans, sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          align-self: center;
          margin-top: 4px;
          margin-left: -7px;
          width: 234px;
        }
        @media (max-width: 991px) {
          .div-011 {
            text-wrap: initial;
          }
        }
        .div-012 {
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.5px;
          align-self: center;
          margin-top: 7%;
          margin-left: -8px;
          margin-bottom: 3px;
          max-width: 247px;
        }
        .column-04 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-04 {
            width: 100%;
            margin-left: 0px;
          }
        }
        .div-013 {
          border-radius: 36px;
          box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: var(--white, #fff);
          display: flex;
          margin-left: auto;
          margin-right: auto;
          width: 309px;
          max-width: 100%;
          padding-top: 16px;
          padding-right: 20px;
          padding-bottom: 40px;
          padding-left: 20px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-013 {
            width: 90%;
          }
        }
        .img-03 {
          aspect-ratio: 1.08;
          object-fit: cover;
          object-position: center;
          width: 120px;
          overflow: hidden;
          align-self: center;
        }
        .div-014 {
          color: #404040;
          text-align: center;
          font-family: Open Sans, sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          align-self: center;
          margin-top: 22px;
          margin-left: -7px;
          width: 234px;
        }
        .div-015 {
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.5px;
          align-self: center;
          margin-top: 7%;
          margin-left: -8px;
          margin-bottom: 3px;
          max-width: 247px;
        }
      `}</style>
    </>
  )
}
