import * as React from "react";

export function WhyPartner(props) {
  return (
    <>
      <div>
        <div className="div-az" style={{ margin: "0% 8%" }}>
          <div className="column-az">
            <div className="div-az-2">
              <div className="div-az-3">Why WellM?</div>
              <div className="div-az-4">
                Synergy thrives through collaboration. If your business champions scientific and natural wellness
                approaches, we want you. Let's create a global wellness ecosystem together!
              </div>
            </div>
          </div>{" "}
          <div className="column-az-2">
            <div className="div-az-5">
              <div className="div-az-6">Ideal partners include :</div>{" "}
              <div className="div-az-7">
                <ul>
                  <li>Wellness Coaches in Psychology, Naturopathy, Ayurveda, Fitness, Nutrition, Yoga, and more</li>
                  <li>Wellness Spas, Hospitality &amp; Institutes</li>
                  <li>Providers of natural wellness products</li>
                  <li>Consultants &amp; Organizations aligned with WellM's vision</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .div-az {
          gap: 10px;
          display: flex;

          margin-top: 5.7em;
          margin-left: 3.7em;
          margin-right: 3.7em;
        }
        @media (max-width: 991px) {
          .div-az {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
            margin-left: 1.7em;
            margin-right: 1.7em;
            margin-top: 1em;
          }
        }
        .column-az {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-az {
            width: 100%;
          }
        }
        .div-az-2 {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-az-2 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-az-3 {
          align-self: stretch;
          color: #393b3d;
          margin-right: -20px;
          width: 100%;
          font: 500 28px/114.286% Rubik, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-az-4 {
          align-self: stretch;
          width: 100%;
          margin: 16px -20px 0 0;
          font-size: 1.2rem;
          font-weight: 300;
          color: #646464;
        }
        .column-az-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-az-2 {
            width: 100%;
            margin-left: 0px;
          }
        }
        .div-az-5 {
          align-items: flex-start;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          flex: 1;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-az-5 {
            max-width: 100%;
            margin-top: 40px;
          }
          div-az-5 ol,
          ul {
            padding: 0 2vw !important;
          }
        }
        .div-az-6 {
          align-self: stretch;
          color: #393b3d;
          margin-right: -20px;
          width: 100%;
          font: 500 28px/114.286% Rubik, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-az-7 {
          align-self: stretch;
          width: 100%;
          margin: 16px -20px 0 0;
          font-size: 1.2rem;
          font-weight: 300;
          color: #646464;
        }
      `}</style>
    </>
  );
}
