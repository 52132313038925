import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import "./heroStyles.css"
import AppDownloadModal from "../App_Download_Modal"
import ReactGA from 'react-ga4';


export default function HeroMain(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    
    
    setShow(false);
  
    ReactGA.event({
      category: 'home_hero_download_app_button',
      action: 'Click',
      label: window.location
      
  });
  
  }
  const handleShow = () => setShow(true)

  return (
    <>
      {/* <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4045e23b-b2de-49e0-a140-b453e8559665?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
          className="img"
        /> */}
      <AppDownloadModal
        show={show}
        handleClose={handleClose}
        heading="Nurturing your emotional and mental well-being to pave the way for holistic wellness."
      />
      <div className="div-3-hp">
        <img
          loading="lazy"
          srcSet="https://firebasestorage.googleapis.com/v0/b/covid19-coupon-game.appspot.com/o/website%2F5.png?alt=media&token=870ffe8b-bd46-4874-a174-f0f206503001"
          className="img-hp"
          alt=""
        />
        {/* <img loading="lazy" src="/img/wellm-logo.png" alt="wellm logo" className="img-2-hp" /> */}
        <div className="div-4-hp">
          <div className="div-5-hp">
            <div className="div-award-hp">
              Awarded by Women's Health India as
            </div>
            <div className="div-6-hp">
              Best Mental Well- <br />
              being Start-up
            </div>
            <div className="7-pw-hp"></div>
            <div className="div-8-hp">
              2023-2024
              
            </div>
            <div className="div-9-hp">
              <Button
                variant="outline-secondary"
                className="downloadButton"
                style={{
                  borderRadius: "8px",
                  fontSize: "1.1rem",
                  color: "white",
                  borderColor: "#e77c40",
                  backgroundColor: "#e77c40",
                }}
                
                href="https://user.wellm.co/#/home?status=auth"
                target="_blank"
              >
                Sign up
              </Button>{" "}
              <Button
                variant="outline-secondary"
                className="downloadButton"
                style={{
                  borderRadius: "8px",
                  fontSize: "1.1rem",
                }}
                onClick={() => handleShow()}
              >
                Download our app
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>

      {/* <section className="hero">
          <div className="titleContainer">
            <h2>
              {" "}
              Happier mind <br /> Healthier you
            </h2>
            <span>
              Nurturing your emotional and mental <br /> well-being to pave the
              way for
              <br /> holistic wellness
            </span>{" "}
            <br />
            <div className="button-row">
              <Button variant="warning" className="signupButton">
                Sign up
              </Button>{" "}
              <Button variant="outline-secondary" className="downloadButton">
                Download our app
              </Button>{" "}
            </div>
          </div>
        </section> */}
      <style jsx>{`
        .break {
          display: none;
        }

        .div-3-hp {
          disply: flex;
          flex-direction: column;
          overflow: visible;
          align-self: stretch;
          position: relative;
          display: flex;

          width: 100%;
          padding: 0px 0px 0px;
        }
        @media (max-width: 991px) {
          .div-3-hp {
            max-width: 100%;
          }
        }
        .img-hp {
          position: absolute;
          width: 100vw;
          left: 13%;
          top: 42%;
        }
        @media (max-width: 991px) {
          .img-hp {
            max-width: 150%;

            margin-left: -30vw;

            top: 13%;
            left: 40%;
           
            width: 100vw;
          }
        }
        .img-2-hp {
          aspect-ratio: 2.05;
          object-fit: cover;
          object-position: center;
          width: 219px;
          overflow: hidden;
          align-self: start;
          margin-left: 36px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-2-hp {
            margin-left: 10px;
          }
        }
        .div-4-hp {
          position: relative;

          background-color: white;
          align-self: start;
          display: flex;
          padding: 2% 7%;

          max-width: 100%;
          

          flex-direction: column;
          margin: 24% 0 -120px 12%;
        }
        @media (max-width: 437px) {
          .div-4-hp {
            margin-bottom: 10px;
            margin-right: 5vw;
            margin-top: 40vh;
            margin-left: 7vw;
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
            padding: 1.5rem;
            border-radius: 15px;
            align-self: center;
          }

          
        }
        .div-5-hp {
          align-self: start;
          display: flex;
          width: 100%;

          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-5-hp {
            max-width: 100%;
            align-self: center;
          }
        }
        .div-6-hp {
          color: #4f4f4f;

          font-size: 23px;
          font-weight: 400;
          align-self: start;

          text-wrap: nowrap;
          color: #ef7505;
          font-weight: bold;
          font-size: 52px;
          line-height: 52px;
        }
        @media (max-width: 991px) {
          .div-6-hp {
            font-size: 2.3rem;
            text-wrap: nowrap;
            align-self: center;
            line-height: 45px;
            margin-bottom: 18px;
          }
        }

        .div-award-hp {
          color: #518b91;

          
          font-weight: 400;
          align-self: start;

          text-wrap: nowrap;
          
          font-weight: bold;
          font-size: 25px;
          line-height: 52px;
        }
        @media (max-width: 991px) {
          .div-award-hp {
            font-size: 1rem;
            text-wrap: initial;
            align-self: start;
            line-height: 45px;
            margin-bottom: 0px;
          }
        }
        .div-7-hp {
          color: #506ea2;

          font-size: 50px;
          font-weight: 800;
          align-self: start;

          text-wrap: nowrap;
          color: #ef7505;
          font-weight: bold;
          font-size: 44px;
        }
        @media (max-width: 991px) {
          .div-7-hp {
            max-width: 100%;
            font-size: 40px;
            text-wrap: initial;
            align-items: center;
            margin-left: 15vw;
            margin-right: 15vw;
          }
        }

        .disButton {
          backgroundcolor: #e77c40;
          bordercolor: transparent;
          borderradius: 8px;
        }

        .div-8-hp {
          font-size: 1.2rem;
          font-weight: 300;
          align-self: start;
          max-width: 100%;
          margin: 1% 0 0 0;
        }
        @media (max-width: 991px) {
          .div-8-hp {
            font-size: 1rem;
            font-weight: 500;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            text-align: center;
            align-self: center;
          }
        }
        .div-9-hp {
          justify-content: center;
          align-items: flex-start;
          align-self: start;
          display: flex;
          margin-top: 17px;

          max-width: 100%;
          gap: 5px;
        }

        @media (max-width: 991px) {
          .div-9-hp {
            margin-top: 18px;
            align-self: center;
            flex-direction: column;
          }
        }
        .div-10-hp {
          justify-content: center;
          align-items: center;
          border-radius: 5.966px;
          background-color: #e77c40;
          align-self: center;
          display: flex;
          width: 130px;
          max-width: 100%;
          flex-direction: column;
          margin: auto 0;
          padding: 10px 20px;
        }
        .div-11-hp {
          color: #fff;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-11-hp {
            text-wrap: initial;
          }
        }
        .div-12-hp {
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          gap: 19px;
        }

        @media (max-width: 991px) {
          .div-12-hp {
            display: none;
          }
        }
        .img-3-hp {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 49px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-13-hp {
          color: #797979;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-13-hp {
            text-wrap: initial;
          }
        }
      `}</style>
    </>
  )
}
