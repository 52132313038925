import React, { Component } from "react"
import "./cardCaraousel.css"

class CardCaraousel extends Component {
  render() {
    return (
      <div className="cardCaraousel">
        <div className="container">
          <aside className="carousel">
            <div className="carousel__wrapper" id="cards">
              <div className="item" id="slide-0">
                <div className="divv">
                  <img
                    loading="lazy"
                    style={{ padding: "14% 2%" }}
                    srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e62afcaa-5dbd-4f2b-959c-5d70cda217ae?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-1"
                    alt=""
                  />
                  <div className="divv-2">WellM App</div>
                  <div className="divv-3">
                    Private, portable and personalised Wellness for every student.
                  </div>
                </div>
              </div>
              <div className="item" id="slide-1">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    style={{ padding: "14% 12%" }}
                    srcset="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/testFiles%2FClip%20path%20group-3.png?alt=media&token=c9f427a2-90f1-4a11-ba73-42f2a8d62cb3&_gl=1*1ikap5p*_ga*MjAwMDY1ODI3NC4xNjk2NTg0MjM3*_ga_CW55HF8NVT*MTY5ODIzNTAxMy4zOTYuMS4xNjk4MjM1MDIxLjUyLjAuMA"
                    className="img-2d"
                  />
                  <div className="divv-2">Wellness Expert</div>
                  <div className="divv-3">
                    Dedicated counselling service provided for your Institution.
                  </div>
                </div>
              </div>
              <div className="item" id="slide-2">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    style={{ padding: "14% 6%" }}
                    src="/img/univeristy_wellness.png"
                    className="img-3"
                  />

                  <div className="divv-2">University Wellness Dashboard</div>
                  <div className="divv-3">
                    Study your university’s overall emotional wellbeing status and
                    take proactive steps
                  </div>
                </div>
              </div>
              <div className="item" id="slide-3">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    src="/img/ring_fenced.png"
                    style={{ padding: "14% 2%" }}
                    className="img-4"
                  />

                  <div className="divv-2">Ring Fenced Community</div>
                  <div className="divv-3">
                    Safe Guared community for students to engage and connect. Even
                    the shy ones can chip in anonymously!
                  </div>
                </div>
              </div>
              <div className="item" id="slide-4">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    src="/img/self_care.png"
                    className="img-5"
                    style={{ padding: "14% 2%" }}
                  />

                  <div className="divv-2">Self-Care Content</div>
                  <div className="divv-3">
                    Basic mind games that will help refresh a studying brain and
                    improve focus.
                  </div>
                </div>
              </div>
              <div className="item" id="slide-5">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/356856d9-d121-423f-a6d0-93b77e5a3969?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-6"
                    style={{ padding: "14% 2%" }}
                  />
                  <div className="divv-2">Mind Refreshing Games</div>
                  <div className="divv-3">
                    Basic mind games that will help refresh a studying brain and
                    improve focus.
                  </div>
                </div>
              </div>
              <div className="item" id="slide-6">
                <div className="divv">
                  <img
                    loading="lazy"
                    alt=""
                    src="/img/wellness_tracker.png"
                    style={{ padding: "14% 2%" }}
                    className="img-7"
                  />

                  <div className="divv-2">Wellness Tracker</div>
                  <div className="divv-3">
                    A well-being monitor that allows students to track their
                    activity, mood or habits. And even helps set new habits!
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}

export default CardCaraousel
