"use client";
import React from "react";
import "./style.css";
import Accordion from "react-bootstrap/Accordion";

export function Hipaa(props) {
  return (
    <>
      <section id="hipa" className={`container hipa`}>
        <h2>HIPAA Policies Procedures</h2>

        <Accordion className="section">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="Ahead">
              {" "}
              AUTHORIZATION TO USE OR DISCLOSE PROTECTED HEALTH INFORMATION
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>

              <p>
                WellM will obtain an individual’s authorization to use or disclose protected health information in
                accordance with HIPAA and its regulations. Generally, designated covered components do not need to
                obtain an individual’s authorization when using and disclosing protected health information for routine
                purposes (e.g. treatment, payment, or health care operations), or for other limited purposes, as
                described in WellM’s Notice of Privacy Practices. Otherwise, designated covered components must obtain
                an individual’s valid authorization for the use or disclosure of protected health information.
              </p>
              <hr></hr>
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Authorization Form :-
                <ul className="point">
                  <li>A Sample Authorization may be found at the last of this Manual.</li>
                  <li>
                    The authorization shall be written in plain language and shall contain the following information:
                  </li>
                  <ul className="subpoint">
                    <li>
                      A description of the PHI to be used/disclosed that identifies the information in a specific and
                      meaningful fashion;
                    </li>
                    <li>
                      A description of each purpose of the requested use or disclosure, for example, the statement “at
                      the request of the individual” is a sufficient description of the purpose when an individual
                      initiates the authorization and does not, or elects not to, provide a statement of the purpose;
                    </li>
                    <li>The name of the person or organization authorized to disclose the PHI;</li>
                    <li>The name of the person or organization authorized to receive the PHI;</li>
                    <li>A statement that the individual has the right to revoke the authorization in writing;</li>
                    <li>A statement listing the exceptions to an individual’s right to revoke;</li>
                    <li>
                      A statement that information used or disclosed pursuant to the authorization may be subject to
                      re-disclosure by the recipient and no longer protected;
                    </li>
                    <li>A statement that the individual may refuse to sign the authorization;</li>
                    <li>
                      A statement that the covered component will not condition treatment, payment, enrollment or
                      eligibility for benefits in a health plan, based on the individual providing authorization for the
                      requested use or disclosure;
                    </li>
                    <li>An expiration date (or expiration event); and</li>
                    <li>
                      The signature of the individual and date (or the signature of an individual’s personal
                      representative).
                    </li>
                  </ul>
                  <li>WellM must provide the individual with a signed copy of the authorization.</li>
                </ul>
                Psychotherapy Notes
                <ul className="point">
                  <li>
                    WellM will obtain an individual’s authorization to use or disclose psychotherapy notes, except in
                    the circumstances listed below.
                  </li>
                  <li>
                    WellM does not need to obtain an individual’s authorization to use or disclose psychotherapy notes:
                  </li>

                  <ul className="subpoint">
                    <li>To carry out treatment, payment, or health care operations;</li>
                    <li>For use by the originator of the psychotherapy notes for treatment;</li>
                    <li>
                      For use or disclosure by the designated covered component for its own training programs in which
                      students, trainees, or practitioners in mental health learn under supervision to practice or
                      improve their skills in counseling;
                    </li>
                    <li>
                      For use or disclosure by the covered entity to defend itself in a legal action or proceeding
                      brought by the individual; and
                    </li>
                    <li>For other limited uses and disclosures as described in 45 C.F.R. § 508(a)(2).</li>
                  </ul>
                </ul>
                Revocation of Authorization
                <ul className="point">
                  <li>
                    An individual may revoke an authorization at any time, provided that the revocation is in writing.
                  </li>
                  <li>
                    If WellM has already taken action in reliance on the authorization, the WellM will stop providing
                    the protected health information based on the revoked authorization with a reasonable period of
                    time.
                  </li>
                </ul>
                Documentation
                <ul className="point">
                  <li>WellM must document and retain any signed authorization under this section.</li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header className="Ahead">BUSINESS ASSOCIATES</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                From time to time, covered components may share protected health information with external parties,
                known as business associates. Protected health information generally may only be shared with business
                associates pursuant to a valid Business Associate Agreement. A Business Associate Agreement can be in
                the form of a written amendment to an existing agreement.
              </p>
              <hr></hr>
              <h4 className="pp">2. PROCEDURE</h4>
              <p>Business Associate Agreement:-</p>
              <ul className="point">
                <li>A Sample Business Associate Agreement is set forth on last of this Manual.</li>
                <li>
                  Generally, PHI may only be shared with business associates pursuant to a valid Business Associate
                  Agreement.
                </li>
                <li>
                  It is the responsibility of each designated covered component contracting with business associates to
                  assure that valid Business Associates Agreements are executed.
                </li>
                <li>
                  Business Associate Agreements must be in writing and must contain certain language that is HIPAA
                  compliant.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header className="Ahead">COMPLAINT </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                An individual who believes his or her HIPAA privacy rights have been violated may file a complaint
                regarding the alleged privacy violation with the WellM’s Privacy Official of the appropriate Office of
                Civil Rights (OCR) Regional office. Complaints submitted to the WellM’s Privacy Official will be
                documented, reviewed, and acted upon, if necessary.
              </p>
              <hr></hr>

              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Filing a Complaint:-
                <ul className="point">
                  <li>A Sample Complaint Form is set forth on last of this Manual.</li>
                  <li>
                    If an individual believes his or her privacy rights have been violated, an individual may file a
                    complaint with the appropriate OCR Regional office, or with the WellM’s Privacy Official located in
                    the office of the Wellness Mandala Pvt. Ltd. (WellM) located at 3rd Floor, Times Square Building
                    Sushant Lok, Phase-1. Gurugram – 122002. India.{" "}
                  </li>
                  <li>Individuals must file complaints in writing, either paper or electronically.</li>
                  <li>
                    A complaint must be filed 180 days from when the individual knew or should have known of the
                    circumstance that led to the complaint, unless this time limit is waived for “good cause” shown.
                  </li>
                  <li>
                    A complaint must name the entity that is the subject of the complaint and describe the acts or
                    omission believed to be in violation of the HIPAA requirements.
                  </li>
                  <li>
                    OCR may prescribe additional procedures for the filing of complaints, as well as the place and
                    manner of filing, by notice in the Federal Register.
                  </li>
                  <li>Individuals may not be penalized for filing a complaint</li>
                </ul>
                Investigation, Sanctions
                <ul className="point">
                  <li>
                    The Privacy Official will investigate alleged complaints to determine if a breach of privacy has
                    occurred.
                  </li>
                  <li>
                    If the Privacy Official determines that a violation occurred, the Privacy Official will apply
                    appropriate sanctions against the person or entity who failed to comply with the privacy policies
                    and procedures and instruct the person or entity to take the corrective actions, if necessary. The
                    Privacy Official will document any sanctions imposed.
                  </li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header className="Ahead">DE-IDENTIFICATION OF PROTECTED HEALTH INFORMATION</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                WellM may use or disclose de-identified PHI without obtaining an individual’s authorization. PHI shall
                be considered de-identified if either of the two de-identification procedures set forth below are
                followed.
              </p>
              <hr></hr>
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Removal of Identifiers:-
                <ul className="point">
                  <li>
                    De-identified PHI is rendered anonymous when identifying characteristics are completely removed and
                    when WellM does not have any actual knowledge that the information could be used alone or in
                    combination with other information to identify and individual.
                  </li>
                  <li>
                    De-identification requires the elimination not only of primary or obvious identifiers, such as the
                    individual’s name, address, and date of birth, but also of secondary identifiers through which a
                    user could deduce the individual’s identity.
                  </li>
                  <li>For information to be de-identified the following identifiers must be removed:</li>
                  <ul className="subpoint">
                    <li>Names;</li>
                    <li>All address information except for the state;</li>
                    <li>Names of relatives and employers;</li>
                    <li>
                      All elements of dates (except year), including date of birth, admission date, discharge date, date
                      of death; and all ages over 89 and all elements of dates including year indicative of such age
                      except that such ages and elements may be aggregated into a single category of age 90 or older;
                    </li>
                    <li>Telephone numbers;</li>
                    <li>Fax numbers;</li>
                    <li>E-mail addresses;</li>
                    <li>Social security numbers;</li>
                    <li>Medical record numbers;</li>
                    <li>Health plan beneficiary numbers;</li>
                    <li>Account numbers;</li>
                    <li>Certificate/license numbers;</li>
                    <li>Vehicle identifiers, including license plate numbers;</li>
                    <li>Device ID’s and serial numbers;</li>
                    <li>Web Universal Resource Locators (URL);</li>
                    <li>Internet Protocol (IP) addresses;</li>
                    <li>Biometric identifiers;</li>
                    <li>Full face photographic images and other comparable images;</li>
                    <li>
                      Any other unique identifying number characteristics (except as otherwise permitted for
                      re-identification purposes).
                    </li>
                  </ul>
                </ul>
                Statistical Method
                <ul className="point">
                  <li>
                    PHI is considered de-identified if a person with appropriate knowledge of and experience with
                    generally accepted statistical and scientific principles and methods for rendering information not
                    individually identifiable: (a) determines that the risk is very small that the information could be
                    used, alone or in combination with other reasonably available information, by an anticipated
                    recipient to identify an individual who is a subject of the information; and (b) documents the
                    methods and results of the analysis to justify such determination.
                  </li>
                </ul>
                Re-identification
                <ul className="point">
                  <li>
                    A covered component may assign a code or other means of record identification to allow information
                    de-identified under this section to be re-identified by the covered component, provided that (a) the
                    code or other means of record identification is not derived from or related to information about the
                    individual and (b) the covered component does not use or disclose the code or other means of record
                    identification for any other purpose, and does not disclose the mechanism for re-identification.
                  </li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header className="Ahead">LIMITED DATA SHEETS</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                Covered components may use and disclose a limited data set without an individual’s authorization for the
                purposes of research, public health, or health care operations if the covered component enters into a
                Data Use Agreement with the intended recipient of the limited data set. A designated covered component
                may use protected health information to create a limited data set, or to disclose protected health
                information to a Business Associate to create a limited data set on behalf of the covered component.
              </p>
              <hr></hr>
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Limited Data Set
                <ul className="point">
                  <li>
                    A limited data set is PHI that excludes the following direct identifiers of the individual or
                    relatives, employers, or household members of the individual:
                  </li>
                  <ul className="subpoint">
                    <li>Names;</li>
                    <li>Postal address information, other than town, city, state, and zip codes;</li>
                    <li>Telephone numbers;</li>
                    <li>Fax numbers;</li>
                    <li>Electronic mail addresses;</li>
                    <li>Social security numbers;</li>
                    <li>Medical record numbers;</li>
                    <li>Health plan beneficiary numbers;</li>
                    <li>Account numbers;</li>
                    <li>Certificate/license numbers;</li>
                    <li>Vehicle identifiers and serial numbers (including license plate number);</li>
                    <li>Web Universal Resource Locators (URLs);</li>
                    <li>Internet Protocol (IP) address numbers;</li>
                    <li>Biometric identifiers, including finger and voiceprints; and</li>
                    <li>Full face photographs and comparable images.</li>
                  </ul>
                </ul>
                Data Use Agreements. Data use agreements must:
                <ul className="point">
                  <li>Establish the permitted uses and disclosures of the limited data set;</li>
                  <li>Establish who is permitted to use or receive the limited data set; and</li>
                  <li>Provide that the recipient of the information will:</li>
                  <ul className="subpoint">
                    <li>Not use or further disclose the information other than as permitted by the agreement;</li>
                    <li>
                      Use appropriate safeguards to prevent use or disclosure other than as permitted by the agreement;
                    </li>
                    <li>
                      Report to WellM any uses or disclosures that recipient is aware of that is not provided for by the
                      agreement;
                    </li>
                    <li>
                      Ensure that the recipient’s agents who have access to the information agree to the same
                      restrictions as imposed on the recipient; and
                    </li>
                    <li>Not identify the information or contact the individuals.</li>
                  </ul>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header className="Ahead">
              MINIMUM NECESSARY USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                When using or disclosing PHI or when requesting PHI from another entity covered by the HIPAA privacy
                regulations, WellM shall make a reasonable effort to limit itself to the minimum amount of protected
                health information necessary to accomplish the intended purpose of the use, disclosure or request. WellM
                is not required to apply the minimum necessary standard under the following circumstances:
                <ul className="subpoint">
                  <li>
                    <b>For Treatment.</b> Disclosure to or requests by a health care provider for purposes of diagnosing
                    or treating an individual.
                  </li>
                  <li>
                    <b>To the Individual.</b> Uses or disclosures made to the individual.
                  </li>
                  <li>
                    <b>Pursuant to Patient’s Authorization.</b> Uses or disclosures pursuant to a valid authorization.
                  </li>
                  <li>
                    <b>To the HHS.</b> Disclosures to the Office for Civil Rights of the U.S. Department of Health and
                    Human Services for HIPAA compliance purposes
                  </li>
                  <li>
                    <b>Required by Law.</b> Uses or disclosures that are required by law (i.e., a mandate that is
                    contained in law that compels WellM to use or disclose protected health information and that is
                    enforceable in a court of law, e.g., court orders, court-ordered subpoenas, civil or authorized
                    investigative demands).
                  </li>
                </ul>
              </p>
              <hr></hr>
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                WellM recognizes that each designated covered component that uses or discloses protected health
                information has a unique organizational structure and that employees of the unit may perform various
                functions for the unit that require different levels of access to protected health information. Further,
                the responsibilities designated to these functions vary across each designated covered component at
                WellM and cannot be determined solely based on job title or description. For these reasons it is the
                responsibility of each designated covered component that uses and discloses protected health information
                to determine those persons or classes of persons, as appropriate, in its workforce who need access to
                protected health information to carry out their duties; and for each such person or class of persons,
                the category or categories of protected health information to which access is needed and any conditions
                appropriate to such access.
              </p>
              <p>
                For any type of disclosure that it makes on a routine and recurring basis, a covered component must
                implement policies and procedures (which may be standard protocols) that limit the protected health
                information disclosed to the amount reasonably necessary to achieve the purpose of the disclosure. For
                all other disclosures, the covered component must develop criteria designed to limit the protected
                health information disclosed to the information reasonably necessary to accomplish the purpose for which
                disclosure is sought and review requests for disclosure on an individual basis in accordance with such
                criteria.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header className="Ahead">NOTICE OF PRIVACY PRACTICES</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                WellM is committed to maintaining and protecting the confidentiality of the individual’s PHI. This
                Notice of Privacy Practices applies to WellM (Staff, Branches etc). WellM is required by federal and
                state law, including the Health Insurance Portability and Accountability Act (“HIPAA”), to protect the
                individual’s PHI and other personal information. WellM is required to provide the individual with this
                Notice of Privacy Practices about the WellM’s policies, safeguards, and practices. When WellM uses or
                discloses an individual’s PHI, WellM is bound by the terms of this Notice of Privacy Practices, or the
                revised Notice of Privacy Practices, if applicable.
                <p>WellM’s Obligations:</p>
                <p>WellM is required by law to:</p>
                <ul className="point">
                  <li>Maintain the privacy of PHI (with certain exceptions)</li>
                  <li>
                    Give the individual this notice of the WellM’s legal duties and privacy practices regarding health
                    information about the individual
                  </li>
                  <li>Follow the terms of the WellM’s Notice of Privacy Practice that is currently in effect</li>
                </ul>
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                How WellM may use and disclose PHI:
                <p>
                  The following describes the ways WellM may use and disclose PHI. Except for the purposes described
                  below, WellM will use and disclose PHI only with the individual’s written permission. The individual
                  may revoke such permission at any time by writing to WellM’s Compliance Officer.
                </p>
                <ul className="point">
                  <li>
                    <b>For Treatment.</b> WellM may use and disclose PHI for the individual’s treatment and to provide
                    the individual with treatment-related health care services. For example, WellM may disclose PHI to
                    doctors, nurses, technicians, or other personnel, including people outside the WellM’s office, who
                    are involved in the individual’s medical care and need the information to provide the individual
                    with medical care.
                  </li>
                  <li>
                    <b>For Payment.</b> WellM may use and disclose PHI so that WellM or others may bill and receive
                    payment from the individual, an insurance company or a third party for the treatment and services
                    the individual received. For example, WellM may tell the individual’s insurance company about a
                    treatment the individual is going to receive to determine whether the individual’s insurance company
                    will cover the treatment.
                  </li>
                  <li>
                    <b>For Health Care Operations.</b> WellM may use and disclose PHI for health care operations
                    purposes. These uses and disclosures are necessary to make sure that all WellM’s patients receive
                    quality care and to operate and manage the WellM’s office. For example, WellM may share information
                    with doctors, residents, nurses, technicians, clerks, and other personnel for quality assurance
                    purposes. WellM also may share information with other entities that have a relationship with the
                    individual (for example, the individual’s insurance company and anyone other than the individual who
                    pays for the individual’s services) for the individual’s health care operation activities.
                  </li>
                  <li>
                    <b>Appointment Reminders, Treatment Alternatives, and Health Related Benefits and Services.</b>{" "}
                    WellM may use and disclose PHI to contact the individual to remind them that they have an
                    appointment with the WellM. WellM also may use and disclose PHI to tell the individual about
                    treatment alternatives or health-related benefits and services that may be of interest to the
                    individual.
                  </li>
                  <li>
                    <b>Third Parties Involved in an Individual’s Care or Payment for an Individual’s Care.</b> When
                    appropriate, WellM may share PHI with a person who is involved in the individual’s medical care or
                    payment for the individual’s care, such as the individual’s family or a close friend. WellM also may
                    notify the individual’s family about the individual’s location or general condition or disclose such
                    information to an entity assisting in a disaster relief effort.
                  </li>
                </ul>
                SPECIAL SITUATIONS:
                <ul className="point">
                  <li>
                    As Required by Law. WellM will disclose PHI when required to do so by international, federal, state
                    or local law.
                  </li>
                  <li>
                    To Avert a Serious Threat to Health or Safety. WellM may use and disclose PHI when necessary to
                    prevent a serious threat to the individual’s health and safety or the health and safety of others.
                    Disclosures, however, will be made only to someone who may be able to help prevent or respond to the
                    threat, such as law enforcement or a potential victim. For example, WellM may need to disclose
                    information to law enforcement when a patient reveals participation in a violent crime.
                  </li>
                  <li>
                    Business Associates. WellM may disclose PHI to the WellM’s business associates that perform
                    functions on the behalf of WellM with services if the information is necessary for such functions or
                    services.{" "}
                  </li>
                  <li>
                    Organ and Tissue Donation. If the individual is an organ donor, WellM may use or release PHI to
                    organizations that handle organ procurement or other entities engaged in procurement, banking or
                    transportation or organs, eyes or tissues to facilitate organ, eye or tissue donation and
                    transplantation.
                  </li>
                  <li>
                    Military and Veterans. If the individual is a member of the armed forces, WellM may release PHI as
                    required by military command authorities. WellM also may release PHI to the appropriate foreign
                    military authority if the individual is a member of a foreign military.
                  </li>
                  <li>
                    Workers’ Compensation. WellM may release PHI for workers’ compensation or similar programs. These
                    programs provide benefits for work-related injuries or illness.
                  </li>
                  <li>
                    Public Health Risks. WellM may disclose PHI for public health risks or certain occurrences. These
                    risks and occurrences generally include disclosures to prevent or control disease, injury or
                    disability; report births and deaths; report child, elder or dependent adult abuse or neglect;
                    report reactions to medications or problems with products; notify people of recalls of products they
                    may be using; a person who may have been exposed to a disease or may be at risk for contracting or
                    spreading a disease or condition; and the appropriate government authority if we believe a patient
                    has been the victim of abuse, neglect, or domestic violence (we will only make this disclosure when
                    required or authorized by law).
                  </li>
                  <li>
                    Health Oversight Activities. WellM may disclose PHI to a health oversight agency, such as the
                    California Department of Health and Human Services or Center for Medicare and Medical Services, for
                    activities authorized by law. These oversight activities include, for example, audits,
                    investigations, inspections, and licensure. These activities are necessary for the government to
                    monitor the health care system, government programs, and compliance with civil rights laws.
                  </li>
                  <li>
                    Data Breach Notification Purposes. WellM may use or disclose the individual’s PHI to provide legally
                    required notices of unauthorized access to or disclosure of PHI.
                  </li>
                  <li>
                    Lawsuits and Disputes. If the individual is involved in a lawsuit or a dispute, WellM may disclose
                    PHI in response to a court or administrative order. WellM also may disclose PHI in response to a
                    subpoena, discovery request, or other lawful request by someone else involved in the dispute, but
                    only if efforts have been made to tell the individual about the request or to allow the individual
                    to obtain an order protecting the information requested.
                  </li>
                  <li>
                    Law Enforcement. WellM may release PHI if asked by a law enforcement official if the information is:
                    (1) in response to a court order, subpoena, warrant, summons or similar process; (2) limited
                    information to identify or locate a suspect, fugitive, material witness, or missing person; (3)
                    about the victim of a crime even if, under certain very limited circumstances, WellM is unable to
                    obtain the individual’s agreement; (4) about a death WellM believes may be the result of criminal
                    conduct; (5) about criminal conduct on the WellM’s premises; and (6) in an emergency to report a
                    crime, the location of the crime or victims, or the identity, description or location of the person
                    who committed the crime.
                  </li>
                  <li>
                    Coroners, Medical Examiners and Funeral Directors. WellM may release PHI to a coroner or medical
                    examiner. This may be necessary, for example, to identify a deceased person or determine the cause
                    of death. WellM also may release PHI to funeral directors as necessary for their duties.
                  </li>
                  <li>
                    National Security and Intelligence Activities. WellM may release PHI to authorized federal officials
                    for intelligence, counter-intelligence, and other national security activities authorized by law.
                  </li>

                  <li>
                    Protective Services for the President and Others. WellM may disclose PHI to authorized federal
                    officials so they may provide protection to the President, other authorized persons or foreign heads
                    of state, or to conduct special investigations.
                  </li>
                  <li>
                    Inmates or Individuals in Custody. If the individual is an inmate of a correctional institution or
                    under the custody of a law enforcement official, WellM may release PHI to the correctional
                    institution or law enforcement official. This release would be necessary if: (1) for the institution
                    to provide the individual with health care; (2) to protect the individual’s health and safety or the
                    health and safety of others; or (3) the safety and security of the correctional institution.
                  </li>
                </ul>
                <p>USES AND DISCLOSURES THAT REQUIRES WELLMTO GIVE THE INDIVIDUAL AN OPPORTUNITY TO OBJECT/OPT OUT</p>
                <ul className="subpoint">
                  <li>
                    <b>Third Parties Involved in the Individual’s Care or Payment for Individual’s Care. </b>Unless the
                    individual objects, WellM may disclose to a member of the individual’s family, a relative, a close
                    friend or any other person the individual identifies, the individual’s PHI that directly relates to
                    that third party’s involvement in the individual’s health care.{" "}
                  </li>
                </ul>
                Disaster Relief. WellM may disclose the individual’s PHI to disaster relief organizations that seek the
                individual’s PHI to coordinate the individual’s care, or notify family and friends of the individual’s
                location or condition in a disaster. WellM will provide the individual with an opportunity to agree or
                object to such a disclosure whenever WellM practically can do so.
                <br></br>
                INDIVIDUAL’S WRITTEN AUTHORIZATION IS REQUIRED FOR OTHER USES AND DISCLOSURES:
                <p>
                  The following uses and disclosures of the individual’s PHI will be made only with the individual’s
                  written authorization: 1. Uses and disclosures of PHI for marketing purposes; 2. Disclosures that
                  constitute a sale of the individual’s PHI; and 3. Disclosures of psychotherapy notes.  Other uses and
                  disclosures of PHI not covered by this Notice of Privacy Practice or the laws that apply to WellM will
                  be made only with the individual’s written authorization. If the individual gives us authorization,
                  the individual may revoke it at any time by submitting a written revocation to WellM Compliance
                  Officer and we will no longer disclose PHI under the authorization. But disclosure that WellM made in
                  reliance on an individual’s authorization before the individual revoked it will not be affected by the
                  revocation.
                </p>
                <p>
                  INDIVIDUAL’S RIGHTS REGARDING PHI:
                  <br></br> Right to Inspect and Copy. The individual has a right to inspect and copy PHI that may be
                  used to make decisions about the individual’s care or payment for the individual’s care. This includes
                  medical and billing records, other than psychotherapy notes. To inspect and copy the individual’s PHI,
                  the individual must make their request, in writing, to the Department in which their care was
                  provided. WellM has up to 30 days to make the individual PHI available to the individual and WellM may
                  charge the individual a reasonable fee for the costs of copying, mailing or other supplies associated
                  with the individual’s request. WellM may not charge the individual a fee if the individual needs the
                  information for a claim for benefits under the Social Security Act or any other state or federal
                  needs-based benefit program. WellM may deny the individual’s request in certain limited circumstances.
                  If WellM does deny the individual’s request, the individual has the right to have the denial reviewed
                  by a licensed healthcare professional that was not directly involved in the denial of the individual’s
                  request, and WellM will comply with the outcome of the review.  Right to Get Notice of a Breach.
                  WellM is committed to safeguarding the individual’s PHI. If a breach of the individual’s PHI occurs,
                  WellM will notify the individual in accordance with state and federal law.  Right to Amend, Correct
                  or Add an Addendum. If the individual feels that the PHI WellM has is incorrect, incomplete, or the
                  individual wishes to add an addendum to the individual’s records, the individual has the right to make
                  such request for as long as the information is kept by or for the WellM’s office. The individual must
                  make their request in writing to the Department in which their care was provided. In the case of
                  claims that the information is incorrect, incomplete, or if the record was not created by WellM, WellM
                  may deny the individual’s request. However, if WellM denies any part of the individual’s request,
                  WellM will provide the individual with a written explanation of the reasons for doing so within 60
                  days of the individual’s request.  Right to an Accounting of Disclosures. Individuals have the right
                  to request a list of certain disclosures WellM made of PHI for purposes other than treatment, payment,
                  health care operations, certain other purposes consistent with law, or for which the individual
                  provided written authorization. To request an accounting of disclosure, individuals must make their
                  request, in writing, to the Department in which the individual’s care was provided. The individual may
                  request an accounting of disclosures for up to the previous six years of services provided before the
                  date of the individual’s request. If more than one request is made during a 12-month period, WellM may
                  charge a cost-based fee.  Right to Request Restrictions. Individuals have the right to request a
                  restriction or limitation on the PHI WellM uses or disclose for treatment, payment, or health care
                  operations. Individuals also have the right to request a limit on the PHI we disclose to someone
                  involved in the individual’s care or the payment for the individual’s care, like a family member or
                  friend. For example, the individual could ask that WellM not share information about a particular
                  diagnosis or treatment with the individual’s spouse. To request a restriction, the individual must
                  make their request, in writing, to the Department in which their care was provided. WellM is not
                  required to agree to the individual’s request unless the individual is asking us to restrict the use
                  and disclosure of the individual’s PHI to a health plan for payment or health care operation purposes
                  and such information the individual wishes to restrict pertains solely to a health care item or
                  service for which the individual has paid WellM out-of-pocket in full. If WellM agrees, WellM will
                  comply with the individual’s request unless the information is needed to provide the individual with
                  emergency treatment or to comply with law. If WellM does not agree, WellM will provide an explanation
                  in writing.  Out-of-Pocket-Payments. If the individual pays out-of-pocket (or in other words, the
                  individual has requested that WellM not bill the individual’s health plan) in full for a specific item
                  or service, the individual has the right to ask that the individual’s PHI with respect to that item or
                  service not be disclosed to a health plan for purposes of payment or health care operations, and WellM
                  will honor that request.  Right to Request Confidential Communications. Individuals have the right to
                  request that WellM communicate with them about medical matters in a certain way or at a certain
                  location. For example, the individual can ask that WellM only contact individuals by mail or at work.
                  To request confidential communications, individuals must make their request, in writing, to the
                  Department in which their care was provided. The individual’s request must specify how or where the
                  individual wishes to be contacted. WellM will accommodate reasonable requests.  Right to Choose
                  Someone to Act for the Individual. If the individual gives someone medical power of attorney or if
                  someone is the individual’s legal guardian, that person can exercise the individual’s rights and make
                  choices about the individual’s PHI. WellM will use our best efforts to verify that person has
                  authority to act for the individual before WellM takes any action.  Right to a Paper Copy of This
                  Notice of Privacy Practices. Individuals have the right to a paper copy of this Notice of Privacy
                  Practices. Individuals may ask WellM to give the individual a copy of this Notice of Privacy Practices
                  at any time. Even if the individual has agreed to receive this Notice of Privacy Practices
                  electronically, individuals are still entitled to a paper copy of this Notice of Privacy Practices.
                  Individuals may obtain a copy of this Notice of Privacy Practices on our web site at,
                  https://wellm.co/policies.
                </p>
                <p>
                  CHANGES TO THIS NOTICE OF PRIVACY PRACICES:
                  <br />
                  WellM reserves the right to change this Notice of Privacy Practices and make the new Notice of Privacy
                  Practices apply to PHI WellM already has as well as any information WellM receives in the future.
                  <br />
                  COMPLAINTS:
                  <br /> If an individual believes their privacy rights have been violated, the individual may file a
                  complaint with Rohit Sharma, HIPAA Compliance Officer, Office -Wellness Mandala Pvt. Ltd. (WellM) at
                  Address-3rd Floor, Times Square Building Sushant Lok, Phase-1. Gurugram – 122002. India. All
                  complaints must be made in writing. Individuals may also contact the Secretary of the Department of
                  Health and Human Services or Director, Office of Civil Rights of the U.S. Department of Health and
                  Human Services. Please contact WellM Compliance Officer if an individual needs assistance locating
                  current contact information. Individuals will not be penalized or retaliated against for filing a
                  complaint.
                </p>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header className="Ahead">
              PRIVACY OFFICIAL, SECURITY OFFICER, AND PRIVACY COORDINATORS
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. Privacy Official</h4>
              <p>
                WellM has designated a Privacy Official who is responsible for the development and implementation of the
                WellM’s policies and procedures related to the privacy and security of protected health information
                under HIPAA. Responsibilities of the Privacy Official include:
                <ul className="point">
                  <li>
                    {" "}
                    Maintain ongoing communication with the Security Official and all Privacy Coordinators. Coordinate
                    training programs for the designated covered components in cooperation with the Privacy
                    Coordinators.
                  </li>
                  <li>
                    {" "}
                    Respond to complaints regarding WellM policies, procedures, and practices related to the privacy of
                    health information. Respond to, or refer to the appropriate covered component, requests by
                    individuals for access and amendment, an accounting of disclosures, or requested restrictions to the
                    use and disclosure of the individual’s PHI.
                  </li>
                </ul>
                The contact information for the Privacy Official is:
                <br />
                <b>
                  Rohit Sharma <br />
                  Wellness Mandala Pvt. Ltd. (WellM)
                  <br />
                  Address-3rd Floor, Times Square Building Sushant Lok, Phase-1. Gurugram – 122002. India.
                  <br />
                  Email - accounts@wellm.co
                  <br />
                  Phone- 9315721646
                </b>
                <br />
                This information is subject to change and will be revised accordingly.
              </p>
              <hr />
              <h4 className="pp">2. Security Official</h4>
              <p>
                WellM has designated a Security Official to assist the Privacy Official and Privacy Coordinators in
                carrying out WellM adopted policies and procedures related to the privacy and security of individuals’
                ePHI under HIPAA.
                <br />
                Responsibilities of the Security Official include:
                <ul className="point">
                  <li>
                    {" "}
                    Maintain ongoing communication with the Privacy Official and all Privacy Coordinators. Assist in the
                    development of policies and procedures of each covered component related to the security of ePHI.
                    Assist in the development and implementation of ongoing security awareness and training programs for
                    the workforce of covered components, researchers, and students with respect to ePHI. Monitor the use
                    of security measures to protect ePHI. Assist in revising the WellM’s policies and procedures related
                    to the privacy and security of ePHI as required to comply with changes in any applicable laws and
                    document any changes.
                  </li>
                </ul>
                The contact information for the Security Official is:
                <br />
                Name <br />
                Address
                <br />
                Email
                <br />
                Phone
              </p>
              <hr />
              <h4 className="pp">3. Privacy Coordinators</h4>
              <p>
                WellM has designated Privacy Coordinators within each of the covered components to assist the Privacy
                Official and the Security Officer in carrying out WellM adopted policies and procedures related to the
                privacy and security of protected health information under HIPAA.
                <ul className="point">
                  <li>
                    <b>Responsibilities of the Privacy Coordinators include:</b>
                    <br />
                    Perform the role of liaison and maintain ongoing communication with the Privacy Official and the
                    Security Official. Communicate with the Privacy Official and the Security Official regarding the
                    privacy and security policies of the covered component within which the Privacy Coordinator is
                    located. Develop and maintain procedures consistent with the policy for protection of PHI in the
                    covered component. Maintain all policies and procedures in written or electronic form. Inform
                    members of the covered component about the policies and procedures through various mechanisms,
                    including staff meetings, orientation for new workforce members, and ongoing education. Monitor the
                    process for identifying workforce members within the covered component who require access to PHI.
                    Monitor compliance with the policies and procedures of the covered component. Report to the Privacy
                    Official violations that result in an impermissible use of disclosure of PHI, and report to the
                    Security Official violations that result in an impermissible use of disclosure of ePHI.
                    <br />
                    Help ensure continued compliance with HIPAA and WellM policies and procedures.
                  </li>
                </ul>
                The contact information for each of the Privacy Coordinators is:
                <br /> <b>Name / Address / Email / Phone /</b>
                <br />
                This information is subject to change and will be revised accordingly.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header className="Ahead">RECORDS RETENTION</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                WellM will maintain certain documentation regarding its HIPAA compliance, in written or electronic form.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <ul className="point">
                <li>
                  {" "}
                  Covered components must retain the following documentation for six years from the date of its creation
                  or the date it was last in effect (whichever is later):
                  <br />o Policies and Procedures. Any policy or procedural documentation, including notice of privacy
                  practices, consents (if any) and authorizations, and other standard forms. o Patient Requests. Patient
                  requests for access, amendment, or accounting of disclosures. o Complaints. The handling of any
                  individual’s complaints. o Workforce Training. The processes for and content of workforce training. o
                  Sanctions. The handling of any sanctions against members of its workforce who fail to comply with the
                  privacy policies and procedures of the covered component.
                </li>
                <li> If state laws require longer retention periods, the state requirements control.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header className="Ahead">RESEARCH</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                HIPAA establishes privacy protections from human subject’s research and establishes the conditions under
                which protected health information may be used or disclosed by WellM for research purposes. This policy
                and procedure should be followed in addition to any applicable federal or state regulations governing
                the protection of human subjects research, as well as any applicable Institutional Review Board (“IRB”)
                policies and procedures.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Research
                <ul className="point">
                  <li>
                    WellM may use or disclose protected health information for research, regardless of the source of the
                    funding of the research, in the following circumstances:
                  </li>
                  <ul className="subpoint">
                    <li>
                      o Individual Authorization. The individual has signed a valid authorization; o Board Approval of
                      Waiver. The IRB has approved a proper waiver of the need to obtain the individuals authorization;
                      o Limited Data Set. The health information is used or disclosed in a limited data set in
                      accordance with a valid Data Use Agreement; o De-identification. The health information has been
                      de-identified; o Preparatory to Research. PHI may be used or disclosed to a researcher as
                      necessary to prepare a research protocol or for similar purposes preparatory to research if WellM
                      obtains the following representations from the researcher: (a) the use or disclosure is sought
                      solely to review PHI as necessary to prepare a research protocol or for similar purposes
                      preparatory to research: (b) no PHI will be removed from WellM by the researcher in the course of
                      the review; and (c) the PHI for which use or access is sought is necessary for the research
                      purposes; o Decedent’s Research. PHI may be used or disclosed to a researcher for research on
                      decedents if WellM obtains the following from the researcher: (a) a representation that the use or
                      disclosure sought is solely for research on the PHI of decedents; (b) documentation of the death
                      of such individual(s) and/or research subject(s); (c) a representation that the PHI for which use
                      or disclosure is sought is necessary for research purposes.
                    </li>
                  </ul>
                  <li>
                    {" "}
                    If WellM is also the researcher, WellM must still obtain the proper authorization or fit within one
                    of the other exceptions before using PHI for research purposes.
                  </li>
                </ul>
                Research Pursuant to an Authorization
                <ul className="point">
                  <li>
                    Research authorizations must contain the same core elements as other authorizations (Authorization
                    to Use or Disclose Protected Health Information on pages 9 and 10), except for the following
                    differences:
                  </li>
                  <ul className="subpoint">
                    <li>
                      o WellM may condition the provision of research-related treatment on a provision of authorization
                      for the use or disclosure of protected health information for such research; o An authorization
                      for use and disclosure of protected health information for a research study may be combined with
                      any other type of written permission for the same research study, including another authorization
                      for the use or disclosure of protected health information for such research or consent to
                      participate in such research; o A research authorization does not need to contain an expiration
                      date or event as is required for other authorizations (the language “end of the research study” or
                      “none” or similar language is sufficient).
                    </li>
                  </ul>
                  <li>
                    {" "}
                    A research authorization may be revoked by an individual.  If an authorization is revoked, WellM
                    may continue its use or disclosure of the PHI already obtained pursuant to the valid authorization
                    to the extent necessary to preserve the integrity of the research study.
                  </li>
                  <p>IRB Waiver Approval</p>
                  <li>
                    {" "}
                    For a use or disclosure to be permitted upon IRB approval, the IRB must document that all of the
                    following criteria have been met:
                  </li>
                  <ul className="subpoint">
                    <li>
                      o The use or disclosure of PHI involves no more than a minimal risk to the privacy of individuals,
                      based on the presence of the following elements: (i) an adequate plan to protect the identifiers
                      from improper use and disclosure; (ii) an adequate plan to destroy the identifiers at the earliest
                      opportunity consistent with the conduct of research, unless there is a health or research
                      justification for retaining the identifiers or such retention is otherwise required by law; and
                      (iii) adequate written assurances that the protected health information will not be reused or
                      disclosed to any other person or entity, except as required by law, for authorized oversight of
                      the research study, or for other research for which the use or disclosure of protected health
                      information would be permitted under this policy;
                      <br />o The research could not be conducted without the waiver or alteration; and o The research
                      could not be conducted without access to and use of the protected health information.
                    </li>
                  </ul>
                  <li>
                    {" "}
                    The documentation should include a statement identifying the IRB and the date on which the
                    alteration or waiver of authorization was approved. The documentation should include a brief
                    description of the PHI for which use or access has been determined to be necessary by the IRB. The
                    documentation should include a statement that the alteration or waiver of authorization has been
                    reviewed. The Chair of the IRB or other member designated by the Chair must sign the document.
                  </li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header className="Ahead">
              RIGHT TO REQUEST ACCESS TO PROTECTED HEALTH INFORMATION
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                Individuals have the right to request access to inspect or copy their protected health information that
                is maintained in a designated record set. WellM will address an individual’s request to inspect or copy
                his or her protected health information in a timely and professional manner. Individuals do not have the
                right to access certain types of information (set forth below), and in those situations, WellM may deny
                an individual’s request to access. In certain circumstances, an individual may have the right to have a
                denial reviewed. WellM will adhere to the procedures set forth below when addressing, denying, or
                reviewing an individual’s request to access.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Requests for Access
                <ul className="point">
                  <li>
                    {" "}
                    A Sample Request for Access Form is set forth on page 47 of this Manual. Each covered component must
                    designate the title of the person(s) or office responsible for receiving and processing requests for
                    access by individuals. Individuals must be instructed to direct their request for access to the
                    designated person responsible for receiving such requests. Individuals may be instructed to make
                    their request for access in writing. The person responsible for processing the request may discuss
                    the scope, format, and other aspects of the request for access with the individual as necessary to
                    facilitate a timely provision of access. The parties can agree in advance that a summary of the
                    requested protected health information will be provided in lieu of access to the information. Upon
                    receipt of a proper request, the covered component will act on the request by either: (1) informing
                    the individual of acceptance and providing the access requested; or (2) providing the individual
                    with a written denial in accordance with the procedure set forth. If the covered component does not
                    maintain the requested protected health information, but it knows where the requested information is
                    maintained, the covered component will inform the individual where to direct the request for access.
                    An individual’s request for access must be acted upon no later than 30 days after the request is
                    made; or, if the request is for protected health information that is not maintained or accessible
                    on-site, no later that 60 days after the request.
                  </li>
                </ul>
                Providing Access
                <ul className="point">
                  <li>
                    {" "}
                    If a request for access is granted, the individual will be given access to the protected health
                    information in a secure and confidential manner. The covered component will provide the individual
                    with access to the protected health information in the form or format requested by the individual,
                    if it is readily producible in such form or format. If it is not readily producible in such format,
                    the covered component will provide the access in such other form as agreed to by the individual. In
                    instances where the protected health information is in more than one record set, or at more than one
                    location, the covered component will only produce the protected health information once in response
                    to the request for access.
                  </li>
                </ul>
                Denial of Access
                <ul className="point">
                  <li>
                    {" "}
                    A Sample Denial of Access Form is set forth on page 45 of this Manual. A written denial of access
                    may be issued in the following circumstances:
                    <br />
                    o Psychotherapy Notes. An individual does not have the right to access psychotherapy notes relating
                    to him or herself except (a) to the extent the patient’s treating professional approves to such
                    access in writing; or (b) the patient obtains a court order authorizing such access. o Legal
                    Information. An individual does not have the right to access information compiled in reasonable
                    anticipation of, or for use in, a civil, criminal, or administrative action or proceeding. o
                    Endangerment. An individual does not have the right to access information in the event that a
                    licensed health care professional has determined, in the exercise of professional judgment, that the
                    access requested is reasonably likely to endanger the life or physical safety of the individual or
                    another person. o Obtained from Someone Else. An individual does not have the right to access
                    information if the protected health information was obtained from someone other than a health care
                    provider under a promise of confidentiality and the access requested would be reasonably likely to
                    reveal the source of the information. o Reference to Other People. An individual does not have the
                    right to access information if the protected health information makes reference to another person
                    and a licensed health care professional has determined, in the exercise of professional judgment,
                    that access requested is reasonably likely to cause substantial harm to such other person.
                    <br />
                    o Personal Representative. An individual does not have the right to access information if the
                    request for access is made by the individual’s personal representative and a licensed health care
                    professional has determined, in the exercise of professional judgment, that the provision of access
                    to such personal representative is reasonably likely to cause substantial harm to the individual or
                    another person. o Research. WellM may temporarily suspend an individual’s access to protected health
                    information created or obtained in the course of research that includes treatment. The suspension
                    may last for as long as the research is in progress, provided that the individual agreed to the
                    denial of access when consenting to participate in the research, and the individual has been
                    informed that the right of access will be reinstated upon completion of the research. o Other
                    Limited Circumstances. There are other limited circumstances when an individual does not have the
                    right to access protected health information, listed in 45 C.F.R. § 164.524.  When denying an
                    individual access to protected health information, the denial will be written in plain language and
                    o Contain the basis for the denial; o If applicable, contain a statement of the individual’s review
                    rights, including a description of how the individual may exercise such rights; and o Contain a
                    description of how the individual may complain to WellM (and include the title and telephone number
                    of the contact person), or to the appropriate OCR Regional office.
                    <br />
                    WellM must, to the extent possible, grant the individual access to any other protected health
                    information requested after excluding the protected health information that was denied.
                  </li>
                </ul>
                Reviewing a Denial of Access
                <ul className="point">
                  <li>
                    {" "}
                    If access is denied based on (1) Endangerment; (2) Reference to Other People; or (3) Personal
                    Representative (these exceptions are all set forth above), the individual must be given the
                    opportunity to have the denial reviewed. If an individual has requested a review of denial, WellM
                    must designate a licensed health care professional, who was not directly involved in the denial, to
                    review the decision to deny access. The reviewing official must determine whether or not to confirm
                    the denial based on the standards set forth in 45 C.F.R. 164.524(a)(3). The reviewing official must
                    review the denial of access within a reasonable period of time and then must promptly notify the
                    individual of the decision and take any necessary action to carry out the reviewing officials
                    decision.
                  </li>
                </ul>
                Costs and Fees
                <ul className="point">
                  <li>
                    {" "}
                    WellM may impose a reasonable, cost-based fee for copying costs and postage in response to a request
                    for access. If the individual agrees in advance, WellM may impose a reasonable cost-based fee for
                    preparing a summary of the protected health information.
                  </li>
                </ul>
                Documentation
                <ul className="point">
                  <li>
                    {" "}
                    WellM must document and retain:
                    <br />
                    o The designated record sets that are subject to access by individuals; and
                    <br />o The titles of the persons or offices responsible for receiving and processing requests for
                    access by individuals.
                  </li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11">
            <Accordion.Header className="Ahead">RIGHT TO REQUEST AN ACCOUNTING OF DISCLOSURES</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                WellM will permit individuals to request and receive an accounting of disclosures of their protected
                health information. An individual may request an accounting for disclosures that have been made up to
                six years prior to the date of his or her request; however, WellM is not required to account for any
                disclosures that occurred prior to the HIPAA compliance date of April 14, 2003. The accounting must
                include all disclosures except for the following:
                <ul className="point">
                  <li>Disclosures made to carry out treatment, payment, or health care operations;</li>
                  <li>Disclosures made to the individual;</li>
                  <li>Disclosures made pursuant to an individual’s authorization;</li>
                  <li>
                    {" "}
                    Disclosures for a facility directory; Disclosures to persons directly involved in the individual’s
                    care or payment or disclosures for notification purposes pursuant to 45 C.F.R. § 164.510(b);
                    Disclosures for national security or intelligence purposes; Disclosures to correctional facilities
                    or law enforcement officials; Disclosures made as part of a limited data set; Disclosures that
                    occurred prior to the compliance date; and Other limited disclosures as set forth in 45 C.F.R. §
                    164.528.
                  </li>
                </ul>
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Request for Accounting
                <ul className="point">
                  <li>
                    Individuals will be permitted to request and receive an accounting of disclosures of their protected
                    health information.
                  </li>
                  <li>
                    {" "}
                    Designated covered components may require requests for an accounting to be submitted in writing.
                  </li>
                  <li>Sample Request for Accounting of Disclosures Form is set forth in last of this Manual.</li>
                </ul>
                Accounting Requirements
                <ul className="point">
                  <li>
                    {" "}
                    A Sample Accounting for Disclosures Form is set forth in last of this Manual.
                    <br />
                    An individual must receive a written accounting of disclosures and the written accounting must
                    include:
                  </li>
                  <ul className="subpoint">
                    <li>The date of disclosure;</li>
                    <li>
                      The name of the entity or person who received the protected health information, if known, the
                      address of such entity or person;
                    </li>
                    <li>A brief description of the protected health information disclosed; and</li>
                    <li>
                      A brief statement of the purpose of the disclosure; or in lieu of such statement, a copy of a
                      written request for a disclosure, if any.
                    </li>
                  </ul>
                  <li>
                    If WellM has made multiple disclosures of the protected health information to the same person or
                    entity for a single purpose, or pursuant to a single authorization, the accounting may, with respect
                    to such multiple disclosures, provide:
                  </li>
                  <ul className="subpoint">
                    <li>The information required above for the first disclosure during the accounting period;</li>
                    <li>The frequency or number of disclosures made during the accounting period; and</li>
                    <li>The date of the last such disclosure during the accounting period.</li>
                  </ul>

                  <li>
                    WellM must act on the individual’s request for an accounting no later than 60 days after receipt of
                    such a request. If WellM is unable to provide the accounting within this time frame, it may extend
                    the time to provide the accounting by no more than 30 days, provided that:
                    <br />
                    (1) WellM provides the individual with a written statement of the reasons for delay and the date by
                    which WellM will provide the accounting; and
                    <br /> (2) WellM may have only one such extension of time for action on a request for an accounting.
                  </li>
                </ul>
                Suspension of Accounting of Disclosures
                <br /> An accounting of disclosures may be suspended at the request of a health oversight agency or law
                enforcement official if certain conditions are met.
                <br /> If a designated health care component receives a request to suspend an individual’s right to
                receive an accounting of disclosures, the designated covered component should contact the WellM’s
                Privacy Official.
                <br />
                Costs and Fees
                <br /> The first accounting of disclosures to an individual in any twelve (12) month period must be
                provided at no charge.
                <br /> A reasonable cost-based fee may be imposed for each subsequent request for an accounting by the
                same individual within the 12-month period, provided that WellM informs the individual in advance of the
                fee and provides the individual with an opportunity to withdraw or modify the request.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="12">
            <Accordion.Header className="Ahead">
              RIGHT TO REQUEST AN AMENDMENT TO PROTECTED HEALTH INFORMATION
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                Individuals have the right to request an amendment or correction to their protected health information.
                WellM will permit an individual to request an amendment to his or her protected health information in
                their designated record set for as long as the information is maintained in the designated record set.
                An individual can request an amendment to his or her protected health information that was not created
                by the WellM, but the individual must provide WellM with a reasonable basis to believe that the
                originator of the information is no longer available to act on the request. WellM has the right to deny
                the request to amend in certain circumstances.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Requests for an Amendment
                <ul className="point">
                  <li>A Sample Request for an Amendment Form is set forth on page 49 of this Manual.</li>
                  <li>
                    Each covered component of WellM must designate the title of the person(s) or office responsible for
                    receiving and processing requests for an amendment by individuals.
                  </li>
                  <li>
                    Individuals must be instructed to direct their requests for an amendment to the designated person
                    responsible for receiving such request.
                  </li>
                  <li>
                    A covered component may instruct individuals to make their requests in writing and may require the
                    individual to provide a reason to support the requested amendment, as long as the designated covered
                    component informs the individual in advance of such requirements.
                  </li>
                  <li>
                    WellM must act upon an individual’s request for amendment no later than 60 days after receipt of the
                    request. If the covered entity is unable to act on the amendment within this time period, WellM may
                    extend the time for such action by no more than 30 days, provided that: <br />
                    (1) WellM provides the individual with a written statement of the reasons for the delay and the date
                    by which WellM will complete its action on the request; and <br />
                    (2) WellM may have only one such extension of time for action on a request for an amendment.
                  </li>
                </ul>
                Accepting a Request to Amend
                <br />
                If the requested amendment is accepted, in whole or in part, the covered component shall inform the
                individual of the acceptance and make the appropriate amendment. At a minimum, the covered component
                shall amend the information by identifying the affected information in the designated record set and
                appending or otherwise providing a link to the location of the amendment.
                <br />
                The covered component and the individual should identify the relevant persons or entities, including
                business associates, who need to be informed about the amendment.
                <br />
                Denying a Request to Amend A Sample Denial of Request for an Amendment Form is set forth on last of this
                Manual.<br></br>
                An individual’s request for an amendment may be denied if the covered component determines that the
                protected health information or record that is the subject of the request:
                <br />
                o Was not created by the WellM, unless the individual provides a reasonable basis to believe that the
                originator of the protected health information is no longer available to act on the requested amendment;
                o Is not part of the individual’s designated record set; o Is not available for inspection by the
                individual pursuant to the Access to Right to Request Access to PHI policy, set forth herein; and o Is
                accurate and complete.
                <br />
                If a covered component denies the requested amendment, the covered component shall inform the individual
                in writing.
                <br />
                The denial shall be written in plain language and contain the following: o The basis for the denial;
                <br />
                o A statement notifying the individual that he or she has the right to submit a written statement of
                disagreement and a description of how the individual may file such a statement;
                <br />
                o A statement notifying the individual that if he or she does not submit a statement of disagreement,
                the individual may request that the designated covered component provide the individual’s request for
                amendment and the denial with any future disclosures of the protected health information that is the
                subject of the amendment; and
                <br />
                o A description of how the individual may file a complaint pursuant to the Privacy Complaint Policy and
                Procedure, set forth above.
                <br />
                If WellM denies a request for an amendment, the individual has the right to file a statement of
                disagreement.
                <br />
                Statement of Disagreement
                <br />
                1. If WellM denies an individual’s request for an amendment, the individual will have the right to
                submit a statement of disagreement.
                <br />
                2. WellM may then prepare a written rebuttal to the individual’s statement of disagreement.
                <br />
                3. A copy of the rebuttal must be provided to the individual.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="13">
            <Accordion.Header className="Ahead">RIGHT TO REQUEST CONFIDENTIAL COMMUNICATION</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                Individuals may request to receive communications of protected health information in a confidential
                manner (e.g., by alternative means or in alternative locations). WellM shall accommodate reasonable
                requests to receive confidential communications.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                A covered component may require an individual to make a request to receive confidential communications
                in writing.
                <br />
                Covered components may condition the provision of a reasonable accommodation on:<br></br> (1)
                information as to how payment (if any) will be handled; and
                <br /> (2) specification of an alternative address or other method of contact.
                <br />A covered component may not require an explanation from the individual as to the basis for the
                request as a condition of providing confidential communications.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="14">
            <Accordion.Header className="Ahead">
              RIGHT TO REQUEST RESTRICTIONS ON THE USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION
            </Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                Individuals may request restrictions on the use and disclosure of their protected health information.
                Requests for restriction do not have to be granted; however, if WellM agrees to a restriction, it may
                not use or disclose the protected health information in violation of the restriction, except in
                emergency situations. Further, any agreed-to restriction will not be effective to prevent uses and
                disclosures to the individual or as required by law.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Request to Restrict Use or Disclosure of Protected Health Information
                <br></br>
                An individual may request a restriction on the use and disclosure of his or her protected health
                information.<br></br>
                A covered component does not have to agree to requests for restrictions; however, if it does agree, the
                covered component may not use or disclose the protected health information in violation of such
                restriction, except in emergency situations. The covered component should discuss with the individual
                whether the restriction should be communicated to others (i.e., other covered components of WellM or
                business associates who may be sending the individual communications on behalf of the WellM).
                Terminating a Restriction A restriction can be terminated if:
                <br />
                o The individual requests the restriction in writing or orally (if the termination is requested orally,
                it should be documented; or
                <br />o The designated covered component informs the individual that it is terminating the agreement to
                a restriction, in which case the termination will only apply to protected health information created or
                received after the individual has been notified of the termination.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="15">
            <Accordion.Header className="Ahead">SAFEGUARDING PROTECTED HEALTH INFORMATION</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className="pp">1. POLICY</h4>
              <p>
                WellM will implement appropriate administrative, technical, and physical safeguards, which will
                reasonably safeguard the confidentiality of protected health information. Designated covered components
                may develop additional policies and proced`ures that are stricter than the parameters set forth below in
                order to maximize the privacy of protected health information in light of the unique circumstances of a
                particular component.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                WellM recognizes that each designated covered component has a unique organizational structure. For this
                reason, it is the responsibility of each designated covered component to determine and implement
                reasonable administrative, technical, and physical safeguards. The following list of guidelines contains
                some suggestions of administrative, technical, and physical safeguards that covered components may wish
                to adopt:
              </p>
              <ul className="point">
                <li>
                  Oral Communications. Exercising due care to avoid unnecessary disclosures of protected health
                  information through oral communications, such as avoiding such conversations in public areas.
                </li>
                <li>
                  Telephone Messages. Limiting messages left on answering machines and voicemails to appointment
                  reminders and messages that do not link an individual’s name to protected health information.
                </li>
                <li>
                  Faxes. Placing fax machines in secure areas not readily accessible to visitors, clients, patients,
                  etc. and/or using a cover sheet with a confidentiality notice when faxing protected health
                  information.
                </li>
                <li>
                  Paper Records. Storing paper records and charts in a way that avoids access by unauthorized persons,
                  such as in locked filing cabinets.
                </li>
                <li>
                  Desks and Working Areas. Securing desks and working areas that contain protected health information.
                </li>
                <li>
                  Computer Monitors. Positioning computer monitors away from common areas or installing a privacy screen
                  to prevent unauthorized viewing, and/or creating password protected screen savers.
                </li>
                <li>
                  Disposal of Paper records. Disposing of documents containing protected health information in a secure
                  manner, e.g., by shredding.
                </li>
                <li>
                  Disposal of Electronic Materials. Disposing of electronic material that contains unencrypted protected
                  health information in a secure method.
                </li>
                <li>
                  E-mails. Sending e-mails that contain protected health information with a confidentiality notice,
                  and/or sending such e-mails in encrypted form.
                </li>
                <li>
                  Electronic Documents. Securing protected health information that is stored on a hard disk drive or
                  other internal component of a personal computer, such as by password or encryption.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="16 ">
            <Accordion.Header className="Ahead">TRAINING</Accordion.Header>
            <Accordion.Body className="Adata">
              <h4 className={"pp"}>1. POLICY</h4>
              <p>
                Each designated covered component is responsible for training its workforce (including employees, BA and
                other associate) with respect to the WellM’s HIPAA policies and procedures on the use and disclosure of
                PHI as necessary and appropriate for the members of the workforce to carry out their function.
              </p>
              <hr />
              <h4 className="pp">2. PROCEDURE</h4>
              <p>
                Training
                <ul className="point">
                  <li>
                    It will be the responsibility of each designated covered component to ensure that its workforce
                    receives training.
                  </li>
                  <li>
                    Each employee must be trained no later than April 14, 2003. Each new employee must receive training
                    within a reasonable period of time after the person becomes an employee, etc.
                  </li>
                  <li>
                    If there is a material change in the policies and procedures and, as a result, certain employees are
                    affected, those employees must receive training on the material change within a reasonable period of
                    time after the change becomes effective.
                  </li>
                </ul>
                Documentation
                <ul className="point">
                  <li>A covered entity must document that the training has been provided.</li>
                </ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <section className={`container formsec`}>
          <h2 className="formhead">HIPAA Forms Manual</h2>
          <div className={` row form`}>
            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20A.pdf?alt=media&token=84d6f6f4-2129-4c61-93a7-bfe846f34bac">
              {" "}
              <p>A. Accounting for Disclosures of Protected Health Information</p>{" "}
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20B.pdf?alt=media&token=5bc356db-0545-4394-ad94-5e0583f9c804">
              {" "}
              <p>B. Authorization to Use/Disclose Protected Health Information</p>{" "}
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20C.pdf?alt=media&token=4a0da053-1b28-484d-b358-6252f5883bf3">
              {" "}
              <p>C. Business Associate Agreement</p>{" "}
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20D.pdf?alt=media&token=0777840b-d881-40f5-8924-4f5ab91b95c8">
              <p>D. Denial of Request for Amendment</p>
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20E.pdf?alt=media&token=447d1e5a-2a6f-46fc-b7c0-0f7e4d8977bc">
              <p>E. Denial of Request for Access</p>
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20F.pdf?alt=media&token=841c3851-1798-4d78-aef9-3208e5e8c6bc">
              {" "}
              <p>F .Privacy Complaint</p>
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20G.pdf?alt=media&token=1d74e26c-b007-4bff-881c-09500ff5fcbc">
              <p>G. Request for Access to Protected Health Information</p>{" "}
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20H.pdf?alt=media&token=4d0fc39a-3caa-4f16-aec9-61fb46dc635f">
              <p>H. Request for Accounting of Disclosures</p>
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20I.pdf?alt=media&token=34227edb-319b-406c-b81c-30e526afd1ab">
              {" "}
              <p>I. Request for Amendment to Protected Health Information</p>
            </a>

            <a href="https://firebasestorage.googleapis.com/v0/b/happymind-test.appspot.com/o/hipaa%2FForm%20J.pdf?alt=media&token=e0e744e2-d2c7-4d67-972c-78b328270e64">
              <p>J. Acknowledgement of Receipt of Notice of Privacy Practices</p>
            </a>
          </div>
        </section>
      </section>
    </>
  );
}
export default Hipaa;
