"use client"
import * as React from "react"
import {
  
  NavLink,
} from "react-router-dom";
import ReactGA from 'react-ga4';

export function Services(props) {
  return (
    <>
      <div className="div home-services" style={{ width: "80%", margin: "0 auto" }}>
        <div className="div-2">
          <div className="div-3">
            Our <span className="gradient-text">Services</span>
          </div>
          <div className="div-4">
            At WellM, our mission is to empower you to lead a healthier, happier life
            by providing support in various aspects of your mind and body well-being.
          </div>
          <div className="div-5">
            <div className="div-11">
              <div className="div-12">
                <div className="column-3">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b59dce63-b3dd-4a98-80aa-36413bbc60f4?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-2"
                    alt=""
                  />
                </div>
                <div className="column-4">
                  <div className="div-13">
                    <div className="div-14">Personal Wellness</div>
                    <div className="div-15">
                      Don’t let emotional turmoil, stress and anxiety slow down your
                      progress.
                      <br />
                      <br />
                      Join WellM today and discover the path to mental well-being
                      with interactive assessments, self-care practices, rituals
                      gelling with time tested science of nature and a supportive
                      community.
                      <br />
                      <br />
                      Our competant coaches are available to you 24/7 on WellM App at
                      your time and place of comfort.
                     
                      <br />
                      <NavLink
                      to="/personal-wellness"
               
               
                target="_blank"
                style={{ color: "#646464" }}
                rel="noreferrer"
                onClick={()=>{

                  ReactGA.event({
                    category: 'listening_circle_button',
                    action: 'Click_personal_wellness_read_more',
                    label: window.location
                    
                });
                }}
              >
                <div  className="readMoreLink">
                Read More→
                </div>
              </NavLink>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-10">
          <div className="div-11">
            <div className="div-12a">
              <div className="column-3a">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2d8acf7-4d51-479f-b95c-2713485c8ae4?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-2"
                  alt=""
                />
              </div>
              <div className="column-4">
                <div className="div-13">
                  <div className="div-14">Corporate Wellness</div>
                  <div className="div-15">
                    Emotionally disturbed employees can lead to a big invisible leak
                    in company's productivity and growth.
                    <br />
                    <br />
                    Prevent potential blowups with the assistance of WellM.
                    <br />
                    <br />
                    Let WellM provide and track the emotional and selfcare heatmap of
                    your organisation.
                    <br />
                    <br />
                    And, guide your employees towards mental wellness so they can
                    contribute to your path of success.
                  </div>
                 
                  <NavLink 
                  to="/corporate-wellness"
               
               
                target="_blank"
                style={{ color: "#646464" }}
                rel="noreferrer"
                onClick={()=>{

                  ReactGA.event({
                    category: 'listening_circle_button',
                    action: 'Click_corporate_wellness_read_more',
                    label: window.location
                    
                });
                }}
              >
                <div  className="readMoreLink">
                Read More→
                </div>
              </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="div-11">
            <div className="div-12">
              <div className="column-3">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b56b3602-fc3b-4fe9-ba24-aec63f0755a1?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-2"
                  alt=""
                />
              </div>
              <div className="column-4">
                <div className="div-13">
                  <div className="div-14">Student Wellness</div>
                  <div className="div-15">
                    The young generation embodies the future of our nation, and it's
                    our responsibility to guide them towards success. 
                    <br />
                    <br />
                    Join hands with WellM in the journey to support them in attaining
                    mental strength and unlocking boundless growth potential.
                    <br />
                    <br />
                    If you are a student reading this, WellM promises you to put a
                    zing factor in your congnitive, career and personal growth.
                  </div>
                  <NavLink
                  to="/student-wellness"
                
               
                target="_blank"
                style={{ color: "#646464" }}
                rel="noreferrer"
                onClick={()=>{

                  ReactGA.event({
                    category: 'listening_circle_button',
                    action: 'Click_student_wellness_read_more',
                    label: window.location
                    
                });
                }}
              >
                <div  className="readMoreLink">
                Read More→
                </div>
              </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <style jsx>{`
        .div {
          display: flex;
          flex-direction: column;
          margin-top: 3.7vw;
          margin-bottom: 3.7vw;
        }
        .readMoreLink {

          margin-top: 5px;
          text-decoration: underline;
          font-size: 16px;
          font-weight: 400;





        }
        .home-services .div-2 {
          align-self: center;
          display: flex;
          padding-right: 20px;
          padding-left: 20px;
          flex-direction: column;
          margin-top: 3.7em;
          padding-bottom: 0 !important;
        }
        @media (max-width: 991px) {
          .home-services .div-2 {
            max-width: 100%;
            margin: 0;
            padding: 0;
            margin-top: 3.7em;
          }
          .home-services {
            width: 90% !important;
          }
        }
        .div-3 {
          color: #646464;
          text-align: center;
          font-size: 45px;
          font-weight: 400;
          line-height: 267.86%;
          align-self: center;
          margin-left: -27px;
          max-width: 527px;
        }
        @media (max-width: 991px) {
          .div-3 {
            font-size: 40px;
          }
        }
        .div-4 {
          text-align: center;
          font-size: 1.4rem;
          font-weight: 300;
          align-self: center;
          max-width: 100%;
        }
        .div-5 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .div-5 {
            max-width: 100%;
          }
        }
        .div-6 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-6 {
            flex-direction: column-reverse;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-7 {
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 3vw;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-7 {
            max-width: 100%;
            margin-top: 200px;
          }
        }
        .div-8 {
          color: #646464;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 48px;
          font-weight: 500;
          line-height: 312.5%;
          align-self: start;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-8 {
            font-size: 35px !important;
            text-wrap: initial;
            line-height: 160%;
            margin-bottom: 1.2em;
          }
        }

        .img-4z {
          aspect-ratio: 1.02;
          object-fit: cover;
          object-position: center;
          width: 353px;
          overflow: hidden;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-4z {
            margin-top: 40px;
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .div-9 {
          color: rgba(56, 56, 56, 0.8);
          font-family: Outfit, sans-serif;
          font-size: 24px;
          font-weight: 400;
          line-height: 133.33%;
          letter-spacing: 0.5px;
          align-self: start;
          // margin-top: 45px;
          width: 576px;
          max-width: 100%;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .img {
          aspect-ratio: 0.96;
          object-fit: cover;
          object-position: center;
          width: 90%;
          border-radius: 170px 0px 250px 0px;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img {
            max-width: 100%;

            margin-top: 50px;
          }
        }
        .div-10 {
          align-self: stretch;
          display: flex;
          margin-top: 21px;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-10 {
            max-width: 100%;
          }
        }
        .div-11 {
          align-self: stretch;
          margin-right: 12px;
          padding-right: 20px;
          padding-left: 20px;
          margin-top: 69px;
        }
        @media (max-width: 991px) {
          .div-11 {
            max-width: 100%;
            margin: 0px;
            padding: 0px;
            align-self: center;
          }
        }
        .div-12 {
          gap: 90px;
          display: flex;
          flex-direction: row-reverse;
        }
        @media (max-width: 991px) {
          .div-12 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .div-12a {
          gap: 90px;
          display: flex;
          flex-direction: row;
        }
        @media (max-width: 991px) {
          .div-12a {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;

          margin-left: 0px;
          align-items: flex-end;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .column-3a {
          display: flex;
          flex-direction: column;
          line-height: normal;

          margin-left: 0px;
          align-items: flex-start;
        }
        @media (max-width: 991px) {
          .column-3a {
            width: 100%;
          }
        }
        .img-2 {
          aspect-ratio: 0.95;
          object-fit: cover;
          object-position: center;
          width: 80%;
          border-radius: 150px 0px 150px 0px;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-2 {
            width: 100%;
            max-width: 100%;
            margin-top: 50px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
            margin-left: 0px;
          }
        }
        .div-13 {
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-13 {
            max-width: 100%;
            margin-top: 50px;
          }
        }
        .div-14 {
          color: #646464;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 2rem;
          font-weight: 500;
          line-height: 200%;
          align-self: start;
          max-width: 505px;
        }
        @media (max-width: 991px) {
          .div-14 {
            font-size: 30px !important;
            text-wrap: initial;
            line-height: 160%;
            margin-bottom: 1.2em;
          }
        }
        .div-15 {
          font-family: Outfit, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 133.33%;
          letter-spacing: 0.5px;
          align-self: start;
          width: max-content;
          max-width: 100%;
        }
        .div-16 {
          align-self: stretch;
          margin-top: 42px;
          padding-right: 20px;
          padding-left: 20px;
        }
        @media (max-width: 991px) {
          .div-16 {
            max-width: 100%;
          }
        }
        .div-17 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-17 {
            flex-direction: column-reverse;
            align-items: stretch;
            gap: 0px;
          }
        }
        .div-18 {
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          flex-direction: column;
          margin-left: 3vw;
        }
        @media (max-width: 991px) {
          .div-18 {
            max-width: 100%;
            margin-top: 200px;
          }
        }
        .div-19 {
          color: #646464;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 48px;
          font-weight: 500;
          line-height: 312.5%;
          align-self: start;
          margin-left: 11px;
          max-width: 410px;
        }
        @media (max-width: 991px) {
          .div-19 {
            font-size: 35px !impotant;
            line-height: 140.5%;
            margin-bottom: 1.2em;
          }
        }
        .div-20 {
          color: rgba(56, 56, 56, 0.8);
          font-family: Outfit, sans-serif;
          font-size: 24px;
          font-weight: 400;
          line-height: 133.33%;
          letter-spacing: 0.5px;
          align-self: start;

          width: 576px;
          max-width: 100%;
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .img-3 {
          aspect-ratio: 0.95;
          object-fit: cover;
          object-position: center;
          width: 80%;
          border-radius: 170px 0px 250px 0px;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-3 {
            max-width: 100%;
            margin-top: 50px;
          }
        }
      `}</style>{" "}
    </>
  )
}
