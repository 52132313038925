import * as React from "react"
import { useState } from "react"


export function TeamWellM(props) {
  const teamData = [
    {
      name: "Rakesh Sarin",
      designation: "Founder & Chairman",
      slogan: "A joyful heart has tremendous power to heal",
    },

    {
      name: "Aditya Sankhla",
      designation: "Head Product And Tech",
      slogan: "The only impossible journey is the one you never begin.",
    },
    {
      name: "Shivi Sabharwal",
      designation: "Co-Founder & Director",
      slogan:
        "Love Selflessly, Give Joyfully, Speak Consciously, Dance Carelessly, Live Blissfully and Receive Abundantly",
    },
    {
      name: "Sameera Sachdev",
      designation: "Counseling Psychologist",
      slogan:
        "Leading by example in the world of trauma therapy. I'm committed to fostering a community of healing and resilience",
    },
    {
      name: "Viveka Mansata",
      designation: "Counselling Psychologist",
      slogan:
        "Don't let your happiness depend on something you may lose - C.S. Lewis",
    },
    {
      name: "Palak",
      designation: "Marketing Head",
      slogan:
        "Happiness is not about getting all you want- it's about enjoying what you have",
    },
    {
      name: "Rohit",
      designation: "Accounts Executive",
      slogan:
        "Committed to creating a world where mental health is a priority, not a stigma.",
    },
    {
      name: "Sanchi Sharma",
      designation: "Counseling Psychologist",
      slogan: "The only impossible journey is the one you never begin.",
    },
  ]

  const [member, setMember] = useState(teamData[0])

  function handleHover(index) {
    setMember(teamData[index])
  }
  return (
    <>
      <div className="div-twm">
        <div className="div-twm-2">Meet the Talented team of WellM</div>
        <div className="div-twm-3">
          <div className="div-twm-4 team-members">
            <div className="column-twm">
              <div className="memberName">
                {member["name"]} - {member["designation"]}
              </div>
              {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                className="img-twm"
              /> */}
            </div>
            <div className="column-twm-2">
              <img
                loading="lazy"
                src="/img/rs.jpeg"
                className={member === 0 ? "img-twm-2 selected-photo" : "img-twm-2"}
                onMouseEnter={() => handleHover(0)}
                onMouseLeave={() => handleHover(0)}
                alt=""
              />
            </div>
            <div className="column-twm-3">
              <img
                loading="lazy"
                src="/img/aditya.png"
                className={member === 1 ? "img-twm-3 selected-photo" : "img-twm-3"}
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={() => handleHover(0)}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="div-twm-5">
          <div className="div-twm-6">
            <div className="column-twm-4">
              <div className="div-twm-7">
                <div className="div-twm-8">
                  “{member["slogan"]}”
                  <br />
                </div>
                <div className="div-twm-9">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5cfa09-dea0-404c-8b1a-c4da818b5455?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-twm-4"
                    alt=""
                  />
                  <div className="div-twm-10">Yeah! That’s Us</div>
                </div>
              </div>
            </div>
            <div className="column-twm-5">
              <div className="div-twm-11">
                <div className="div-twm-12">
                  <div className="div-twm-13">
                    <div className="column-twm-6">
                      <div className="div-twm-14">
                        <img
                          loading="lazy"
                          src="/img/shivi.png"
                          className={
                            member !== 2 ? "img-twm-5" : "img-twm-5 selected-photo"
                          }
                          onMouseEnter={() => handleHover(2)}
                          onMouseLeave={() => handleHover(0)}
                          alt=""
                        />
                        <img
                          loading="lazy"
                          src="/img/sameera.jpg"
                          className="img-twm-6"
                          onMouseEnter={() => handleHover(3)}
                          onMouseLeave={() => handleHover(0)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="column-twm-7">
                      <img
                        loading="lazy"
                        src="/img/viveka.jpg"
                        className="img-twm-7"
                        onMouseEnter={() => handleHover(4)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="div-twm-15">
                  <div className="div-twm-16">
                    <div className="column-twm-8">
                      <img
                        loading="lazy"
                        className="img-twm-8"
                        src="/img/palak.png"
                        style={{ width: "170px", marginBottom: "24%" }}
                        onMouseEnter={() => handleHover(5)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                    <div className="column-twm-9">
                      <img
                        loading="lazy"
                        style={{ width: "160px", marginBottom: "24%" }}
                        src="/img/rohit.png"
                        className="img-twm-9"
                        onMouseEnter={() => handleHover(6)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                    <div className="column-twm-9">
                      <img
                        loading="lazy"
                        src="/img/sanchi.jpeg"
                        style={{ width: "160px", marginBottom: "24%" }}
                        className="img-twm-9"
                        onMouseEnter={() => handleHover(7)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
            <h3 className="subTopic">Discover WellM’s Experience:</h3>
          </div>

          <div className={`row experience`}>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/selfcare.jpg"
                  alt="selfcare"
                />
              </div>
              <h4>Assess Your Wellness</h4>
              <p className="">
                Take digital assessments on the App. Discover your current state of
                physical and emotional wellness
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/community.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Support Group</h4>
              <p className="">
                A digital support system to vent your feelings on our app that
                nurtures camaraderie.
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/counselling.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Individual Counselling</h4>
              <p className="">
                Book budget friendly one-on-one counselling sessions with expert
                coaches on our App.
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/meditation.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Personalised Content</h4>
              <p className="">
                Get targetted self-care content, based on your wellness tracker, to
                help you build healthy habits.
              </p>
            </div>
          </div> */}
      </div>
      <style jsx>{`
        .memberName {
          background-color: #fae9c8;
          padding: 2%;
          border-radius: 10px;
          font-size: 1.4rem;
          width: 70%;
        }

        .div-twm {
          display: flex;
          flex-direction: column;
          padding: 0 8%;
          margin-top: 6%;
        }
        @media (max-width: 991px) {
          .div-twm {
            display: none;
          }
        }
        .team-members img:hover {
          cursor: pointer;
        }
        .div-twm-2 {
          font-size: 2rem;
          font-weight: 400;
          color: #646464;
        }
        @media (max-width: 991px) {
          .div-twm-2 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .div-twm-3 {
          align-self: center;
          margin-top: 49px;
          width: 100%;
          max-width: 1328px;
        }
        @media (max-width: 991px) {
          .div-twm-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-4 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-4 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 70%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm {
            width: 100%;
          }
        }
        .img-twm {
          aspect-ratio: 5.59;
          object-fit: contain;
          object-position: center;
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          margin-top: 59px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-twm-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-2 {
            width: 100%;
          }
        }
        .img-twm-2 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 149px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: 10px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm-2 {
            margin-top: 40px;
          }
        }

        .selected-photo {
          border: 2px solid;
        }

        .column-twm-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-3 {
            width: 100%;
          }
        }
        .img-twm-3 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 135px;
          overflow: hidden;
          border-radius: 50%;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-twm-3 {
            margin-top: 40px;
          }
        }
        .img-twm-3:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
        .div-twm-5 {
          align-self: center;

          width: 100%;
          max-width: 1357px;
        }
        @media (max-width: 991px) {
          .div-twm-5 {
            max-width: 100%;
          }
        }
        .div-twm-6 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-6 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-4 {
            width: 100%;
          }
        }
        .div-twm-7 {
          display: flex;

          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-twm-7 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-8 {
          color: #646464;
          font-size: 1.4rem;
          font-weight: 300;
        }
        @media (max-width: 991px) {
          .div-twm-8 {
            max-width: 100%;
          }
        }
        .div-twm-9 {
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          aspect-ratio: 0.7407407407407407;
          margin-left: 0;
          width: 340px;
          max-width: 100%;
          flex-grow: 1;
          margin-top: 6%;
        }
        .img-twm-4 {
          position: absolute;
          height: 80%;
          width: 80%;
          object-fit: cover;
          object-position: center;
        }
        .div-twm-10 {
          position: relative;
          text-align: center;
          align-self: end;
          margin-bottom: -70px;
          font-size: 1.4rem;
          color: #646464;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-twm-10 {
            margin-bottom: 10px;
            text-wrap: initial;
          }
        }
        .column-twm-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-5 {
            width: 100%;
          }
        }
        .div-twm-11 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-twm-11 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-12 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .div-twm-12 {
            max-width: 100%;
          }
        }
        .div-twm-13 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-13 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 69%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-6 {
            width: 100%;
          }
        }
        .div-twm-14 {
          display: flex;
          margin-top: -12px;
          width: 420px;
          max-width: 100%;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-twm-14 {
            margin-top: 40px;
          }
        }
        .img-twm-5 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 181px;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-twm-5:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
        .img-twm-6 {
          aspect-ratio: 1.1;
          object-fit: cover;
          object-position: center;
          width: 100px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: 45px;
          flex: 1;
        }
        @media (max-width: 991px) {
          .img-twm-6 {
            margin-top: 40px;
          }
        }
        .img-twm-6:hover {
          border: 2px solid;
          cursor: pointer;

          border-radius: 50%;
        }
        .column-twm-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: -12px;
        }
        @media (max-width: 991px) {
          .column-twm-7 {
            width: 100%;
          }
        }
        .img-twm-7 {
          aspect-ratio: 1.00;
          object-fit: cover;
          object-position: center;
          width: 181px;
          overflow: hidden;
          border-radius: 50%;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-twm-7 {
            margin-top: 36px;
          }
        }
        .img-twm-7:hover {
          border: 2px solid;
          cursor: pointer;

          border-radius: 50%;
        }
        .div-twm-15 {
          align-self: center;
          width: 461px;
          max-width: 100%;
          margin: 17px 0 0 13px;
        }
        .div-twm-16 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-16 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-8 {
            width: 100%;
          }
        }
        .img-twm-8 {
         
          aspect-ratio: 1.06;
          object-fit: cover;
          object-position: center;
          width: 188px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: -39px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm-8 {
            
            margin-top: 40px;
          }
        }

        .img-twm-8:hover {
          border: 2px solid;
          border-radius: 50%;

          cursor: pointer;
        }
        .column-twm-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: -6px;
        }
        @media (max-width: 991px) {
          .column-twm-9 {
            width: 100%;
          }
        }
        .img-twm-9 {
         
          aspect-ratio: 1.08;
          object-fit: cover;
          object-position: center;
          width: 139px;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        @media (max-width: 991px) {
          .img-twm-9 {
            
            margin-top: 40px;
          }
        }
        .img-twm-9:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
        
        
       
        
        @media only screen and (max-width: 480px) {
          .institute {
            margin: 0px auto;
        
            margin-top: 3.7vh;
            width: 98%;
          }
          .subheading {
            margin-bottom: 12px !important;
          }
          .institute img {
            width: 200px !important;
            height: 200px !important;
          }
          .experienceImageParent {
            display: flex;
            justify-content: center;
            padding: 0 !important;
          }
        
          .experience h4 {
            margin-top: 12px;
          }
          .heading {
            font-size: 2rem;
            font-weight: 500;
            margin-top: 4%;
          }
        }
        
        .experience h4 {
          text-align: center;
          font-size: 18px;
        }
        
        .experience p {
          text-align: center;
          font-weight: 200;
          font-size: 16px;
        }
        
        .experience div {
          padding: 28px;
        }
        
        .experienceImage {
          border-radius: 50%;
        }
        
        .description {
          font-weight: 200;
          font-size: 18px;
        }
        
        .heading {
          margin-bottom: 1.5rem;
          color: var(--text);
          font-size: 38px;
          font-weight: 500;
        }
        
        .instituteDescription {
          font-size: 18px;
          font-weight: 300;
          margin: 4vh 0;
        }
        
        .institute .interestedButton {
          color: #646464 !important;
          border-radius: 12px !important;
          border-color: #646464 !important;
          padding: 10px 24px !important;
        }
        
        .institute .interestedButton:hover {
          background-color: #f2e97c !important;
          color: white !important;
          border-color: #f2e97c !important;
        }
        
        .horizonalSlider {
          display: grid;
          grid-gap: calc(var(--gutter) / 2);
          grid-template-columns: repeat(6, calc(50% - var(--gutter) * 2));
          grid-template-rows: minmax(150px, 1fr);
        
          overflow-x: scroll;
          scroll-snap-type: x proximity;
          padding-bottom: calc(0.75 * var(--gutter));
          margin-bottom: calc(-0.25 * var(--gutter));
          list-style: none;
          padding: 0;
        }
        
        .horizontalSliderContainer {
          width: 100%;
          height: 667px;
          background: #dbd0bc;
          overflow-y: scroll;
        }
        
        .horizonalSlider li {
          scroll-snap-align: center;
          padding: calc(var(--gutter) / 2 * 1.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-radius: 8px;
        }
        
        .cardCarousel {
          margin-top: 8vh;
        }
        
        .navigationIconRight {
          position: absolute;
          top: 50%;
          right: 0;
        }
        
        .navigationIconLeft {
          position: absolute;
          top: 50%;
          left: 0;
        }
        .experienceImage {
          border-radius: 50%;
        }
        .experienceImageParent {
          display: flex;
          justify-content: center;
          padding: 0 !important;
        }
      
      `}</style>
    </>
  )
}
