import React, { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"
import NavDropdown from "react-bootstrap/NavDropdown"

import "./navStyles.css"
import AppDownloadModal from "../App_Download_Modal"
import ReactGA from 'react-ga4';

export function NavBar() {
  const [stickyClass, setStickyClass] = useState("top")
  const [isGradientBackground, setGradientBackground] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = ()=> {
    console.log('------dededed');
    ReactGA.event({
      category: 'navbar_book_appointment_button',
      action: 'Click',
      label: window.location
      
  });

  } 

  useEffect(() => {
    setGradientBackground(
      window.location.pathname === "/corporate-wellness" ||
        window.location.pathname === "/student-wellness"
    )

    window.addEventListener("scroll", stickNavbar)

    return () => {
      window.removeEventListener("scroll", stickNavbar)
    }
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY

      windowHeight > 500 ? setStickyClass("sticky") : setStickyClass("top")
    }
  }

  return (
    <>
      <AppDownloadModal
        show={show}
        handleClose={handleClose}
        heading="Discover the right treatment for your mental health journey with personalized recommendations from our Mental Health Coaches."
      />
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          backgroundColor: "transparent",
          zIndex: "3",
          width: "100%",
          padding: "0",
        }}
        className={`${stickyClass} ${
          isGradientBackground && stickyClass !== "sticky"
            ? "gradient-background"
            : ""
        } `}
      >
        <Container className="nav-background">
          <Navbar.Brand href="/">
            {
              <img
                src="/img/wellm-logo.png"
                style={{
                  width: "9rem",
                  height: "3.5rem",
                  padding: "2% 0",
                  margin: "4%",
                }}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto allNavLinks">
              <Nav.Link href="/" className="navLink" style={{ color: "#646464" }}>
                Home
              </Nav.Link>
              <Nav.Link
                href="about-us"
                className="navLink"
                style={{ color: "#646464" }}
              >
                About us
              </Nav.Link>
              <NavDropdown
                title={
                  <span className=" my-auto " style={{ color: "#646464" }}>
                    Services
                  </span>
                }
                id="collapsible-nav-dropdown"
                style={{ fontSize: "324px !important" }}
              >
                <NavDropdown.Item href="personal-wellness">
                  <div className="row navbar-service">
                    <div className="col-10">
                      <p className="navbar-dropdown-heading">Personal Wellness</p>
                      <p className="navbar-dropdown-description">
                        Take control of your mental health <br /> journey with
                        WellM's solutions.
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        className="navbar-arrow-right"
                        src="/img/personal-wellness/right-arrow.svg"
                        alt="user"
                      />
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="corporate-wellness">
                  <div className="row navbar-service">
                    <div className="col-10">
                      <p className="navbar-dropdown-heading">Corporate Wellness</p>
                      <p className="navbar-dropdown-description">
                        Take control of your employess mental health <br /> journey
                        with WellM's corporate program.
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        className="navbar-arrow-right"
                        src="/img/personal-wellness/right-arrow.svg"
                        alt="user"
                      />
                    </div>
                  </div>
                </NavDropdown.Item>

                <NavDropdown.Item href="student-wellness">
                  <div className="row navbar-service">
                    <div className="col-10">
                      <p className="navbar-dropdown-heading">Student Wellness</p>
                      <p className="navbar-dropdown-description">
                        Supporting Student Well-Being: Your <br /> Journey to Mental
                        Health
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        className="navbar-arrow-right"
                        src="/img/personal-wellness/right-arrow.svg"
                        alt="user"
                      />
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="corporate-wellness">
                  <div className="row navbar-service">
                    <div className="col-10">
                      <p className="navbar-dropdown-heading">Partnership</p>
                      <p className="navbar-dropdown-description">
                        Unite with WellM for a Healthier, Happier World!
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        className="navbar-arrow-right"
                        src="/img/personal-wellness/right-arrow.svg"
                        alt="user"
                      />
                    </div>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                href="https://events.wellm.co/wellm-listening-circle"
                className="navLink"
                target="_blank"
                style={{ color: "#646464" }}
                rel="noreferrer"
                onClick={()=>{

                  ReactGA.event({
                    category: 'listening_circle_button',
                    action: 'Click_listening_circles_button',
                    label: window.location
                    
                });
                }}
              >
                Workshops
              </Nav.Link>
              <a
                href="https://user.wellm.co/#/all-therapist"
                target="_blank"
                className="bookAppointmentNavLink"
                rel="noreferrer"
                onClick= {()=>{



                  ReactGA.event({
                    category: 'talk_to_therapist_button',
                    action: 'Click_talk_to_therapist_button',
                    label: window.location
                    
                });
                }}
              >
                <Button
                  variant="success"
                  onClick={handleShow}
                  className="book-button2"
                  style={{ color: "white" }}
                >
                  Talk to a Therapist
                </Button>
              </a>
              <a
                href="https://user.wellm.co/#/home"
                target="_blank"
                className="bookAppointmentNavLink"
                rel="noreferrer"
                onClick={()=>{


                  ReactGA.event({
                    category: 'explore_app_button',
                    action: 'Click_explore_app_button',
                    label: window.location
                    
                });

                }}
              >
                <Button
                  variant="success"
                  className="book-button"
                  style={{ color: "white" }}
                >
                  Explore App
                </Button>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar
