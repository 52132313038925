"use client"
import React from "react"
import "./style.css"

export function HomeInstitute(props) {
  return (
    <>
      <section className={`institute container`}>
        <div className={`container `}>
          <div className="row">
            <div className={`col-lg-10 col-md-11`}>
              <div className="lefttext">
                <h2 className="heading">
                  <span className="gradient-text">
                    {" "}
                    Nurture your mind and body for a fullfilling life.
                  </span>{" "}
                  <span className="gradient-text"></span>
                </h2>
              </div>
              <p className="description">
                Help yourself discover the best selves as you embrace your future.
                Enable yourself with the skills of self-love and self-care via the
                WellM App.
                <br />
                <br />
                The WellM app provides a hub of safe spaces for you to discuss a
                range of relatable topics.
              </p>
            </div>
          </div>
          <div className="row">
            <h3 className="subTopic">Discover WellM’s Experience:</h3>
          </div>

          <div className={`row experience`}>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/selfcare.jpg"
                  alt="selfcare"
                />
              </div>
              <h4>Assess Your Wellness</h4>
              <p className="">
                Take digital assessments on the App. Discover your current state of
                physical and emotional wellness
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/community.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Support Group</h4>
              <p className="">
                A digital support system to vent your feelings on our app that
                nurtures camaraderie.
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/counselling.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Individual Counselling</h4>
              <p className="">
                Book budget friendly one-on-one counselling sessions with expert
                coaches on our App.
              </p>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="experienceImageParent">
                <img
                  className="experienceImage"
                  src="/img/meditation.jpg"
                  alt="selfcare"
                />
              </div>

              <h4>Personalised Content</h4>
              <p className="">
                Get targetted self-care content, based on your wellness tracker, to
                help you build healthy habits.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
