"use client"
import React, { Component } from "react"
import "../App.css"
import { PersonalWellnessFeature } from "../components/PW_Features/feature"
import { Testimonial } from "../components/Testimonial/testimonial"
import { TherapyStatistics } from "../components/Therapy_Statistics/therapyStatistics"
import { PersonalWellnessHero } from "../components/PW_Hero_Section/hero"
import { AppDownload } from "../components/App_Download/app_download"
import { Discover } from "../components/Discover/discover"
import { Newsletter } from "../components/Newsletter"

class PersonalWellness extends Component {
  render() {
    return (
      <div className="personal-wellness">
        <PersonalWellnessHero />
        <PersonalWellnessFeature />
        <Discover />
        {/* <BookTherapy />*/}
        <TherapyStatistics />
        <Testimonial
          testimonials={[
            `Before using this app, I was struggling with anxiety and depression. But using this app has helped me manage my symptoms and improve my overall well-being. The guided meditations, journaling prompts, and other resources have been invaluable. I'm so grateful for this app.`,
            `I'm a therapist, and I recommend this app to all of my clients. It's a great way to supplement therapy and support mental wellness between sessions. The app offers a variety of features that can be tailored to each individual's needs. I highly recommend this app.`,
            `I've been using this app for a few months now, and I've noticed a significant improvement in my mental health. I used to struggle with negative self-talk and low self-esteem. I'm so glad I found this app.`,
            `I've been using this app for a few weeks now. I used to have trouble sleeping, but the guided sleep meditations have helped me fall asleep more easily and sleep more soundly. I highly recommend this app to anyone looking to improve their sleep and overall mental well-being.`,
          ]}
          author={[
            "Software Engineer",
            "Therapist",
            "Marketing Manager",
            "Teacher",
          ]}
        />
        <AppDownload showGetStarted={false} />
        <Newsletter />
      </div>
    )
  }
}

export default PersonalWellness
