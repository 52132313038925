import React, { Component } from "react"
import { AppDownload } from "../components/App_Download/app_download"
import { CorporateWellnessHero } from "../components/CW_Hero_Section/hero"
import { CorporateWellnessReasonSection } from "../components/CW_Reason_Section/reasonSection"
import { CorporateWellnessServices } from "../components/CW_Services/services"
import { CorporateWellnessHow } from "../components/CW_How/how"
import { TherapyStatistics } from "../components/Therapy_Statistics/therapyStatistics"
import { CorporateWellnessWhyAssessments } from "../components/CW_Why_Assessment/whyAssessments"
import { CorporateWellnessTestimonial } from "../components/CW_Testimonials"
import { Newsletter } from "../components/Newsletter"

class CorporateWellness extends Component {
  render() {
    return (
      <div>
        <CorporateWellnessHero />
        <CorporateWellnessReasonSection />
        <CorporateWellnessHow />
        <CorporateWellnessServices />
        <TherapyStatistics heading="WellM's Impact" />

        <CorporateWellnessWhyAssessments />
        <CorporateWellnessTestimonial />
        {/* <Testimonial /> */}
        <AppDownload
          heading="Ready to Elevate Your Corporate Environment?"
          image="/img/download-app1.png"
          subheading="Self-development isn't a single stride but a collection of small efforts that accumulate over time."
        />
        <Newsletter />
      </div>
    )
  }
}

export default CorporateWellness
