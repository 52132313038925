("use client");
import React from "react";

import "./styles.css";
import { Carousel } from "react-bootstrap";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

export function CorporateWellnessTestimonial(props) {
  return (
    <>
      <section id="testimonial" className="corporateTestimonial">
        <div className={`col-md-8 col-lg-5 col-11 testimonial`}>
          <p className="subheading">TESTIMONIALS</p>
          <h2 className="heading">
            WellM <span>Success</span> Stories
          </h2>
          <Carousel
            controls={true}
            interval={500000}
            indicators={false}
            prevIcon={<MdKeyboardArrowLeft color="#646464" fontWeight={800} fontSize="80" />}
            nextIcon={<MdKeyboardArrowRight color="#646464" fontWeight={800} fontSize="80" />}
          >
            <Carousel.Item>
              <div className="testimonialCarousel">
                <img src="img/qoute.png" alt="testimonial qoute" />

                <p>
                  My experience with WellM was Excellent. Their psychologist, Lehar was very quick and on spot with her
                  diagnosis and provided good solutions. It was evident that she came from a good background as the pace
                  was smooth and the session was very helpful. The reports generated were really efficient. My
                  experience with WellM was beautiful. I recommend this to everyone.
                </p>
                <div className="testimonialOwner">
                  Mr. Suresh Bose <br />
                  <span>Chief Human Resource Officer- Jindal Stainless</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimonialCarousel">
                <img src="img/qoute.png" alt="testimonial qoute" />
                <p>
                  I found Vital Care is done through a fairly scientific and sophisticated equipments, and psychometric
                  assesment was fantastic. I met coaches from different sectors and have been made aware how my wellness
                  journey should be and how WellM will support me throughout.
                </p>
                <div className="testimonialOwner">
                  Mr. C P Gurnani <br />
                  <span>CEO & MD Tech Mahindra</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimonialCarousel">
                <img src="img/qoute.png" alt="testimonial qoute" />

                <p>
                  It was a wonderful and unique experience with WellM. The USP of WellM is to help you take preventive
                  measures by giving insights and personalised advice about one’s physical as well as emotional
                  wellbeing.
                </p>
                <div className="testimonialOwner">
                  Mukesh Gahlaut <br />
                  <span>HR Head North Zone, Aditya Birla</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimonialCarousel">
                <img src="img/qoute.png" alt="testimonial qoute" />

                <p>
                  I personally went through the WellM assesment and found it quite informative and reports provided me
                  inputs about my current state of health and what I need to take care of my wellness. Thanks to WellM.
                </p>
                <div className="testimonialOwner">
                  RK Malhotra
                  <br />
                  <span>Director General FIPI</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimonialCarousel">
                <img src="img/qoute.png" alt="testimonial qoute" />

                <p>
                  After using WellM, I’m feeling more alert and experiencing better sleeping pattern. Everything seems
                  good and positive looking and there are improvement areas in my overall health.
                </p>
                <div className="testimonialOwner">
                  Mr. Vijay Sharma <br />
                  <span>Director - Jindal Stainless</span>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </>
  );
}
