import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./cardCaraousel.css";
import ReactGA from 'react-ga4';


function CorporateWellnessServicesCaraousel() {
  const getItems = [
    {
      heading: "Mental Health Assessment",
      subheading:
        "Experience a lifestyle assessment rooted in the timeless principles of natural laws of wellness. This assessment comprehends an employee’s present dedication to these fundamental practices and provides a report.",
      image: "/img/slider_1.png",
    },
    {
      heading: "Detailed Reports",
      subheading:
        "The assessments scores are then personalized into an elaborate individual score report. This feature enables employees to identify their areas for improvement and make necessary adjustments.",
      image: "/img/slider_2.png",
    },
    {
      heading: "Personal Dashboard",
      subheading: `The personal dashboard serves as a private area where employees can review their assessment scores, plans,
              and progress.`,
      image: "/img/slider_3.png",
    },
    {
      heading: "Corporate Dashboard",
      subheading: `The Corporate Dashboard reveals wellness insights for both individual employees and the entire company,
              uncovering the underlying causes of employees' mental distress. `,
      image: "/img/slider_4.png",
    },
    {
      heading: "Counselling Session",
      subheading: `Corporate members will enjoy access to scheduled counselling sessions with our experts as per their selected plan. These
              sessions will be completely confidential and off the record.`,
      image: "/img/slider_5.png",
    },
    {
      heading: "Ring-Fenced Community",
      subheading: `The secure corporate environment is designed solely for the team, members can freely express themselves,
              sharing thoughts and providing mutual support. Experts closely monitor each employee's engagement,
              offering interventions as needed.`,
      image: "/img/slider_6.png",
    },
    {
      heading: "Self-Help Library",
      subheading: `Employees can access blogs and videos aligned with their assessment reports and advisory recommendations.
              This will help them maintain their daily health rituals through handpicked content available on the WellM
              App`,
      image: "/img/slider_7.png",
    },
  ];
  const [selected, setSelected] = React.useState([]);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected ? currentSelected.filter((el) => el !== id) : currentSelected.concat(id)
      );
    };

  return (
    <div className="cw-services">
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        options={{
          ratio: 0.9,
          rootMargin: "5px",
          threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
        }}
      >
        {getItems.map(({ heading, image, subheading }) => (
          <Card
            image={image}
            heading={heading}
            subheading={subheading}
            onClick={handleClick(heading)}
            selected={isItemSelected(heading)}
          />
        ))}
      </ScrollMenu>
    </div>
  );

  function LeftArrow() {
    const { scrollPrev } = React.useContext(VisibilityContext);

    return (
      <img
        className="feature-card-arrow"
        onClick={() => {
          
          ReactGA.event({
            category: 'corporate_wellness_services_caraousel_swipe_left',
            action: 'Click',
            label: window.location
            
        });
          scrollPrev()
        
        }}
        alt=""
        src="/img/personal-wellness/left-arrow.svg"
      />
    );
  }

  function RightArrow() {
    const { scrollNext } = React.useContext(VisibilityContext);

    return (
      <img
        className="feature-card-arrow"
        onClick={() =>{ 
          ReactGA.event({
            category: 'corporate_wellness_services_caraousel_swipe_right',
            action: 'Click',
            label: window.location
            
        });
          
          scrollNext()}}
        alt=""
        src="/img/personal-wellness/right-arrow.svg"
      />
    );
  }
}

function Card({ onClick, selected, heading, subheading, image }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div onClick={() => onClick(visibility)} tabIndex={0} className="services-feature-card">
      <img loading="lazy" src={image} alt="" />
      <div className="feature-card-heading">{heading}</div>
      <div className="feature-card-subheading">{subheading}</div>
    </div>
  );
}

export default CorporateWellnessServicesCaraousel;
