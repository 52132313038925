"use client"
import * as React from "react"
import Button from "react-bootstrap/Button"
import { TeamWellM } from "../components/partComponents/aboutUs/teamWellM"
import { WhyPartner } from "../components/partComponents/aboutUs/whyPartner"
import YouTube from "react-youtube"
import { Newsletter } from "../components/Newsletter"
import { AppDownload } from "../components/App_Download/app_download"
import { HashLink } from "react-router-hash-link"
import StarRating from "../components/GOOGLE_RATING/GoogleRating"

export function AboutUsPage(props) {
  return (
    <>
      <div className="div">
        <div className="div-2">
          <div className="div-12">
            <div className="div-13">
              <div className="column">
                <div className="div-14">
                  <div className="div-15">About Us</div>
                  <div className="div-16">
                    <div className="div-17">
                      <br />
                      Unite with WellM for a Healthier,
                      <br />
                      Happier World!
                      <br />
                    </div>
                    <div className="div-18">
                      <div className="div-19">
                        <Button
                          variant="success"
                          size="lg"
                          style={{
                            backgroundColor: "#E77C40",
                            borderColor: "transparent",
                            borderRadius: "8px",
                            width: "9em",
                          }}
                        >
                          <HashLink
                            smooth
                            to="/about-us#explore-about"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            Get started
                          </HashLink>
                        </Button>{" "}
                        {/* <div className="div-21">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/48da2d55-8fd8-4e31-9fee-ed1daaed30bb?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                            className="img-2"
                          />
                          <div className="div-22">Watch Video</div>
                        </div> */}
                      </div>
                      <div className="div-23">
                        <div className="div-24">Google Playstore</div>
                        <StarRating />
                        {/* <img loading="lazy" src="/img/rating-about-use.png" alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-2">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7a666d-ea9a-48c1-9180-8a52818ffaea?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-4"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div className="div-25">
            At the roots of Crosson, there is 20 years of experience in food industry that is filled with research,
            increasing efficiency and producing solution for food, quality, automation and software.
          </div> */}
          <div className="div-26">
            WellM didn't start as a program or a visionary concept; it began as a
            necessity—a need for individuals to recognize the profound impact of
            mental health on physical well-being.
            <br />
            Did you know? Mental health conditions can contribute to or exacerbate
            chronic physical health issues in up to 60% of adults. Probably not.
            <br />
            <br />
            Our founder, Rakesh Sarin (RS), was also unaware of this. For the past 35
            years, RS has dealt with diabetes, which initially seemed like an
            opportunity to embrace a health-conscious lifestyle from an early age.
            <br />
            However, with time, instead of diminishing, his condition intensified.
            This led to a reliance on endless medications and the dreaded insulin
            shots. 
            <br />
            <br />
            This is when our mighty founder decided to head on a search—a quest to
            find a definitive solution to eliminate diabetes once and for all.
            <br />
            <br />
            In his pursuit of a solution, he stumbled upon something unexpected: a
            diabetes remedy comprised of nine natural elements combined with a focus
            on enhancing mental well-being. Astonishingly, this approach led to him
            overcoming diabetes within just 20 days.
            <br />
            <br />
            But it also brought about an epiphany—a realization that there's no true
            health without mental well-being. Despite a lifetime of adhering to a
            healthy lifestyle, the accumulation of stress continually counteracted
            the benefits of his efforts.
            <br />
            This marked the inception of the idea behind WellM—the transformative
            journey centred on adhering to the laws of nature and promoting awareness
            of mental health.
            <br />
          </div>
        </div>
        <section id="explore-about" className="main">
          <div className={`container mainsec `}>
            <div className={`row maintext`}>
              <div className={`col-md-6 col-12 left`}>
                <div className="boxwrapper">
                  <h3 className="heading">Our Mission</h3>
                  <p className="description">
                    We bring deeply rooted science of nature to the forefront aided
                    by technology in creating its relevance in modern day healthy
                    living.
                  </p>
                </div>
              </div>
              <div className={`col-md-6 col-12 right`}>
                <div className="boxwrapper">
                  <h3 className="heading">Our Vision</h3>
                  <p className="description">
                    Be the trusted provider of self-care ecosystem for managing
                    anxiety & chronic diseases, leading to joy & growth in millions &
                    billions of lives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="div-27">
          <div className="div-29">
            <div className="div-30">
              <div className="column-3">
                <div className="div-31">
                  Ask our happy customers about our quality
                </div>
              </div>
              <div className="column-4">
                <div className="div-32">
                  To be one of the pioneering, dynamic and leading companies that
                  offer quality products and services with an understanding of
                  continuous improvement in the fields in which it operates.
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-4">
              <YouTube
                videoId="i-343XBFbNk"
                iframeClassName="about-us-testimonial"
              />
            </div>
            <div className="col-md-4">
              <YouTube
                videoId="_umUEq-ANjg"
                iframeClassName="about-us-testimonial"
              />
            </div>
            <div className="col-md-4">
              <YouTube
                videoId="HMMX3vJTNOg"
                iframeClassName="about-us-testimonial"
              />
            </div>
          </div>
        </div>
        <div className="div-35">
          <div className="div-36">
            <div className="div-37">
              Be the go-to source for self-care and impact billions of lives by
              promoting well-being and managing anxiety, and chronic diseases to
              foster joy and growth.
            </div>
          </div>
        </div>
        <TeamWellM />
        {/* <TeamSectionResponsive /> */}
        <div className="div-54">
          <div className="div-55">Partner with WellM</div>
          <img
            loading="lazy"
            src="/img/about-us-why.png"
            className="img-28"
            alt=""
          />
        </div>
        <div className="whyPartner">
          <WhyPartner />
        </div>
        <AppDownload
          heading="Ready to revolutionise Set foot towards a revolutionary journey!"
          subheading="Self-development isn't a single stride but a collection of small efforts that accumulate over time."
          image="/img/download-app3.png"
        />
        <Newsletter />
      </div>{" "}
      <style jsx>{`
        .main {
          background-image: url("/img/about_back.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 100vh;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
        }
        .mainsec {
          height: 100%;
          margin-bottom: 4%;
        }
        .maintext {
        }
        .about-us-testimonial {
          width: 100%;
          border-radius: 8px;
          height: 32vh;
        }
        .description {
          font-weight: 400;
          font-size: 18px;
          color: #646464;
          width: 95%;
          margin-top: 2%;
        }
        .description span {
          font-size: 24px;
          letter-spacing: 2px;
          font-weight: 500;
        }
        .boxwrapper {
          background-color: rgb(254, 254, 252);
          border-radius: 20px;
          padding: 18px;
          margin: auto;
          text-align: center;
        }
        .heading {
          background: linear-gradient(to right, #eae96d 0%, #50b081 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 40px;
          letter-spacing: 1px;
        }
        @media only screen and (max-width: 480px) {
          .boxwrapper {
            height: 87%;

            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .div-37 {
            font-size: 1.2rem !important;
          }
          .div-az {
            margin: 0 !important;
          }
          .div-az-4 {
            font-size: 1.1rem !important;
          }
          .div-az-7 li {
            font-size: 1.1rem !important;
          }
          .main h3 {
            font-size: 28px;
          }
          .main p {
            font-size: 17px;
          }
          .main {
            background-image: url("https://i.pinimg.com/736x/fa/13/a8/fa13a8167308a55acc0312befd7ad03a.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 5%;
            justify-content: center;
          }
          .mainsec {
            height: 110%;
          }
          .boxwrapper p {
            width: 100%;
            text-align: center;
            padding: 25px;
          }
          .main {
            padding: 0 2%;
          }

          .boxwrapper .heading {
            margin-bottom: 0%;
          }
        }

        .div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .div-2 {
          align-self: center;
          display: flex;
          margin-top: 14%;
          width: 100%;
          max-width: 1320px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .div-3 {
          align-self: center;
          display: flex;
          margin-left: -20px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .img {
          aspect-ratio: 2.05;
          object-fit: cover;
          object-position: center;
          width: 219px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-4 {
          align-self: center;
          display: flex;
          width: 847px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-4 {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .div-5 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-6 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .whyPartner {
          display: flex;
          padding: 4%;
        }

        .div-7 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-8 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-9 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-10 {
          color: rgba(56, 56, 56, 0.9);
          text-align: center;
          letter-spacing: 0.5px;
          align-self: center;
          margin: auto 0;
          font: 600 20px/120% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-11 {
          color: var(--neutral-0, #fff);
          letter-spacing: 0.5px;
          align-self: stretch;
          text-wrap: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background-color: #e77c40;
          width: 187px;
          max-width: 100%;
          padding: 12px 11px;
          font: 600 16px/150% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-11 {
            text-wrap: initial;
          }
        }
        .div-12 {
          align-self: center;
          margin: 4% auto;
          width: 100%;
          max-width: 1202px;
        }
        @media (max-width: 991px) {
          .div-12 {
            max-width: 100%;
            margin-top: 0px;
          }
        }
        .div-13 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-13 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-14 {
          display: flex;
          margin-top: 50px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-14 {
            max-width: 100%;
            margin-top: 3rem;
          }
        }
        .div-15 {
          color: #e77c40;
          font: 400 48px/125% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-15 {
            font-size: 40px;
            margin-top: 20px;
          }
        }
        .div-16 {
          align-items: flex-start;
          align-self: start;
          display: flex;
          margin-top: 44px;
          width: 437px;
          max-width: 100%;
          flex-grow: 1;
          padding-bottom: 12px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-16 {
            margin-top: 0px;
          }
        }
        .div-17 {
          color: #473e3e;
          letter-spacing: 0.12px;
          max-width: 347px;
          align-self: start;
          font: 400 24px/116.667% Outfit, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-18 {
          align-items: flex-start;
          align-self: start;
          display: flex;
          margin-top: 21px;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-18 {
            max-width: 100%;
          }
        }
        .div-19 {
          justify-content: start;
          align-items: flex-start;
          align-self: start;
          display: flex;
          width: 286px;
          max-width: 100%;
          gap: 12px;
        }
        @media (max-width: 991px) {
          .div-19 {
            justify-content: flex-start;
          }
        }
        .div-20 {
          color: #fff;
          align-self: stretch;
          text-wrap: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #e77c40;
          width: 119px;
          max-width: 100%;
          padding: 10px 20px;
          font: 600 14px/142.857% Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-20 {
            text-wrap: initial;
          }
        }
        .div-21 {
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid #ced2da;
          align-self: stretch;
          display: flex;
          width: 155px;
          max-width: 100%;
          gap: 8px;
          padding: 10px 20px;
        }
        @media (max-width: 991px) {
          .div-21 {
            text-wrap: initial;
            display: none;
          }
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 20px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-22 {
          color: #344051;
          align-self: stretch;
          text-wrap: nowrap;
          font: 600 14px/142.857% Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-22 {
            text-wrap: initial;
          }
        }
        .div-23 {
          align-items: flex-start;
          align-self: start;
          display: flex;
          margin-top: 24px;
          width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-23 {
            max-width: 100%;
          }
        }
        .div-24 {
          color: #344051;
          font: 400 18px/155.556% Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-24 {
            max-width: 100%;
          }
        }
        .img-3 {
          aspect-ratio: 5;
          object-fit: cover;
          object-position: center;
          width: 140px;
          justify-content: center;
          align-items: flex-start;
          overflow: hidden;
          align-self: start;
          margin-top: 8px;
          max-width: 100%;
          flex-grow: 1;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .img-4 {
          aspect-ratio: 1.45;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-4 {
            max-width: 87%;
            margin-top: 40px;
          }
        }
        .div-25 {
          color: var(--Black, #000);
          align-self: start;
          max-width: 1159px;
          margin: 71px 0 0 28px;
          font: 400 30px/126.667% Outfit, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-25 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-26 {
          color: #646464;
          max-width: 1205px;
          margin: 5% auto;
          font-size: 1.2rem;
          font-weight: 300;
        }
        @media (max-width: 991px) {
          .div-26 {
            max-width: 100%;
            margin-top: 40px;
            margin-left: 5vw;
            margin-right: 5vw;
          }
        }
        .div-27 {
          align-self: center;
          display: flex;
          margin-top: 144px;
          width: 100%;
          max-width: 1234px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-27 {
            max-width: 100%;
            margin-top: 0px;
          }
        }
        .div-28 {
          color: var(--Black, #000);
          opacity: 0.3;
          font: 700 20px/160% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-28 {
            margin-left: 7px;
          }
        }
        .div-29 {
          align-self: stretch;
          margin-top: 34px;
        }
        @media (max-width: 991px) {
          .div-29 {
            max-width: 100%;
          }
        }
        .div-30 {
          gap: 20px;
          display: flex;
          margin-bottom: 12px;
        }
        @media (max-width: 991px) {
          .div-30 {
            flex-direction: column;
            align-items: self-end;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-31 {
          font-size: 2rem;
          color: #646464;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-31 {
            max-width: 100%;
            margin-top: 40px;
            font-size: 40px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .div-32 {
          color: var(--Black, #000);
          letter-spacing: 0.36px;
          opacity: 0.7;
          max-width: 617px;
          margin: auto;
          font: 400 18px/166.667% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-32 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-33 {
          align-self: center;
          display: flex;
          margin-top: 90px;
          width: 100%;
          max-width: 1104px;
          align-items: flex-start;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .div-33 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .img-5 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-6 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-7 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-8 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-9 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-10 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-11 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-12 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-13 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-14 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .div-34 {
          align-self: center;
          display: flex;
          width: 656px;
          max-width: 100%;
          align-items: flex-start;
          gap: 15px;
          margin: 48px 0 0 42px;
        }
        @media (max-width: 991px) {
          .div-34 {
            flex-wrap: wrap;
            justify-content: center;

            margin: 0px;
            margin-top: 40px;
          }
        }
        .img-15 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-16 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-17 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-18 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-19 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-20 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .div-35 {
          align-self: stretch;
          display: flex;
          margin-top: 142px;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-35 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-36 {
          background-color: #c8e2fa;
          align-self: start;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 76px 20px 65px;
        }
        @media (max-width: 991px) {
          .div-36 {
            max-width: 100%;
          }
        }
        .div-37 {
          text-align: center;
          align-self: center;
          max-width: 1159px;
          font-size: 1.5rem;
          font-weight: 400;
          color: #646464;
        }
        @media (max-width: 991px) {
          .div-37 {
            max-width: 100%;
            font-size: 32px;
          }
        }
        .div-38 {
          color: var(--Black, #000);
          align-self: start;
          max-width: 532px;
          margin: 75px 0 0 84px;
          font: 400 48px/125% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-38 {
            font-size: 40px;
            margin: 40px 0 0 10px;
          }
        }
        .div-39 {
          align-self: center;
          display: flex;
          margin-top: 89px;
          width: 100%;
          max-width: 1286px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-39 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-40 {
          color: var(--Black, #000);
          align-self: start;
          max-width: 532px;
          font: 400 48px/125% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-40 {
            max-width: 100%;
            font-size: 32px;
          }
        }
        .div-41 {
          align-self: center;
          width: 100%;
          margin: 76px 0 0 -20px;
        }
        @media (max-width: 991px) {
          .div-41 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-42 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-42 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 62%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .div-43 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-43 {
            max-width: 100%;
            margin-top: 10px;
          }
        }
        .div-44 {
          border-radius: 10px;
          background-color: #fae9c8;
          align-self: start;
          display: flex;
          width: 713px;
          max-width: 100%;
          flex-direction: column;
          padding: 25px 20px 11px;
        }
        .div-45 {
          color: var(--Black, #000);
          text-align: center;
          align-self: center;
          max-width: 702px;
          font: 400 35px/171.429% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-45 {
            max-width: 100%;
            font-size: 32px;
          }
        }
        .div-46 {
          color: #696767;
          align-self: start;
          margin-top: 46px;
          max-width: 706px;
          font: 400 35px/135.714% Outfit, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-46 {
            max-width: 90%;
            margin-top: 40px;
            align-self: center;
          }
        }
        .div-47 {
          align-self: start;
          width: 775px;
          max-width: 100%;
          margin: 69px 0 0 19px;
        }
        @media (max-width: 991px) {
          .div-47 {
            margin-top: 40px;
          }
        }
        .div-48 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-48 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 60%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .img-21 {
          aspect-ratio: 0.85;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          margin-top: 66px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-21 {
            margin-top: 40px;
            width: 90%;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 40%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .div-49 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-49 {
            margin-top: 40px;
            display: none !important;
          }
        }
        .img-22 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 217px;
          overflow: hidden;
          border-radius: 50%;
          align-self: end;
          max-width: 100%;
        }
        .img-23 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          align-self: end;
          margin-top: 38px;
          flex-grow: 1;
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 38%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .div-50 {
          display: flex;
          margin-top: 140px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-50 {
            max-width: 100%;
            margin-top: 40px;
            display: none;
          }
        }
        .img-24 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 271px;
          overflow: hidden;
          border-radius: 50%;
          align-self: end;
          margin-right: 10px;
          max-width: 100%;
        }
        .div-51 {
          align-self: end;
          width: 482px;
          max-width: 100%;
        }
        .div-52 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-52 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 60%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .div-53 {
          z-index: 1;
          display: flex;
          margin-right: -33px;
          flex-grow: 1;
          flex-direction: column;
        }
        .img-25 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 220px;
          overflow: hidden;
          border-radius: 50%;
          align-self: center;
          margin-left: -22px;
          max-width: 100%;
        }
        .img-26 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          align-self: stretch;
          margin-top: 52px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-26 {
            margin-top: 40px;
          }
        }
        .column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 40%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .img-27 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 205px;
          overflow: hidden;
          border-radius: 50%;
          max-width: 100%;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .img-27 {
            margin-top: 40px;
          }
        }
        .div-54 {
          align-self: center;
          display: flex;
          margin-top: 2%;
          width: 100%;
          max-width: 1248px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-54 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-55 {
          text-align: center;
          align-self: center;
          max-width: 772px;
          font-size: 2.8rem;
          color: #646464;
          font-weight: 400;
          margin-bottom: 1%;
        }
        @media (max-width: 991px) {
          .div-55 {
            font-size: 40px;
          }
        }
        .img-28 {
          object-fit: cover;
          object-position: center;
          width: 80%;
          margin: auto;
          margin-top: auto;
          height: 70vh;
          margin-top: 2%;
          border-radius: 10px;
        }
        @media (max-width: 991px) {
          .img-28 {
            max-width: 100%;
            margin-top: 40px;
            width: 100vh;
            height: auto;
          }
        }
        .div-56 {
          align-self: center;
          margin-top: 82px;
          width: 100%;
          max-width: 1153px;
          padding: 0 20px;
          margin-bottom: 10%;
        }
        @media (max-width: 991px) {
          .div-56 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-57 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-57 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-11 {
            width: 100%;
          }
        }
        .img-29 {
          aspect-ratio: 1.2;
          object-fit: cover;
          object-position: center;
          width: 100%;
          fill: rgba(253, 251, 164, 0.59);
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-29 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-12 {
            width: 100%;
            margin-left: 0px;
          }
        }
        .div-58 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-58 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-59 {
          color: #e77c40;
          align-self: start;
          max-width: 525px;
          font: 800 37.285px Abhaya Libre ExtraBold, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-59 {
            max-width: 100%;
            align-self: center;
            max-width: 100vh;
          }
        }
        .div-60 {
          color: #797979;
          letter-spacing: 0.48px;
          margin-top: 51px;
          font: 500 24px Outfit, sans-serif;
        }
        @media (max-width: 991px) {
          .div-60 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-61 {
          align-self: start;
          display: flex;
          margin-top: 65px;
          width: 399px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-61 {
            margin-top: 40px;
          }
        }
        .div-62 {
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          background-color: #e77c40;
          align-self: stretch;
          display: flex;
          width: 131px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px;
        }
        .div-63 {
          color: var(--default-white, #fff);
          letter-spacing: 0.5px;
          align-self: center;
          text-wrap: nowrap;
          font: 500 20px/100% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-63 {
            text-wrap: initial;
          }
        }
        .div-64 {
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: 2px solid #646464;
          align-self: stretch;
          display: flex;
          width: 245px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px;
        }
        .div-65 {
          color: rgba(56, 56, 56, 0.91);
          letter-spacing: 0.5px;
          align-self: center;
          text-wrap: nowrap;
          font: 600 20px/100% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-65 {
            text-wrap: initial;
          }
        }
        .div-66 {
          align-self: center;
          width: 100%;
          max-width: 1155px;
          margin: 147px 0 80px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-66 {
            max-width: 100%;
            margin: 40px 0;
          }
        }
        .div-67 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-67 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-13 {
            width: 100%;
          }
        }
        .div-68 {
          display: flex;
          margin-top: 24px;
          width: 311px;
          max-width: 100%;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-68 {
            margin-top: 40px;
          }
        }
        .div-69 {
          align-self: start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
        }
        .div-70 {
          color: #274c5b;
          align-self: end;
          text-wrap: nowrap;
          font: 700 30px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .div-70 {
            margin-right: 6px;
            text-wrap: initial;
          }
        }
        .div-71 {
          color: #525c60;
          text-align: right;
          margin-top: 31px;
          font: 400 18px/165% Open Sans, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-72 {
          background-color: #716a6a;
          align-self: start;
          width: 1px;
          height: 336px;
        }
        .column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-14 {
            width: 100%;
          }
        }
        .div-73 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-73 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .img-30 {
          aspect-ratio: 1.89;
          object-fit: cover;
          object-position: center;
          width: 166px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .div-74 {
          align-items: center;
          align-self: stretch;
          display: flex;
          margin-top: 7px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-74 {
            max-width: 100%;
          }
        }
        .div-75 {
          color: #525c60;
          text-align: center;
          font: 400 18px/165.4% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-75 {
            max-width: 100%;
          }
        }
        .div-76 {
          justify-content: center;
          align-items: flex-start;
          align-self: center;
          display: flex;
          margin-top: 49px;
          width: 285px;
          max-width: 100%;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .div-76 {
            margin-top: 40px;
          }
        }
        .img-31 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .img-32 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .img-33 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .img-34 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 20%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-15 {
            width: 100%;
          }
        }
        .div-77 {
          display: flex;
          margin-top: 20px;
          width: 219px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-77 {
            margin-top: 40px;
          }
        }
        .div-78 {
          background-color: #716a6a;
          align-self: start;
          width: 1px;
          height: 336px;
        }
        .div-79 {
          align-self: start;
          display: flex;
          margin-top: 4px;
          flex-direction: column;
        }
        .div-80 {
          color: #274c5b;
          align-self: stretch;
          text-wrap: nowrap;
          font: 700 30px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .div-80 {
            text-wrap: initial;
          }
        }
        .div-81 {
          color: #525c60;
          align-self: stretch;
          margin-top: 11px;
          width: 100%;
          font: 400 18px/272.9% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-81 {
            margin-right: 2px;
          }
        }
      `}</style>{" "}
    </>
  )
}
