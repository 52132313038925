"use client";
import React from "react";

export function Privacypolicy(props) {
  return (
    <>
      <section id="legal" className="head">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 text-center">
              <h2>PRIVACY POLICY</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p>
                We may occasionally make changes to the Policy. If we do, the same shall be reflected on our website.
                Please make sure to check the website periodically.{" "}
              </p>
              <p>
                We hold the sincere belief that you should always know what data we collect from you, the purposes for
                which such data is used, and that you should have the ability to make informed decisions about what data
                you want to share with us.
              </p>
              <p>
                This is why we want to be fully transparent about: (i) how and why we collect, store, share and use your
                personal data through the various capacities in which you interact with us; and (ii) the rights that you
                have to determine the parameters of this interaction.
              </p>
              <p>
                While we would strongly advise you to read the Policy in full, the following summary will give you a
                snapshot of the salient points covered herein:
              </p>
              <ul className="legalPoints">
                <li>
                  This Policy details the critical aspects governing your personal data relationship with ‘Wellness
                  Mandala’ which is owned and operated by Wellness Mandala Private Limited, a company incorporated under
                  the laws of India, and having its registered office at 3rd floor, Times Square Building, Block B,
                  Sushant Lok Phase-1, Gurgaon, Haryana, 122002 , India;
                </li>
                <li>
                  Your personal data relationship with Wellness Mandala varies based on the capacity in which you
                  interact with us/avail of our products and services (<strong>“Services”</strong>). You could be: (i) a
                  visitor to{" "}
                  <a href="https://www.wellm.co/" target="blank">
                    https://www.wellm.co/
                  </a>{" "}
                  (<strong>“Website”</strong>) or any pages thereof (<strong>“Visitor”</strong>); or (ii) a person who
                  has an account with Wellness Mandala to avail our Services (<strong>“Registered User”</strong>);
                </li>
                <li>
                  Based on whether you are a Visitor or Registered User, the type of data we collect and the purpose for
                  which we use it will differ and this Policy details such variations;
                </li>
                <li>
                  This Policy applies to all the Services provided by Wellness Mandala through the Website, App or any
                  other associated website of Wellness Mandala;
                </li>
                <li>This Policy is a part of and should be read in conjunction with our Terms of Service; and</li>
                <li>
                  This Policy will clarify the rights available to you vis-à-vis the personal data you share withus.
                </li>
              </ul>
              <p>
                If you have any queries or concerns with this Policy, please contact our Grievance Officer. If you do
                not agree with the Policy, we would advise you to not visit/use the Website or the App.
              </p>

              <h3 className="hdPrivacy">1. INFORMATION WE COLLECT AND HOW WE USE IT</h3>
              <table className="table table-bordered mt-4" style={{ color: "#999" }}>
                <thead>
                  <tr>
                    <th scope="col" width="15%">
                      TYPE OF USER
                    </th>
                    <th scope="col">VISITOR</th>
                    <th scope="col">REGISTERED USER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" width="15%">
                      What data we may collect
                    </th>
                    <td>
                      <ul className="legalPoints">
                        <li>Your IP Address;</li>
                        <li>Your location;</li>
                        <li>How you behave on the Website, (what pages you land on, how much time you spend, etc.);</li>
                        <li>
                          What device you use to access the Website and its details (model, operating system, etc.); and
                        </li>
                        <li>Cookies and web beacon data.</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Your name, age, gender, phone number, e-mail address, mailing address;</li>
                        <li>Your height, weight;</li>
                        <li>
                          Your lifestyle, food preferences, medical conditions (if any), health goals or other fitness
                          regimes;
                        </li>
                        <li>Your ethnicity, genetics, health;</li>
                        <li>Your IP Address;</li>
                        <li>
                          Your location unless you deactivate location services in the relevant section of the
                          Website/App;
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">How and why we use it</th>
                    <td>
                      <p>
                        We use this information to analyze and identify your behavior and enhance the interactions you
                        have with theWebsite.
                      </p>
                      <p>
                        If you submit your details and give us your consent, we may use your data to send you
                        emails/newsletters, re-target Wellness Mandala advertisements or re-market our Services using
                        services from third-parties like Facebook, Google, Instagram, Whatsapp, etc.
                      </p>
                    </td>
                    <td>
                      <p>
                        We collect this data in order to help you register for and facilitate provision of our Services.
                      </p>
                      <p>We use this data to show age-appropriate content and advertisements.</p>
                      <p>We use this data to track your lifestyle & emotional health.</p>

                      <p>
                        We use the data from the device you access our Website/App to identify the login information of
                        multiple users from the same device.
                      </p>
                      <p>
                        We use this data to prepare a specific diet plan, training regime and emotional wellbeing
                        practices for you.
                      </p>
                      <p>
                        We use this data to enable you to make payments for our Services. We use a third-party service
                        provider to manage payment processing. This service provider is not permitted to store, retain,
                        or use information you provide except for the sole purpose of payment processing on our behalf
                      </p>
                      <p>
                        We use this data for processing your requests, enquiries and complaints, customer services and
                        related activities.
                      </p>
                      <p>
                        We use this data to communicate about existing or new offers, content, advertisements, surveys,
                        key policies or other administrative information.
                      </p>
                      <p>
                        We also use this data to provide you with informative features and services that are developed
                        by us from time to time.
                      </p>
                      <p>We also use this data for providing, testing, improving, or recommending the Services.</p>
                      <p>We also use this data for analytics and reviews to improve the services and draw trends.</p>
                      <p>
                        We also use this data to provide support to law enforcement agencies or in connection with an
                        investigation on matters related to public safety, as permitted by law or anti-fraud
                        activities/systems.
                      </p>
                      <p>
                        If you give us your consent, we may send you newsletters and emails to market other products and
                        services we may provide.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="hdList">SPECIAL CATEGORIES OF PERSONAL DATA</h4>
              <p>
                We may collect and process ‘special categories of personal data’ such as ethnic origin, genetics, health
                or sexual orientation, which are considered sensitive. We use this special category of personal data,
                for example to provide specialized diet plans and exercise routines. We require your explicit consent to
                collect and process special categories of personal data. You may refuse or revoke your consent at any
                time, please see the section about &apos;Your Rights & Preferences As A Data Subject In The EU&apos;
                below. A limited number of our personnel will have access to special categories of personal data
                provided by you.
              </p>

              <h4 className="hdList">WHAT ARE THE COOKIES AND WEB BEACONS REFERRED TO ABOVE?</h4>
              <table className="table table-bordered" style={{ color: "#999" }}>
                <thead>
                  <tr>
                    <th scope="col" width="15%">
                      TYPE OF FILE
                    </th>
                    <th scope="col">COOKIES</th>
                    <th scope="col">WEB BEACON</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" width="15%">
                      What they do
                    </th>
                    <td>
                      <p>
                        Cookies are text files which are sent from our server and downloaded to your device when you
                        visit our website.
                      </p>
                      <p>
                        They are useful because they allow us to recognize your device when you return. You can disable
                        them through your browser should you so wish.
                      </p>
                      <p>
                        We use cookies to see which parts of our website are used by Registered Users/Visitors and to
                        record the number of visits to our website.
                      </p>
                      <p>
                        You can find more information about cookies at:{" "}
                        <a href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a>
                      </p>
                    </td>
                    <td>
                      <p>
                        Web beacon (also known as Clear GIF, Web Bugs or Pixel Tag) is a tiny picture file embedded on
                        the Website/App that tracks your behavior and navigation. It is similar to a cookie in what it
                        does, but it does not get downloaded onto your device.
                      </p>
                      <p>
                        We use web beacons to manage cookies, record visits, and to learn marketing metrics. We also use
                        web beacons to track and monitor email opening rates as well as link clicks.
                      </p>
                      <p>
                        You can find more information about web beacons at:{" "}
                        <a href="https://www.allaboutcookies.org/">Cookies</a>
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">What they won&apos;t do</th>
                    <td colSpan={2}>
                      <ul className="legalPoints">
                        <li>Contain any executable software, malicious code or virus.</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                Usually, browsers have default settings to accept cookies until you change your browser settings. You
                can choose to reject and remove cookies from our Website by changing your browser settings. If you
                reject or remove our cookies, it could affect how our Website works.
              </p>

              <p>
                For the avoidance of any doubt, we should clarify that in the event we anonymize and aggregate
                information collected from you, we will be entitled to use such anonymized data freely, without any
                restrictions other than those set out under applicable law.
              </p>

              <h3 className="hdPrivacy">2. YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT</h3>
              <table className="table table-bordered mt-4" style={{ color: "#999" }}>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>RIGHT TO BE INFORMED</strong>
                      </p>
                      <p>
                        You have a right to be informed about the manner in which any of your personal data is collected
                        or used which we have endeavored to do by way of this Policy.
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>RIGHT OF ACCESS</strong>
                      </p>
                      <p>
                        You have a right to access the personal data which you have provided by requesting us to provide
                        you with the same.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>RIGHT TO RECTIFICATION</strong>
                      </p>
                      <p>
                        You have a right to request us to amend or update your personal data if it is inaccurate or
                        incomplete.
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>RIGHT TO ERASURE</strong>
                      </p>
                      <p>You have a right to request us to delete yourpersonal data.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>RIGHT TO RESTRICT</strong>
                      </p>
                      <p>
                        You have a right to request us to temporarily or permanently stop processing all or some of your
                        personal data.
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>RIGHT TO OBJECT</strong>
                      </p>
                      <p>
                        You have a right, at any time, to object to our processing of your personal data under certain
                        circumstances. You have an absolute right to object to us processing your personal data for the
                        purposes of direct marketing.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>RIGHT TO DATA PORTABILITY</strong>
                      </p>
                      <p>
                        You have a right to request us to provide you with a copy of your personal data in electronic
                        format and you can transmit that personal data for using another third-party’s product/service.
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>RIGHT NOT TO BE SUBJECT TO AUTOMATED DECISION-MAKING</strong>
                      </p>
                      <p>
                        You have a right to not be subject to a decision based solely on automated decision making,
                        including profiling.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                In case you want to exercise the rights set out above you can contact our Grievance Officer/Customer
                Assistance.
              </p>

              <p>
                The data provided by you as a Visitor, or when you sign up as a Registered User for our Services will be
                processed by us for the purpose of rendering Services to you or in order to take steps prior to
                rendering such Services, at your request. Where such data is not being used by us to render Services to
                you, we shall explicitly seek your consent for using the same. You can choose to withdraw this consent
                at any time by writing to us at contact@wellm.co Additionally, we may process your data to serve
                legitimate interests.
              </p>

              <p>Accordingly, the grounds on which we can engage in processing are as follows:</p>

              <table className="table table-bordered" style={{ color: "#999" }}>
                <thead>
                  <tr>
                    <th scope="col">NATURE OF DATA</th>
                    <th scope="col">GROUNDS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>Visitor Data</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Consent;</li>
                        <li>Performance of a Contract; and</li>
                        <li>Legitimate Interest.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Account Registration Data</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Performance of a Contract; and</li>
                        <li>Legitimate Interest.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Special Categories of Personal Data</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Consent; and</li>
                        <li>Performance of a Contract.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Payment Data</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Legitimate Interest; and</li>
                        <li>Performance of a Contract; and</li>
                        <li>Compliance with applicable laws.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Service Usage Data</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Performance of a Contract; and</li>
                        <li>Legitimate Interest.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Data for Marketing our Services</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Consent; and</li>
                        <li>Legitimate Interest.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Personal Stories</p>
                    </td>
                    <td>
                      <ul className="legalPoints">
                        <li>Consent.</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                If you believe we have used your personal data in violation of the rights above or have not responded to
                your objections, you may lodge a complaint with your local supervisory authority.
              </p>

              <h3 className="hdPrivacy">3. PUBLISHED CONTENT</h3>
              <p>
                Any success stories, comments, messages, blogs, scribbles etc. posted/ uploaded/ conveyed/ communicated
                by you on the public sections of the Website/App becomes published content. We may publish such
                published content on our Website/App so long as you consent. You may request that such published content
                be taken down at any time and we shall remove such published content. However, we are not responsible
                for any actions taken by third-parties with respect to such published content.
              </p>

              <h3 className="hdPrivacy">4. INFORMATION WE GET FROM OTHERS</h3>
              <p>
                We may receive data about you from other sources i.e., expert calls, surveys, and such data may be added
                to our Website/App from time to time. Such data may include your behaviour towards various content
                posted on our Website/App from other sources. We use the data collected from other sources to prepare a
                specific diet plan and training regime for you. We also use this data for providing, testing, improving,
                or recommending the Services.
              </p>

              <h3 className="hdPrivacy">5. RETENTION OF PERSONAL INFORMATION</h3>
              <p>
                We will store any personal data we collect from you as long as it is necessary in order to facilitate
                your use of the Services and for ancillary legitimate and essential business purposes – these include,
                without limitation, for improving our Services, attending to technical issues, and dealing with
                disputes.
              </p>
              <p>
                Your personal data will primarily be stored in electronic form. We may enter into agreement with third
                parties to collect, store, process your personal data but under full compliance with applicable laws.
              </p>
              <p>
                We may need to retain your personal data even if you seek deletion thereof, if it is needed to comply
                with our legal obligations, resolve disputes and enforce our agreements.
              </p>
              <p>
                If you are a Registered User, please be advised that after you terminate your usage of a Service, we
                may, unless legally prohibited, delete all data provided or collected by you from our servers.
              </p>

              <h3 className="hdPrivacy">6. TRACKING BY OUR ADVERTISEMENT PARTNERS</h3>
              <p>
                Upon receiving your specific consent, we may share the data we collect from cookies or web beacons with
                our advertisement partners to track your visits, establish your non-personal identity and present you
                with targeted advertisements about our Services.
              </p>

              <h3 className="hdPrivacy">7. COMPLIANCE WITH CHILDREN’S ONLINE PRIVACY PROTECTION ACT</h3>
              <p>
                As a business service, we neither knowingly collect or store nor use any personal data of any
                individuals under the age of 13 years. We do not target our Services towards individuals under the age
                of 13. Individuals under the age of 18 should seek the consent of their parents before providing any
                information about themselves, their parents, or other family members on our Website/App.
              </p>

              <h3 className="hdPrivacy">8. TRANSFER OF INFORMATION</h3>
              <p>
                In the ordinary course of business, we may employ other companies and people to assist us in providing
                certain components of our Services in compliance with the provisions of this Policy. To do so, we may
                need to share your data with them.
              </p>
              <p>
                We shall put in place industry-standard technical and organizational measures (including robust data
                handling policies) to ensure that such transfers are completed in accordance with applicable laws.
              </p>
              <p>
                Some of the examples of where we may subcontract processing activities to third parties include—record
                keeping, data analysis, marketing assistance, processing payments, and providing customer service.
              </p>

              <h3 className="hdPrivacy">9. THIRD PARTY LINKS</h3>
              <p>
                We may display links to third-party websites or applications on our Website or App for advertising or
                providing you with relevant content. We will not be responsible for such third-party websites or
                applications if you choose to access them. If you provide any data to such a website/application, please
                ensure you read their policies given that you will no longer be bound by this Policy in doing so.
              </p>
              <p>
                We may receive data whenever you visit a third-party link through our Website/App which includes the
                date and time of your visit to the third-party website, the web address or URL, technical information
                about the IP address, browser and the operating system you use and, if you are logged into Wellness
                Mandala, your username.
              </p>

              <h3 className="hdPrivacy">10. COMPELLED DISCLOSURE</h3>
              <p>
                In addition to the purposes set out in the Policy, we may disclose any data we collected or processed
                from you if it is required:
              </p>
              <ul className="legalPoints">
                <li>
                  under applicable law or to respond to a legal process, such as a search warrant, court order, or
                  subpoena;
                </li>
                <li>
                  to protect our safety, your safety or the safety of others or in the legitimate interest of any party
                  in the context of national security, law enforcement, litigation, criminal investigation or to prevent
                  death or imminent bodily harm;
                </li>
                <li>to investigate fraud, credit risk or violation of our Acceptable Use Policy;</li>
                <li>
                  in connection with legal proceedings brought against Wellness Mandala, its officers, employees,
                  affiliates, customers or vendors;
                </li>
                <li>to establish, exercise, protect, defend and enforce our legal rights; or</li>
                <li>
                  When we do a business deal or negotiate a business deal, or our assets are merged or acquired by the
                  other business entity, or during restructuring of business or reorganization, we may have to share
                  information provided by you with the other business entities.
                </li>
              </ul>

              <h3 className="hdPrivacy">11. SECURITY OF YOUR PERSONAL INFORMATION</h3>
              <p>
                We implement industry-standard technical and organizational measures by using a variety of security
                technologies and procedures to help protect your data from unauthorized access, use, loss, destruction
                or disclosure. When we collect particularly sensitive data, it is encrypted using industry-standard
                cryptographic techniques including but not limited to PBKDF2, AES256, TLS1.2 & SHA256.
              </p>
              <p>
                The collection, storage, usage, and sharing of your data by the Company shall be in compliance with the
                Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011 and other applicable laws.
              </p>
              <p>
                Your password is your first line of defense once you set up a Wellness Mandala account, we recommend
                that you set a strong password which you never share with anyone.
              </p>
              <p>
                We have taken appropriate steps for the security and protection of all our digital platforms including
                internal applications, however, we shall not be responsible for any breach of security or the disclosure
                of personal data for reasons outside our control, such as hacking, social engineering, cyber terrorism,
                espionage by third parties, or any events by way of force majeure such as sabotage, fire, flood,
                explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection,
                war or acts of government.
              </p>

              <h3 className="hdPrivacy">12. ACCESS, CORRECTION & DELETION</h3>
              <p>
                You may request access, correction or updation, and deletion of the data by contacting
                <a href="mailto:contact@wellm.co">contact@wellm.co</a>. You may note that deletion of certain data or
                withdrawal of consent may lead to cancellation of your registration with us or your access to our
                Services.
              </p>
              <p>
                Based on technical feasibility, we will provide you with access to all your personal and sensitive
                personal data that we maintain about you. We will perform verification before providing you access to
                this data.
              </p>
              <p>
                You may opt out of our marketing emails by clicking on the ‘opt-out’ link provided in the emails.
                However, we may still send you non-marketing emails about your accounts or any other transactions with
                you.
              </p>

              <h3 className="hdPrivacy">13. GRIEVANCE OFFICER</h3>
              <table className="table table-bordered mt-3" style={{ color: "#999" }}>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>Shivi Sabharwal</td>
                  </tr>
                  <tr>
                    <td>ADDRESS:</td>
                    <td>
                      3rd floor, Times Square Building, Block B, Sushant Lok Phase-1, Gurgaon, Haryana, 122002 , India
                    </td>
                  </tr>
                  <tr>
                    <td>EMAIL:</td>
                    <td>
                      <a href="mailto:contact@wellm.co">contact@wellm.co</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Privacypolicy;
