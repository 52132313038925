"use client";
import React, { useState } from "react";
import "./app_download.css";
import Button from "react-bootstrap/Button";
import AppDownloadModal from "../App_Download_Modal";
import ContactUsModal from "../Contact_Us_Modal";
import ReactGA from 'react-ga4';


export function AppDownload({
  heading = "Ready for a happier, healthier you? Join Now!",
  subheading = "Self-development isn't a single stride but a collection of small efforts that accumulate over time.",
  image = "/img/donwload-app2.png",
  showGetStarted = true,
  imageWidth = 90,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () =>
  
  {

    setShow(true);
    ReactGA.event({
      category: 'bottom_download_app_button',
      action: 'Click',
      label: window.location
      
  });
  
  
  
  }
  


  const [showContact, setShowContact] = useState(false);

  const handleContactClose = () => setShowContact(false);
  const handleContactShow = () => {
    ReactGA.event({
      category: 'bottom_section_get_started_button',
      action: 'Click',
      label: window.location
      
  });
    setShowContact(true);
  
  
  
  }

  return (
    <>
      <AppDownloadModal
        show={show}
        handleClose={handleClose}
        heading="Nurturing your emotional and mental well-being to pave the way for holistic wellness."
      />
      <ContactUsModal
        show={showContact}
        handleClose={handleContactClose}
        heading="Share your details here, and our client success team will get in touch with you."
      />
      <div className="app-download container">
        <div className="row">
          <div className="col-md-6">
            <img src={image} alt="donwload app" style={{ width: `${imageWidth}%` }} />
          </div>
          <div className="col-md-6 app-download-right">
            <h2 className="app-download-heading">{heading}</h2>
            <p className="app-download-description">{subheading}</p>

            <div className="row" style={{ marginTop: "6%" }}>
              {showGetStarted && (
                <div className="col-md-6">
                  <Button
                    variant="success"
                    size="lg"
                    onClick={() => handleContactShow()}
                    style={{
                      backgroundColor: "#E77C40",
                      borderColor: "transparent",
                      borderRadius: "8px",
                      marginTop: "6%",
                      fontSize: "1.2rem",
                      width : '13rem'
                    }}
                  >
                    Get Started
                  </Button>
                </div>
              )}
              <div className="col-md-6">
                <Button
                  variant="success"
                  onClick={() => handleShow()}
                  size="lg"
                  style={{
                    backgroundColor: "white",
                    borderColor: "#646464",
                    borderRadius: "8px",
                    marginTop: "6%",
                    color: "#646464",
                    fontSize: "1.2rem",
                    width : '13rem'
                  }}
                >
                  Download Our App
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
