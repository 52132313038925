import React, { Component } from "react"
import { PartnershipMain } from "../components/partComponents/partnerships/part1"
import { PartnershipStatistics } from "../components/Partnership_Statistics/statistics"
import { AppDownload } from "../components/App_Download/app_download"
import { CorporateWellnessTestimonial } from "../components/CW_Testimonials"
import { Newsletter } from "../components/Newsletter"
import ClientLogoSlider from "../components/Clients/ClientLogoSlider"

class Partnerships extends Component {
  render() {
    return (
      <div>
        <PartnershipMain />
        <PartnershipStatistics />
        {/* <Testimonial /> */}
        <CorporateWellnessTestimonial />
        <ClientLogoSlider />
        <div style={{ marginTop: "4%" }} />
        <AppDownload
          heading="Ready to revolutionise. Set foot towards a revolutionary journey!"
          subheading="We offer so many things, that makes it difficult to accomodate within single screen. click to view all."
          image="/img/download-app4.png"
        />
        <Newsletter />
      </div>
    )
  }
}

export default Partnerships
