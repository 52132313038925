import "../App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import HeroMain from "../components/Hero/HeroMain"
import FeaturesTop from "../components/Features_Top/FeaturesTop"
import { Services } from "../components/Services/Services"
import { ClientSection } from "../components/Clients/ClientSection"
import ClientLogoSlider from "../components/Clients/ClientLogoSlider"
import { Newsletter } from "../components/Newsletter/index"
import { HomeInstitute } from "../components/Home_Institute"
import { AppDownload } from "../components/App_Download/app_download"

function HomePage() {
  return (
    <div className="App">
      <div className="navbar-hero">
        <div className="hero-section">
          <HeroMain />
        </div>
      </div>

      <FeaturesTop />
      <Services />
      <HomeInstitute />
      <ClientLogoSlider />

      <ClientSection />
      {/* <FeaturesBottom /> */}
      {/* <DownloadApp /> */}
      <div style={{ marginTop: "8%" }} />
      <AppDownload
        image="/img/download-app6.png"
        showGetStarted={false}
        imageWidth={100}
      />
      <Newsletter />

      {/* <ContactForm /> */}
    </div>
  )
}

export default HomePage
