import React, { Component } from "react";
import "./logoSlider.css";

class ClientLogoSlider extends Component {
  render() {
    return (
      <div className="logoSlider">
        <div className="slider">
          <div className="ourClients">
            Our
            <span className="gradient-text" style={{ marginLeft: "8px" }}>
              Clients
            </span>
          </div>
          <div className="slide-track-1">
            <div className="slide">
              <img src="/img/capital.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/collar.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/environics.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/fipi.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/grasim.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/honda.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/iit.png" alt="" />
            </div>

            <div className="slide">
              <img src="/img/lifeline.png" alt="" />
            </div>

            {/* somethinig  */}

            <div className="slide">
              <img src="/img/capital.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/collar.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/environics.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/fipi.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/grasim.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/honda.png" alt="" />
            </div>
            <div className="slide">
              <img src="/img/iit.png" alt="" />
            </div>

            <div className="slide">
              <img src="/img/lifeline.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientLogoSlider;
