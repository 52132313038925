"use client";
import React from "react";
import "./hero.css";
import Button from "react-bootstrap/Button";
import { HashLink } from "react-router-hash-link";
import ReactGA from 'react-ga4';


export function PartnershipHeroSection(props) {


  const onExploreClick= event =>{

    ReactGA.event({
      category: 'partnership_hero_explore_button',
      action: 'Click',
      label: window.location
      
  });
  


}


  return (
    <>
      <div className="partner-hero-section">
        <div className="container p-5 align-middle">
          <h2 className="heading2">Welcome to</h2>
          <h1 className="heading1">Partnership</h1>

          <p className="subheading">Unite with WellM for a Healthier, Happier World!</p>
          <div>
            <Button
              variant="success"
              size="lg"
              style={{ backgroundColor: "#E77C40", borderColor: "transparent", borderRadius: "8px" }}
              onClick={onExploreClick}
            >
              <HashLink smooth to="/partnerships#explore-p" style={{ color: "white", textDecoration: "none" }}>
                Explore
              </HashLink>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
