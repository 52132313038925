"use client"
import React, { useState } from "react"
import "./testimonial.css"
import ReactGA from 'react-ga4';


export function Testimonial({ testimonials, author }) {
  const [index, setIndex] = useState(0)
  return (
    <>
      <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-6 testimonial-left">
              <h2 className="testimonial-heading">
                Here's what others have to <br /> say about <br />{" "}
                <span>WellM's services</span>
                <br />
                <p>Now it's your turn to benefit from the 'WellM experience'...</p>
              </h2>
            </div>

            <div className="col-md-6 testimonial-right">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className="arrow"
                  src="/img/personal-wellness/left-arrow.svg"
                  alt="user"
                  onClick={() => {
                    var i = index - 1
                    setIndex(i < 0 ? 3 : i)
                    ReactGA.event({
                      category: 'testimonials_swipe_right',
                      action: 'Click',
                      label: window.location
                      
                  });
                  }}
                />
                <div className="testimonial-image">
                  <img src="/img/personal-wellness/user1.png" alt="user" />
                </div>
                <div className="testimonial-text">
                  <p>
                    `{testimonials[index]}`<br />
                    <br />- {author[index]}
                  </p>
                </div>
                <img
                  className="arrow"
                  onClick={() => {
                    var i = index + 1
                    setIndex(i > 3 ? 0 : i)
                    ReactGA.event({
                      category: 'testimonials_swipe_left',
                      action: 'Click',
                      label: window.location
                      
                  });
                  }}
                  src="/img/personal-wellness/right-arrow.svg"
                  alt="user"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
