import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonials.css";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        swipeable={true}
      >
        <div>
          <div className="caraousel-1">
            <div className="avatar">
              <img
                src="https://m.economictimes.com/thumb/msid-69443430,width-1200,height-900,resizemode-4,imgsize-187933/cp-gurnani-1200.jpg"
                className="rounded-circle"
                style={{ width: "150px" }}
                alt="Avatar"
              />
            </div>

            <div className="owner-1">
              <h3>Mr. C P Gurnani</h3>
              <h4>CEO & MD Tech Mahindra</h4>
            </div>
          </div>
          <div className="myCarousel">
          <div className="review">
              <p>
              I found Vital Care is done through a fairly scientific and sophisticated equipments, and psychometric assesment was fantastic. I met coaches from different sectors and have been made aware how my wellness journey should be and how WellM will support me throughout.
              </p>

              <div className="stars">

              <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  </div>
  
            </div>
          </div>
        </div>

        <div>
          <div className="caraousel-1">
            <div className="avatar">
              <img
                src="https://pbs.twimg.com/profile_images/548092622858772480/pCyZT6Ya_400x400.jpeg"
                className="rounded-circle"
                style={{ width: "150px" }}
                alt="Avatar"
              />
            </div>

            <div className="owner-1">
              <h3>RK Malhotra</h3>
              <h4>Director General FIPI</h4>
            </div>
          </div>
          <div className="myCarousel">
            <div className="review">
              <p>
              I personally went through the WellM assesment and found it quite informative and reports provided me inputs about my current state of health and what I need to take care of my wellness. Thanks to WellM.
              </p>

              <div className="stars">

              <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  </div>
  
            </div>
          </div>
          
          
        </div>

        <div>
          <div className="caraousel-1">
            <div className="avatar">
              <img
                src="https://www.constructionworld.in/assets/uploads/s_bca0b1200120942875301c5c7acdb68c.jpg"
                className="rounded-circle"
                style={{ width: "150px" }}
                alt="Avatar"
              />
            </div>

            <div className="owner-1">
              <h3>Mr. Vijay Sharma</h3>
              <h4>Director - Jindal Stainless</h4>
            </div>
          </div>
          <div className="myCarousel">
          <div className="review">
              <p>
              After using WellM, I’m feeling more alert and experiencing better sleeping pattern. Everything seems good and positive looking and there are improvement areas in my overall health.
              </p>

              <div className="stars">

              <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  <i className="star"></i>
  </div>
  
            </div>
          </div>
        </div>
      </Carousel>
    );
  }
}
