"use client";
import React from "react";

export function RefundPolicy(props) {
  return (
    <>
      <section id="legal" className="head">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 text-center">
              <h2>WellM Refund & Cancellation Policy</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p>
                Client satisfaction is WellM’s main priority. For clients desiring refund of the amount paid by them for
                WellM services the following conditions will apply:
              </p>
              <ul className="legalPoints">
                <li>The refund request should be made within 15 days of the payment.</li>
                <li>
                  100% refund will be given if WellM fails to provide services within stipulated time. However, in case
                  there is a delay in service timeline and the same has been mutually discussed & agreed with the client
                  then refund will be applicable post new agreed timeline.
                </li>
                <li>
                  If the client wants refund of the services already availed or started the process of availing the
                  services, WellM will not be liable to refund the amount.
                </li>
                <li>
                  If the client has paid for the services & wants to get the refund before availing the services for
                  whatsoever reasons, WellM will not be liable to refund the amount.
                </li>
                <li>
                  The eligible refund amount on cancellation of services by the client would bear a 15% cancellation
                  charge which will also include payment gateway charges.
                </li>
                <li>
                  Any amount charged erroneously or any technical reasons through the payment gateway over and above the
                  service charges shall be refunded.
                </li>
                <li>
                  The Customer will be required to mail WellM on contact@wellm.co for refund along with <br />-
                  Transaction number - <br />- Payment receipt - <br />- Reasons for asking refund -<br /> - Customer
                  bank details – Account Number, Bank name & IFSC Code.
                </li>
                <li>
                  The amount will be refunded by WellM thru electronic mode in favor of the applicant and confirmation
                  will be sent to their mailing address. The process of refund may take up-to 10 working days.
                </li>
                <li>
                  Company may elect to resolve any dispute, controversy or claim arising out of or relating to this
                  Agreement or Service provided in connection with this Agreement by binding arbitration in accordance
                  with the provisions of the Indian Arbitration & Conciliation Act, 1996. Any such dispute, controversy
                  or claim shall be arbitrated on an individual basis in National Capital Region, India and shall not be
                  consolidated in any arbitration with any claim or controversy of any other party.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default RefundPolicy;
