"use client"
import React from "react"
import "./statistics.css"

export function PartnershipStatistics(props) {
  return (
    <>
      <div className="container statistics">
        <h2>WellM's value with our partners</h2>
        <div className="row">
          <div className="col-md-4" style={{ display: "inline-grid" }}>
            <img
              src="/img/personal-wellness/calendar.svg"
              alt="calendar"
              style={{ width: "12%", margin: "2% auto" }}
            />
            <div className="therapy-stats">3k+</div>
            <p className="therapy-stats-description">
              {" "}
              users onboarded through our partners
            </p>
          </div>

          <div className="col-md-4" style={{ display: "inline-grid" }}>
            <img
              src="/img/personal-wellness/hourglass.svg"
              alt="hourglass"
              style={{ width: "12%", margin: "2% auto" }}
            />
            <div className="therapy-stats">5K+</div>
            <p className="therapy-stats-description">
              mental health assessments given
            </p>
          </div>
          <div className="col-md-4" style={{ display: "inline-grid" }}>
            <img
              src="/img/workshop.png"
              alt="rating"
              style={{ width: "15%", margin: "2% auto" }}
            />
            <div className="therapy-stats">100+</div>
            <p className="therapy-stats-description">
              workshops conducted for our partners
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
