"use client";
import React from "react";
import "./style.css";

export function Termsandcondition(props) {
  return (
    <>
      <section id="legal" className="Termsec">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 text-center">
              <h1>TERMS AND CONDITIONS</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <h2 className="hdPrivacy2 text-center">Legal Agreement</h2>

              <p>
                This user agreement (“Agreement”) is an agreement between you and WellM Private Limited
                ("WellM" or "we" or "us" as the context requires) governing your use of WellM’s products, software
                and/or services with the characteristics and features as described on 
                <a href="https://www.wellm.co/">https://www.wellm.co/</a> (“Website”) and your use of the marketplace
                made available on the Website and App (“Marketplace”) (referred to collectively as the "Services"
                including assessment, reporting, advisory, coaching and products). WellM may have subsidiaries and
                affiliated legal entities around the world ("Subsidiaries and Affiliates"), providing the Services to
                you on behalf of WellM. You acknowledge and agree that the Subsidiaries and Affiliates will be entitled
                to provide the Services to you under the terms of this Agreement.
              </p>
              <p>
                By accessing the Website, the App or the Marketplace, at your option, registering thereon and thereafter
                using the Services as a member or guest, you agree to be bound by this Agreement and the terms contained
                in it. This Agreement governs your access and use of this Website/ App/Marketplace and applies to all
                visitors, users and others who access the Service ("Users"). If you do not agree with the terms
                contained in this Agreement, you are not permitted to use this Website/ App/Marketplace. WellM will not
                be liable for any consequences arising from your unauthorized use. We may revise these terms of use at
                any time by amending this page and the terms hereof. The revised terms of use shall be posted on the
                Website/App and you are expected to check this page from time to time to take notice of any changes we
                make, as they are binding on you. Some of these provisions may be superseded by provisions or notices
                published elsewhere on our Website/App/Marketplace. All changes are effective as soon as we post them
                and by continuing to use the Website/App/Marketplace and avail of the Services, you agree to be bound by
                the revised terms and conditions of use. Your use of Website/App/Marketplace is subject to the most
                current version of the terms of use posted on the Website/App/Marketplace at the time of such use.
              </p>
              <p>
                WellM has created this Website/App/Marketplace to provide Users, the Services which includes, guidance
                on how to improve their wellness and how to integrate fitness into their individual lifestyles and to
                provide the Users access to healthy and nutritious life. While our recommendations consider several
                factors specific to each an individual, including WellM Care Assessments, Mood & Activity Tracker,
                Access to a proactive Wellness Community, Access to basic Wellbeing Content, Access to (paid) Wellbeing
                Coaches on WellM App. We are not a medical organization, and our advisory should not be misconstrued as
                medical advice, prescriptions, or diagnoses.
              </p>
              <p>
                The Marketplace is an e-commerce marketplace made available on the Website and App, wherein Users are
                provided access to a variety of health and nutrition-based services (“Services”). These Services are
                listed for use by WellM on the Marketplace/Website. The Users may place orders and purchase the Services
                on the Marketplace using the payment gateway provided therein. WellM shall not be liable for any
                third-party products sold through the website. The use of the Marketplace and the purchase shall be
                governed by the terms hereof.
              </p>

              <h3 className="hdPrivacy">1. ACCESS</h3>
              <ul className="legalPoints">
                <li>
                  Access to the Services is permitted on a temporary basis, and we reserve the right to withdraw or
                  amend the Service (or any features within the Services) that we provide on our Website/
                  App/Marketplace by notifying the same on our Website/App/Marketplace from time to time without prior
                  notice to you. We will not be liable, if for any reason our Website/App/Marketplace or the Services
                  (or any features within the Services) are unavailable at any time or for any period. From time to
                  time, we may, at our sole discretion, restrict access to some parts of our Website/App/Marketplace, or
                  our entire Website/App/Marketplace, to Users who have registered with us.
                </li>
                <li>
                  WellM grants you permission to use the Services as set forth in this Agreement, provided that: (i) you
                  will not copy or distribute, any part of the Services in any medium or in any manner whatsoever
                  without WellM’s explicit authorisation in this regard; (ii) you will not alter or modify any part of
                  the Services other than as may be reasonably necessary to use the Services for its intended purpose;
                  and (iii) you will otherwise comply with the terms and conditions of this Agreement.
                </li>
                <li>
                  In order to access the Services, you will need to register on the Website/ App and create an account.
                  Your account gives you access to the Services and functionality that we may establish and maintain
                  from time to time and at our sole discretion on the Website.
                </li>
                <li>
                  By using the Services and completing the registration process, you warrant that: (a) all the data
                  provided by you is accurate and complete; (b) you shall maintain the accuracy of such information, and
                  any changes thereto by regular updation of any such information; (c) you affirm that you are over 18
                  (eighteen) years of age and are fully able and competent to enter into the terms, conditions,
                  obligations, affirmations, representations, and warranties set forth in this Agreement, and to abide
                  by and comply with this Agreement; (d) you are eligible in accordance with applicable laws to enter
                  into a binding contract and are not a person barred from receiving the Services under applicable laws
                  ; and (e) you may use the Website/ App or such other Services provided through the Website/ App only
                  in compliance with this Agreement and all applicable local, state, national, and international laws,
                  rules and regulations. We shall not be liable for any injury, damage or other consequence, health
                  related or otherwise arising out of any inaccuracy in the information provided by you on the
                  Website/App. Your profile may be deleted by us by informing/not informing you 24 (twenty-four) hours
                  in advance without warning, or without any notice whatsoever, if we believe that you have violated any
                  of the conditions as mentioned under this Agreement or the Privacy Policy.
                </li>
                <li>
                  You must provide your full legal name, valid email address and any other information requested from
                  you, from time to time, in order to complete the registration process and be entitled to use the
                  Services. The information provided by you may be shared by us with any third party for providing the
                  Services, record keeping purposes, internal procedures or for any other purposes and by using this
                  Website/ App you expressly consent to such sharing of the information provided by you.
                </li>
                <li>
                  Upon completing the registration process, you will be provided with a user identification code,
                  password or any other piece of information, as part of our security procedures. You must treat such
                  information as confidential, and you must not disclose it to any third party. You also agree to ensure
                  that you exit/log out from your account at the end of each session. We have the right to disable any
                  user identification code or password, whether chosen by you or allocated by us, at any time, if in our
                  opinion you have failed to comply with any of the provisions of this Agreement. You will immediately
                  notify WellM of any unauthorized use of your password or user identification code, by sending details
                  of such unauthorized use to: <a href="mailto:contact@wellm.co">contact@wellm.co</a>.
                </li>
                <li>
                  You are responsible for making all arrangements necessary for you to have access to our
                  Website/App/Mar and thereby the Services. You are also responsible for ensuring that any persons who
                  access our Website/App/Marketplace and thereby the Services through your account are aware of these
                  terms, and that they comply with them. You are also solely responsible and liable to WellM for all
                  activities that take place or occur under your account. You agree that your ability to log into your
                  account is dependent upon external factors such as internet service providers and internet network
                  connectivity and we shall not be liable to you for any damages arising from your inability to log into
                  your account.
                </li>
                <li>
                  WellM reserves the right to refuse access to use the Services offered at the Website/ App/Marketplace
                  to new Users or to terminate access granted to existing Users at any time without any reasons for
                  doing so. Use of the account is not available to any User who is suspended or prohibited by WellM from
                  using the Website/App/Marketplace or Services for any reason whatsoever.
                </li>
              </ul>

              <h3 className="hdPrivacy">2. MEMBERSHIP/REGISTRATION FOR THE SERVICES</h3>
              <ul className="legalPoints">
                <li>
                  Once you complete registration on the Website/App, you will be a member which:
                  <ul>
                    <li>allows you to access and view content on the Website/ App/Marketplace;</li>
                    <li>
                      allows us to contact you, if necessary, in order to inform you of changes to the terms of use of
                      the Website/App or describing new services that we enable on the Website/App;
                    </li>
                    <li>
                      allows you to access and purchase Products/Services on the Marketplace, subject to the terms and
                      conditions set out herein;
                    </li>
                    <li>
                      allows you to make use of the Services on the Website/ App subject to these terms and conditions;
                    </li>
                    <li>
                      does not allow you to use email addresses displayed on the Website/ App for any purpose other than
                      that specifically authorized by us.
                    </li>
                  </ul>
                </li>
                <li>
                  By providing WellM your email address/ phone number you consent to:
                  <ul>
                    <li>
                      We use your email address or mobile number to send you Service-related notices, including any
                      notices required by law, in lieu of communication by postal mail. You may use your settings to opt
                      out of Service-related communications via email/mobile number or entirely.
                    </li>
                    <li>
                      We use the phone numbers provided by you, to contact you from time to time, in order to provide
                      you updates and advice relating to your progress on the App and the usage of our Services, and
                      relating to any new promotions and sales opportunities on the App.
                    </li>
                    <li>
                      Our using on your email address, home address, phone number and other ancillary information to
                      fulfil the orders placed by you on the Marketplace; and
                    </li>
                    <li>
                      Our using your email address or phone number to send you other messages, including changes to
                      features of the Service and special offers. If you do not want to receive such email messages, you
                      may opt out by sending us an email at: contact@wellm.co . Opting out may prevent you from
                      receiving email messages or phone messages regarding updates, improvements, or offers.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3 className="hdPrivacy">3. TERMS FOR COUNSELLING</h3>
              <ul className="legalPoints">
                <li>
                  It is hereby stated that everything discussed between the Counsellor and the Client, during the
                  Sessions, are strictly confidential in nature.
                </li>
                <li>
                  Your Name, Case details and Contact Number are secured with us, but it can be used in case of
                  emergency/SOS due to any reason or any mis-happening, then the coach or WellM may contact you or share
                  your details with the relevant people for prevention.
                </li>
                <li>
                  In case of rescheduling or cancellation of appointment, the Client will have to write an email to us
                  at-least 24 hours before on this id contact@wellm.co
                </li>
                <li>
                  Rescheduling of the appointment shall be done at the convenience of the Client and the Counsellor.
                </li>
                <li>
                  It is stated that the Client has the obligation to share information that is true to the best of their
                  knowledge. 
                </li>
                <li>
                  This is a general Advisory as per your assessment, results may vary from person to person, as per your
                  body’s metabolism.
                </li>
                <li>
                  The Client is under the obligation to inform the Counsellor about the contact details of self. It is
                  to be noted, that the WellM is not a crisis intervention helpline. In case of any crisis please seek
                  immediate medical help or call suicide prevention helplines in India.
                </li>
                <li>
                  If you are not satisfied with one coach and wants your case to be transferred to another coach, then
                  we will take your consent to pass-on your details to other WellM Expert with whom you can take further
                  consultations.
                </li>
                <li>
                  On 1-1 Coaching, the Advice given by our expert coaches is solely depends on their discretion, and we
                  don't hold any liability for it.
                </li>
              </ul>

              <h3 className="hdPrivacy">4. TERMS FOR COMMUNITY</h3>
              <ul className="legalPoints">
                <li>
                  You may find content that you don't like but doesn't violate the Community Guidelines. If that
                  happens, you can block the person who posted it. If there's something that you don't like in a comment
                  on one of your posts, you can delete that comment.
                </li>
                <li>
                  We have taken Holistic Wellness content for our site from which some of the content that we used has
                  been taken online. However, some of our content has been also produced by our known associates to
                  further the cause of Holistic well-being amongst the apps users hence we don’t hold any responsibility
                  for that content. Also, WellM is not responsible for any other third-party content.
                </li>
                <li>
                  We want to foster a positive, diverse community. We remove content that contains credible
                  threats or hate speech, content that targets private individuals to degrade or shame them, personal
                  information meant to blackmail or harass someone, and repeated unwanted messages.
                </li>
                <li>
                  Each of us is an important part of the WellM community. We have a global team that reviews these
                  reports and works as quickly as possible to remove content that doesn't meet our guidelines.
                </li>
                <li>
                  You don't have to use your real name on WellM, but we do require WellM users to provide us with
                  accurate and up-to-date information. Don't impersonate others and don't create accounts for the
                  purpose of violating our guidelines or misleading others.
                </li>
                <li>
                  It's never OK to encourage violence or attack anyone based on their race, ethnicity, national origin,
                  sex, gender, gender identity, sexual orientation, religious affiliation, disabilities, or diseases.
                </li>
              </ul>

              <h3 className="hdPrivacy">5. GRANT OF LICENSE</h3>
              <ul className="legalPoints">
                <li>
                  Subject to these terms and conditions of use, WellM grants you a non-transferable, non-exclusive,
                  non-sublicensable limited right and license for you to access and use the Services solely for the
                  purpose permitted, including but not limited to storing or otherwise using or sharing your documents,
                  files, schedules, minutes or other data.
                </li>
              </ul>

              <h3 className="hdPrivacy">6. PRIVACY AND YOUR PERSONAL INFORMATION</h3>
              <ul className="legalPoints">
                <li>
                  For information about WellM's data protection practices, please read privacy policy of WellM. This
                  policy explains how WellM treats your personal information, and protects your privacy, when you use
                  the Services.
                </li>
              </ul>

              <h3 className="hdPrivacy">7. TERMS OF USE OF THE MARKETPLACE</h3>
              <ul className="legalPoints">
                <li>
                  You may access and use the Marketplace to purchase Products/Services listed thereon by us from time to
                  time. Wellness Mandala shall not be liable for any third party products sold through the website.
                </li>
                <li>
                  WellM’s responsibility in terms of the Marketplace shall extend solely and exclusively to:
                  <ul>
                    <li>The provision of the platform for the Marketplace on the Website/App;</li>
                    <li>
                      Collection of payments from Users using the third party payment gateway on the Marketplace. In the
                      event of any disputed payments made, Wellness Mandala shall pass on the dispute to the payment
                      gateway provider for resolution. This shall be the extent of Wellness Mandala’s obligations in
                      this regard;
                    </li>
                    <li>
                      Delivery of the Products/Services to the Users who have placed orders therefor on the Marketplace.
                      Wellness Mandala will not be responsible for delay in delivery or non-delivery of third party
                      products.
                    </li>
                    <li>
                      WellM shall also not be responsible for the quality of the product delivered by third party
                      vendors.
                    </li>
                  </ul>
                </li>
                <li>
                  It is hereby clarified that the Products/Services listed on the Marketplace are listed by us or may be
                  by third party vendors. The Products/Services by third party vendors do not belong to Wellness Mandala
                  in any manner whatsoever.
                </li>
                <li>
                  There shall be no refunds or cancellations initiated or entertained, once an order is placed and
                  processed on the Marketplace, save as set out herein. Any payment failure due to issues on payment
                  gateways are not the responsibility of Wellness Mandala.
                </li>
              </ul>

              <h3 className="hdPrivacy">8. UPLOADING MATERIAL TO THE SERVICE</h3>
              <ul className="legalPoints">
                <li>
                  Whenever you make use of the Services (or any feature or part of the Services) that allows you to
                  upload material on the Website/ App/Marketplace, or to make contact with other users of our Website/
                  App or vendors on the Marketplace, you must comply with applicable data protection rules/laws and the
                  content standards set out below. The content standards apply to each part of a contribution as well as
                  to its whole. Your contribution must be accurate where it states facts, be genuinely held (where it
                  states opinions), comply with the law applicable in any country from which it is posted; and be
                  relevant to your posting or use of the Services.
                </li>
              </ul>

              <h3 className="hdPrivacy">9. RESTRICTIONS ON CONTENT</h3>
              <ul className="legalPoints">
                <li>
                  Your contribution must not:
                  <ul>
                    <li>belong to another person and contain any information that you do not have any right to;</li>
                    <li>Be defamatory of any person;</li>
                    <li>
                      Be obscene, offensive, pornographic, hateful, harmful, harassing, defamatory, vulgar, libelous,
                      lewd, blasphemous, lascivious, invasive of another’s privacy, racially, ethnically or otherwise
                      objectionable or inflammatory, disparaging, relating or encouraging money laundering or gambling
                      or otherwise unlawful in any manner whatsoever;
                    </li>
                    <li>
                      Violate the provisions of the Indian Penal Code, 1861, the Information Technology Act, 2000,
                      Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data
                      or Information) Rules, 2011, notified vide Notification G.S.R 313(E) issued by the Ministry of
                      Communications and Information Technology or other applicable laws containing offences relating to
                      content that is publicly accessible;
                    </li>
                    <li>
                      Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation
                      or age;
                    </li>
                    <li>
                      Disclose the name, address, telephone, mobile or fax number, e-mail address or any other personal
                      data in respect of any individual;
                    </li>
                    <li>Be harmful to minors;</li>
                    <li>
                      Infringe any intellectual property including copyright, database right or trademark of any other
                      person;
                    </li>
                    <li>
                      Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence;
                    </li>
                    <li>Be likely to harass, upset, embarrass, alarm or annoy any other person;</li>
                    <li>Impersonate any person, or misrepresent your identity or affiliation with any person;</li>
                    <li>
                      Advocate, promote, incite any third party to commit, or assist any unlawful or criminal act;
                    </li>
                    <li>
                      Contain a statement which you know or believe, or have reasonable grounds for believing, that
                      members of the public to whom the statement is, or is to be, published are likely to understand as
                      a direct or indirect encouragement or other inducement to the commission, preparation or
                      instigation of acts of terrorism;
                    </li>
                    <li>Be such that it is known by you to be false, inaccurate or misleading;</li>
                    <li>
                      Deceives or misleads the addressee about the origin of such messages or communicates any
                      information which is grossly offensive or menacing in nature;
                    </li>
                    <li>Be content for which you were compensated or granted any consideration by any third party;</li>
                    <li>
                      Contain any computer viruses, any code, worms or other potentially damaging or limiting the
                      functionality of any computer programs or files.
                    </li>
                    <li>
                      Threatens the unity, integrity, sovereignty of India, friendly relations with foreign states or
                      public order or causes incitement to the commission of any cognizable offence or is insulting to
                      another nation.
                    </li>
                  </ul>
                </li>
                <li>
                  We hereby reserve the right to take down any content posted by you, which, in our sole discretion
                  falls under the above restrictions.
                </li>
              </ul>

              <h3 className="hdPrivacy">10. RESTRICTION ON USE OF SERVICES</h3>
              <ul className="legalPoints">
                <li>
                  You are not permitted to and shall not do any of the following acts, the breach of which shall entitle
                  Wellness Mandala to terminate your use of the Services, without prejudice to any other rights or
                  remedies we may have against you:
                  <ul>
                    <li>
                      Distribute, sell, supply, modify, adapt, amend, incorporate, merge or otherwise alter the Services
                      and all content provided to you as part of the Services;
                    </li>
                    <li>
                      Attempt to decompile, reverse engineer or otherwise disassemble any Service or content provided to
                      you as part of the Services or the Website or the App or the Marketplace;
                    </li>
                    <li>
                      Attempt to copy any software provided to you as part of the Services or the Website or the App or
                      the Marketplace and in particular its source code, or attempt to decrypt any part of such software
                      that is provided to you;
                    </li>
                    <li>
                      Create any derivative work or version of any software provided by us in relation to or to
                      facilitate your use of the Services or any content provided as part of the Services;
                    </li>
                    <li>
                      Remove, from the Services or any content provided as part of the Services, any logo, copyright or
                      proprietary notices, legends, symbols, labels,
                    </li>
                    <li>watermarks, signatures or any other like marks affixed to or embedded in the Services;</li>
                    <li>
                      Use the Services or any part of it to create a false identity, to impersonate any person or
                      organization, or attempt to disguise the origin of any content;
                    </li>
                    <li>Use any of Wellness Mandala’s domain name as a pseudonymous return email address;</li>
                    <li>
                      Access or use the Website/ App/Marketplace in any manner that could damage, disable, overburden,
                      or impair any of the Website/ App/Marketplace’s servers or the networks connected to any of the
                      servers on which the Website/ App/Marketplace is hosted;
                    </li>
                    <li>Access or attempt to access any content that you are not authorized to access by any means;</li>
                    <li>
                      Access the Website/ App/Marketplace through any other means other than through the interface that
                      is provided by us;
                    </li>
                    <li>Alter or modify any part of the Services; and</li>
                    <li>
                      Disrupt or interfere with the security of, or otherwise cause harm to the Website/ App, materials,
                      system resources, or gain unauthorized access to the user accounts, passwords, servers or networks
                      connected to or accessible through the Website/App/Marketplace or any affiliated linked sites.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3 className="hdPrivacy">11. RIGHTS RESERVED BY WELLM</h3>
              <ul className="legalPoints">
                <li>
                  We have the right to disclose your identity to any third party who validly claims and provides
                  evidence that any material posted or uploaded by you to our Website/App/Marketplace constitutes a
                  violation of their intellectual property rights, or of their right to privacy.
                </li>
                <li>
                  We are not responsible, or liable to any third party, for the content or accuracy of any materials
                  posted by you or any other User of the Services.
                </li>
                <li>
                  We have the right to immediately terminate your access or usage rights and remove non-compliant
                  information or material, in case of non-compliance with these terms and conditions, this Agreement or
                  the Privacy Policy.
                </li>
                <li>
                  We have the right to investigate and prosecute violations of these terms and conditions to the fullest
                  extent of the law and may involve and cooperate with law enforcement authorities in prosecuting Users
                  who violate these terms and conditions.
                </li>
                <li>
                  While we have no obligation to monitor your access to or your use of the Services (or any feature or
                  part of the Services), we have the right to do so for the purpose of operating the Website/
                  App/Marketplace and providing the Services, to ensure your compliance with these terms and conditions,
                  or to comply with applicable law or the order or requirement of a court, administrative agency or
                  other governmental body.
                </li>
                <li>
                  Subject to the receipt of a complaint from a User, we have the right (but shall have no obligation) to
                  pre-screen, review, flag, filter, modify, refuse or remove any or all content from any Service. For
                  some of the Services, WellM may (but shall be under no obligation to) provide tools to filter out
                  explicit sexual content or any content which it deems to be unsuitable at its sole discretion.
                </li>
                <li>
                  We reserve the right to access, read, preserve, and disclose any information as we reasonably believe
                  is necessary to: (a) satisfy any applicable law, regulation, legal process or governmental request;
                  (b) enforce these terms and conditions, including investigation of potential violations hereof; (c)
                  detect, prevent, or otherwise address fraud, security or technical issues; (d) respond to user support
                  requests; or (e) protect the rights, property or safety of WellM, its Users and the public. WellM
                  shall not be responsible or liable for the exercise or non-exercise of its rights under these terms
                  and conditions in this regard.
                </li>
                <li>
                  We reserve the right to introduce or change the prices of all Services with or without notice. Such
                  notice may be provided at any time by posting the changes on the Website/App itself.
                </li>
                <li>
                  WellM shall not be liable to you or to any third party for any modification, price change, suspension
                  or discontinuance of the Services.
                </li>
              </ul>

              <h3 className="hdPrivacy">12. BREACH OF CONTENT STANDARDS</h3>
              <ul>
                <li>
                  When we consider that a breach of the content standards (as stated above) has occurred, we may at our
                  discretion take such action as we deem appropriate. Failure to comply constitutes a material breach of
                  the terms of use on which you are permitted to use the Service, and we may take any action legally
                  available including all or any of the following actions:
                  <ul className="legalPoints">
                    <li>
                      Immediate, temporary or permanent withdrawal of your right to use the Services and simultaneous
                      termination/suspension of your account on the Website/App;
                    </li>
                    <li>
                      Immediate, temporary or permanent removal of any contribution already posted on the
                      Website/App/Marketplace using the Services, upon your failure to cure the breach that is brought
                      to your notice;
                    </li>
                    <li>Issue of a warning to you;</li>
                    <li>
                      Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but
                      not limited to, reasonable administrative and legal costs) resulting from the breach;
                    </li>
                    <li>Further legal action against you; and</li>
                    <li>
                      Disclosure of such information to law enforcement authorities as we reasonably feel is necessary
                      or as required by law.
                    </li>
                  </ul>
                </li>
                <li>
                  We hereby disclaim and exclude our liability for all action we may take in response to breaches of
                  these rules. The actions described above are not limited, and we may take any other action we
                  reasonably deem appropriate.
                </li>
                <li>
                  Upon such termination/suspension of your account, you will no longer be able to access your account or
                  any content or data you have stored on the servers. All licenses granted to you by WellM to the
                  Website/App/Marketplace or the Services will automatically terminate.
                </li>
              </ul>

              <h3 className="hdPrivacy">13. CONTENT</h3>
              <ul className="legalPoints">
                <li>
                  You understand that all information (such as data files, written text, computer software, music, audio
                  files or other sounds, photographs, videos or other images) which you may have access to as part of,
                  or through your use of, the Services are the sole responsibility of the person from which such content
                  originated. You agree that you are solely and exclusively responsible for any and all content that you
                  create, transmit or display while using the Services and you are solely and exclusively liable for the
                  consequence of your actions (including any loss or damage which WellM may suffer). WellM exercises no
                  control over the content posted on the Website/ App using the Services. Your use of the content
                  accessed or displayed using the Services is entirely at your own risk.
                </li>
                <li>
                  A search using the Services may produce results and links to sites and content that you may find
                  objectionable, inappropriate, or offensive and we accept no liability of responsibility for any such
                  content. It is also possible that your use of the Services will return to you information regarding
                  products, merchants and links to websites of third parties selling the product information requested
                  by you. WellM hereby disclaims any and all responsibility and liability associated with the same.
                </li>
                <li>
                  You acknowledge and agree that WellM is not liable for any loss or damage which may be incurred by you
                  as a result of the availability of such external sites or resources, or as a result of any reliance
                  placed by you on the completeness, accuracy or existence of any advertising, products or other
                  materials on, or available from, such websites or resources.
                </li>
                <li>
                  You shall be responsible for monitoring your content, documents and other data and shall be liable to
                  us for ensuring that the content transferred to or handled by or within Services does not infringe any
                  third-party rights. You warrant that you possess such necessary licenses as may be required in order
                  to process your content or use the Services.
                </li>
                <li>
                  You understand that the technical processing and transmission of the Services, including your Content,
                  may be transferred unencrypted and involve: (a) transmissions over various networks; and (b) changes
                  to conform and adapt to technical requirements of connecting networks or devices.
                </li>
              </ul>

              <h3 className="hdPrivacy">14. BILLING AND PAYMENT</h3>
              <ul className="legalPoints">
                <li>
                  Certain aspects of the Services may be provided for a fee or other charge. If you elect to use paid
                  aspects of the Services, you agree to the terms of sale, pricing, payment and billing policies
                  applicable to such fees and charges. WellM may add new services for additional fees and charges, or
                  amend fees and charges for existing services, at any time in its sole discretion.
                </li>
                <li>
                  It is your responsibility to promptly provide WellM with any contact or billing information changes or
                  updates (including phone number, email address, credit card numbers, etc.). WellM does not validate
                  all credit card information required by the Customer's payment provider to secure payment. We also do
                  not store any payment information.
                </li>
                <li>
                  You must notify WellM about any billing problems or discrepancies within 10 (ten) days after charges
                  first appear on their account statement. If it is not brought to WellM’s attention within 10 (ten)
                  days, You agree to waive your right to dispute such problems or discrepancies.
                </li>
                <li>
                  In respect of purchases made on the Marketplace, you hereby acknowledge and agree that no refund
                  requests will be entertained in any manner whatsoever and no refunds will be initiated there for.
                </li>
              </ul>

              <h3 className="hdPrivacy">15. INDEMNITY AND LIMITATION OF LIABILITY</h3>
              <ul className="legalPoints">
                <li>
                  You agree to defend, indemnify and hold harmless WellM, its officers, directors, employees and agents,
                  from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
                  expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to
                  the Service and Website/ App/Marketplace; (ii) your violation of any term of this Agreement; (iii)
                  your violation of any third party right, including without limitation, any copyright, property, or
                  privacy right; or (iv) any claim that your User Content caused damage to a third party. This defense
                  and indemnification obligation will survive this Agreement and your use of the Service and
                  Website/App/Marketplace.
                </li>
                <li>
                  The material displayed on our Website or App/Marketplace is provided without any guarantees,
                  conditions or warranties as to its accuracy. To the extent permitted by law, we, our Subsidiaries and
                  Affiliates and third parties connected to us hereby expressly exclude:
                  <ul>
                    <li>
                      Conditions, warranties and other terms, which might otherwise be implied by statute, common law or
                      the law of equity;
                    </li>
                    <li>
                      Any liability for any direct, indirect or consequential loss or damage incurred by any user in
                      connection with the Services, our Website/ App/Marketplace or in connection with the use,
                      inability to use, or results of the use of the Services or our Website/ App/Marketplace, any
                      websites linked to it and any materials posted on it, including, without limitation any liability
                      for:
                      <ul>
                        <li>loss of income or revenue;</li>
                        <li>loss of business;</li>
                        <li>loss of profits or contracts;</li>
                        <li>loss of anticipated savings;</li>
                        <li>loss of data;</li>
                        <li>loss of goodwill;</li>
                        <li>wasted management or office time; and</li>
                        <li>
                          for any other loss or damage of any kind, however arising and whether caused by tort
                          (including negligence), breach of contract or otherwise, even if foreseeable or advised of the
                          possibility of the same.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Our liability to you shall under all circumstances be limited to a maximum of the amount paid by you
                  (if any) at the time of registration on our Website/ App to use the Services and/or the purchase price
                  paid by you for Products/Services in the Marketplace.
                </li>
              </ul>

              <h3 className="hdPrivacy">16. OFFENCES</h3>
              <ul className="legalPoints">
                <li>
                  You must not misuse our Website/App/Marketplace by knowingly introducing viruses, trojans, worms,
                  logic bombs, time bombs, cancel bots, spyware or other material, computer programming routines, codes,
                  files or such other programs which are malicious or technologically harmful, or limit the interests of
                  rights of other users or limit the functionality of any computer software, hardware or
                  telecommunications. You must not attempt to gain unauthorized access to our Website/ App/Marketplace,
                  the server on which our Website/ App/Marketplace is stored or any server, computer or database
                  connected to our site. You must not attack our Website/ App/Marketplace via a denial-of-service attack
                  or a distributed denial-of service attack.
                </li>
                <li>
                  By breaching this provision, you would commit a criminal offence under the Information Technology Act,
                  2000 (and any amendments). We will report any such breach to the relevant law enforcement authorities
                  and we will cooperate with those authorities by disclosing your identity to them. In the event of such
                  a breach, your right to use our Website/ App/Marketplace will cease immediately.
                </li>
                <li>
                  We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses
                  or other technologically harmful material that may infect your computer equipment, computer programs,
                  data or other proprietary material due to your use of our Website/ App/Marketplace or to your
                  downloading of any material posted on it, or on any Website/App/Marketplace linked to it.
                </li>
              </ul>

              <h3 className="hdPrivacy">17. LINKING TO THE WEBSITE</h3>
              <ul className="legalPoints">
                <li>
                  You may link to our home page, provided you do so in a way that is fair and legal and does not damage
                  our reputation or take advantage of it, but you must not establish a link in such a way as to suggest
                  any form of association, approval or endorsement on our part where none exists. You must not establish
                  a link from any website that is not owned by you.
                </li>
                <li>
                  Our Website/ App/Marketplace must not be framed on any other site, nor may you create a link to any
                  part of our site other than the home page. We reserve the right to withdraw linking permission without
                  notice. The Website/App/Marketplace from which you are linking must comply in all respects with the
                  content standards set out in these terms and conditions.
                </li>
                <li>
                  These linked sites are under no way in control of Wellness Mandala and we shall not be held
                  responsible for the contents of any linked sites, including without limitation to any link contained
                  in the linked site or any changes or updates to linked site. We shall not be held responsible for any
                  transmission whatsoever received by you from any linked site. The Website/ App provides links to you
                  only as a convenience and the inclusion of any link does not imply endorsement by us or any
                  association with its operators or owners. You are requested to verify the accuracy of all information
                  on your own before relying on such information.
                </li>
                <li>
                  If you wish to make any use of material on our Website/ App other than that set out above, please
                  address your request to: <a href="mailto:contact@wellm.co">contact@wellm.co</a>.
                </li>
              </ul>

              <h3 className="hdPrivacy">18. ADDITIONAL TERMS FOR THE MOBILE APP</h3>
              <ul className="legalPoints">
                <li>
                  You are granted a limited, non-sub-licensable right to access the Mobile App, Desktop App, the
                  Services and Data for the purpose of enabling you or other users to access the Website or the App or
                  the Marketplace and/or the Services via third party software or website. Any use of the Mobile App,
                  Desktop App, is bound by the terms of this agreement plus the following specific terms:
                  <ul>
                    <li>
                      You agree that you are solely responsible for (and that we have no responsibility or liability to
                      you or to any third party for) any services and/or products you provide through any third party
                      software;
                    </li>
                    <li>
                      You expressly understand and agree that we shall not be liable for any direct, indirect,
                      incidental, special, consequential or exemplary damages, including but not limited to, damages for
                      loss of profits, goodwill, use, data or other intangible losses (even if we has been advised of
                      the possibility of such damages), resulting from your use of the Mobile App and Desktop App;
                    </li>
                    <li>
                      You will not use the Mobile App or the Desktop App to create software that sends unsolicited
                      communications (whether commercial or otherwise) to any third party;
                    </li>
                    <li>
                      We reserve the right at any time to modify or discontinue, temporarily or permanently the Desktop
                      App and Mobile App by providing a notice of 48 (forty eight) hours with or without notice;
                    </li>
                    <li>
                      Abuse or excessively frequent requests to the Services via the Desktop App and Mobile App may
                      result in the temporary or permanent suspension of your account’s access. We, in our sole
                      discretion, will determine abuse or excessive usage;
                    </li>
                    <li>
                      We reserve the right at any time to modify or discontinue, temporarily or permanently, your access
                      to the Mobile App and the Desktop App by providing a notice of 48 (forty-eight) hours;
                    </li>
                    <li>
                      We may make available software to access the Service via the App using a mobile device. WellM does
                      not warrant that the App will be compatible with your mobile device. WellM hereby grants you a
                      non-exclusive, non-transferable, revocable license to use a compiled code copy of the App for one
                      Member account on one mobile device owned or leased solely by you, for your personal use. You may
                      not: (i) modify, disassemble, decompile or reverse engineer the App, except to the extent that
                      such restriction is expressly prohibited by law; (ii) rent, lease, loan, resell, sublicense,
                      distribute or otherwise transfer the App to any third party or use the App to provide time sharing
                      or similar services for any third party; (iii) make any copies of the App; (iv) remove,
                      circumvent, disable, damage or otherwise interfere with security-related features of the App,
                      features that prevent or restrict use or copying of any content accessible through the App, or
                      features that enforce limitations on use of the App; or (v) delete the copyright and other
                      proprietary rights notices on the App. You acknowledge that WellM may from time-to-time issue
                      upgraded versions of the App, and may automatically electronically upgrade the version of the App
                      that you are using on your mobile device. You consent to such automatic upgrading on your mobile
                      device and agree that the terms and conditions of this Agreement will apply to all such upgrades.
                      Any third-party code that may be incorporated in the App is covered by the applicable open source
                      or third-party license EULA, if any, authorizing use of such code. The foregoing license grant is
                      not a sale of the App or any copy thereof, and WellM or its third-party partners or suppliers
                      retain all right, title, and interest in the App (and any copy thereof). Any attempt by you to
                      transfer any of the rights, duties or obligations hereunder, except as expressly provided for in
                      this Agreement, is void. WellM reserves all rights not expressly granted under this Agreement.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3 className="hdPrivacy">19. SECURITY</h3>
              <ul className="legalPoints">
                <li>
                  We have implemented commercially reasonable technical and organizational measures designed to secure
                  your personal information and User Content from accidental loss and from unauthorized access, use,
                  alteration or disclosure. However, we cannot guarantee that unauthorized third parties will never be
                  able to defeat those measures or use your personal information and User Content for improper purposes.
                  You acknowledge that you provide your personal information at your own risk.
                </li>
              </ul>

              <h3 className="hdPrivacy">20. OWNERSHIP OF INTELLECTUAL PROPERTY INCLUDING TRADEMARKS/SERVICE MARKS</h3>
              <ul className="legalPoints">
                <li>
                  All right, title and interest in usage of the terms www.wellnessmandala.com, WellM including but not
                  limited to all texts, graphics, user interfaces, visual interfaces, computer code and any other
                  information associated therewith are reserved by us.
                </li>
                <li>
                  All right, title, and interest in and to the Services (excluding your Content) are and will remain the
                  exclusive property of WellM Private Limited and its licensors. Any use of this Website/ App or its
                  contents, including copying or storing it or them in whole or part, other than for your own personal,
                  non-commercial use is prohibited without the permission of WellM Private Limited.
                </li>
                <li>
                  Except as expressly provided in these terms of use, no part of WellM and no content or marks, data,
                  statistics, independent research conducted and posted by WellM may be copied reproduced, republished,
                  uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed in any way
                  including (“mirroring”) to any other computer, server, website, or other medium for publication or
                  distribution of any for any commercial enterprise, without prior written consent.
                </li>
                <li>
                  By accepting the use of terms hereunder you agree that the WellM does not transfer/assign the title to
                  the Website/App to you, and nothing in these terms and conditions shall imply or be deemed or
                  construed to mean that any right, title and interest (including but not limited to intellectual
                  property rights) stands transferred/assigned to you by WellM, we retain the full and complete right,
                  title and interest to the Website/App, and all intellectual property, title and interest to the
                  Website/App, and all intellectual rights therein. You may not redistribute, sell, decompile, reverse
                  engineer, dissemble, or otherwise reduce the Website/App in any manner which is contrary to this
                  Agreement.
                </li>
                <li>
                  Any usage of WellM’s contents, without the written authorization of WellM, shall be considered a
                  breach of this Agreement, and you shall be required to indemnify WellM for all liability incurred in
                  this regard.
                </li>
              </ul>

              <h3 className="hdPrivacy">21. GOVERNING LAW</h3>
              <ul className="legalPoints">
                <li>
                  The jurisdictional court of Haryana shall have sole jurisdiction over any claim arising from, or
                  related to, a visit to / use of the Website/ App/Marketplace or the Services, although we retain the
                  right to bring proceedings against you for breach of any of these terms and conditions in your country
                  of residence, country of use or other relevant country. The laws of India govern this Agreement and
                  these terms and conditions of use of the Services.
                </li>
                <li>
                  WellM accepts no liability whatsoever, direct or indirect, for noncompliance with the laws of any
                  country other than that of India, the mere fact that Website/ App/Marketplace can be accessed or used
                  or any facility can be availed of in a country other than India will not imply that we accede to the
                  laws of such country.
                </li>
              </ul>

              <h3 className="hdPrivacy">22. RELATIONSHIP BETWEEN THE WEBSITE AND LEGAL NAME</h3>
              <ul className="legalPoints">
                <li>
                  The website <a href="www.wellm.co">www.wellm.co</a> and the associated mobile application, WellM, is
                  registered and owned by Wellness Mandala Private Limited, and usage of this Website/App and affiliated
                  links is subject to the Terms of Use and Privacy Policy.
                </li>
              </ul>

              <h2 className="hdPrivacy2 text-center mt-5">END USER LICENCE AGREEMENT</h2>
              <p>
                This End-user License Agreement (<strong>&quot;Eula&quot;</strong>) is a legal and binding agreement
                between you and Wellness Mandala Private Limited (<strong>&quot;Company&quot;</strong>). By clicking on
                the “I Agree” button below and by installing or otherwise using the Wellness Mandala Application (the
                “App”) on your cellular mobile phone, you agree to be bound by the terms and conditions of this
                agreement. if you do not agree to be bound by the agreement or the terms and conditions on the cellular
                mobile phone / start-up application, then do not click “I Agree” or install, copy and/or use the
                application.
              </p>
              <p>
                You must scroll down to the end of this eula before you agree to it and continue with the installation.
              </p>

              <h3 className="hdPrivacy">1. LICENSE</h3>
              <ul className="legalPoints">
                <li>
                  Subject to the terms and conditions of this EULA, the Company hereby grants you a limited,
                  non-commercial, non-sub-licensable, non-exclusive, non-transferable right to install and use the App
                  on one portable wireless device. You may not make the App available over a network where it could be
                  used on multiple devices at the same time.
                </li>
                <li>
                  The term “App” includes any patches, revisions, updates, upgrades, software, code, files, images and
                  other embedded software or replacements of the App (the
                  <strong>&quot;Revisions&quot;</strong>) that may be delivered to you or that you may receive or that
                  may be released by Company, unless other terms and conditions are provided with the Revisions. If such
                  terms and conditions are provided, you will have to agree to those terms and conditions before you are
                  entitled to receive the Revisions. You also agree that upon the installation of any Revisions, you are
                  only permitted to use the App as modified by the Revisions. You shall not either directly or
                  indirectly, or through any third party, modify, adapt, translate, redistribute, rent, lend, network,
                  lease, loan, issue, resell, for profit, or create derivative works based on the App and any portion
                  thereof.
                </li>
                <li>
                  You acknowledge that by installing the App on your cellular mobile phone, the App may aggregate,
                  collect, retain or transmit to secure servers personal information such as, but not limited to,
                  contact information, the serial number and IMEI number of your wireless device or cellular mobile
                  phone. You hereby consent to the App collecting and storing such information on Company&apos; servers
                  and to receive calls from us, from time to time regarding updates of your progress from using the App
                  and promotional information or offers in connection with the Services provided under the App.
                </li>
              </ul>

              <h3 className="hdPrivacy">2. RESTRICTIONS ON USE</h3>
              <ul className="legalPoints">
                <li>
                  The following restrictions shall apply to your use of the App and the license granted herein:
                  <ul>
                    <li>You hereby acknowledge and agree to use the App as permitted in this Agreement;</li>
                    <li>You shall not copy or reproduce any portion of the App;</li>
                    <li>You shall only use the App for your own personal, non-commercial use;</li>
                    <li>
                      You shall not distribute, share, transfer, sell, lease or rent the App or any part of it to any
                      other person;
                    </li>
                    <li>
                      You shall not change, alter, modify or create derivative works, enhancements, extensions or
                      add-ons to the App or any part of it;
                    </li>
                    <li>
                      You shall not remove, alter or obscure any proprietary notice (including any notice of copyright
                      or trademark) of the Company or its affiliates, partners, suppliers, or the licensors of the App;
                    </li>
                    <li>
                      You shall not use the App for any revenue generating endeavor, commercial enterprise, or other
                      purpose for which it is not designed or intended;
                    </li>
                    <li>
                      You shall not use any electronic communication feature of the App for any purpose that is
                      unlawful, tortious, abusive, intrusive on another’s privacy, harassing, libelous, defamatory,
                      embarrassing, obscene, threatening, or hateful.
                    </li>
                    <li>
                      You shall not use any proprietary information or interfaces of the Company or other intellectual
                      property of the Company in the design, development, manufacture, licensing or distribution of any
                      applications, accessories or devices for use with the App;
                    </li>
                    <li>You will not collect or store personal information about other users.</li>
                    <li>
                      You shall not decompile, reverse engineer or disassemble the App or any part of it either in whole
                      or any portion of any component, thereof and in anyway create, develop or derive, including
                      without limitation to any operational, technical, programming technique/sequence, feature,
                      interface, algorithm, video, audio, imagining, graphics, applets, texts, animations, routine or
                      conditions from the App; and
                    </li>
                    <li>
                      Violate any applicable laws, rules or regulations in connection with your access or use of the
                      App;
                    </li>
                    <li>
                      You will at all times comply with, and will not circumvent or attempt to circumvent, any of the
                      restrictions on use set forth anywhere in this EULA.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3 className="hdPrivacy">3. DATA CHARGES</h3>
              <ul className="legalPoints">
                <li>
                  You acknowledge that applicable network/data charges may be incurred through your use of the App and
                  you agree that you are responsible for any and all such charges that may be charged to you and that
                  Company shall have no liability to you whatsoever in respect of such charges. You also agree that the
                  charges incurred through your use of the App are dependent on your agreement with your service
                  provider and that the Company has absolutely no liability to you in respect of such charges. You agree
                  that use of the App whilst outside your home circle might incur additional roaming charges and agree
                  to be solely liable for such charges. Company has no liability to you in respect of such charges.
                </li>
              </ul>

              <h3 className="hdPrivacy">4. INTELLECTUAL PROPERTY RIGHTS</h3>
              <ul className="legalPoints">
                <li>
                  The App may feature trademarks, logos, service marks, product names and designations, by making these
                  trademarks available through the App and in content, the Company is not selling you the App to use it
                  in any manner, and you are not granted any rights under any of Company’s intellectual property rights.
                </li>
                <li>This EULA does not grant you any rights to trademarks, copyrights or patents of the Licensor.</li>
                <li>
                  Any unauthorized copying, displaying, selling or distributing or other use of any content or App is a
                  violation of the law.
                </li>
              </ul>

              <h3 className="hdPrivacy">5. LIMITATION OF LIABILITY</h3>
              <ul className="legalPoints">
                <li>
                  You understand and agree that under no circumstance will the company, its agents, licensors or
                  suppliers be liable to you on account for damages of any kind, whether based in tort, contract, strict
                  liability or otherwise your misuse of the software. the company shall not be liable for any loss or
                  damages including but not limited to damages of personal injury, either direct, indirect, incidental,
                  consequential or otherwise, arising out of the breach of any express or implied warranty, term or
                  condition, breach of contract, negligence, strict liability misrepresentation, failure of any remedy
                  to achieve its essential purpose or any other legal theory arising out of, or related to, this
                  agreement or your use of the app (such damages include, but are not limited to, loss of profits,
                  business interruption, savings, loss of privacy or any other pecuniary loss, loss of revenue, loss of
                  data, loss of use of the app or the cellular mobile device or any associated equipment or documents),
                  even if company has been advised of the possibility of such damages. In any case, the entire liability
                  of the company shall be limited to the amount paid by you to subscribe to the services provided on the
                  app.
                </li>
              </ul>

              <h3 className="hdPrivacy">6. DAMAGES</h3>
              <ul className="legalPoints">
                <li>
                  You shall defend and hold Company harmless from and against any and all liabilities, damages, costs,
                  expenses or losses arising out of your improper or unauthorized use of the App/Website, your negligent
                  or wrongful acts, your violation of any applicable laws or regulations, and/or your breach of any
                  provision of this EULA.
                </li>
              </ul>

              <h3 className="hdPrivacy">7. EVALUATION TERM & TERMINATION</h3>
              <ul className="legalPoints">
                <li>
                  Any license not purchased for the full license fee and any trial version, are provided for a limited
                  duration and for the limited purpose expressly communicated to you in connection with the App. At the
                  end of the evaluation/trial period, you will cease use of the App, remove the App from your cellular
                  mobile device and destroy all copies of the App. Failure to remove the App shall be a breach of this
                  EULA.
                </li>
                <li>
                  You agree that the App may automatically disable itself at the end of the evaluation/trial period and
                  consent to such disabling feature. You also agree that the Company may send you reminders to purchase
                  a permanent license key, whether during or after the evaluation/trial version.
                </li>
                <li>
                  You agree that any evaluation/trial version is not full-featured and the use of certain features of
                  the App may be disabled or limited in any manner deemed appropriate. You may activate all features of
                  the App by purchasing a license key as communicated to you.
                </li>
                <li>
                  Without prejudice to any other rights, Company may terminate this EULA if you fail to comply with or
                  breach the terms and conditions of this EULA or for any other reason upon delivery of notice, unless
                  such breach, where capable of cure, is cured by you immediately upon receipt of a notice of such
                  breach from the Company. In such an event, you must cease use of the App and destroy all copies of the
                  App and all of its component parts and associated documents. You agree that the Company may remotely
                  disable the App in the event of termination under this section.
                </li>
              </ul>

              <h3 className="hdPrivacy">8. ENTIRE AGREEMENT</h3>
              <ul className="legalPoints">
                <li>
                  This EULA constitutes the entire agreement between you and Company relating to the App and supersedes
                  all prior or contemporaneous oral or written communications including but not limited to any terms and
                  conditions of purchase, proposals and representations with respect to the App or any other subject
                  matter covered by this EULA. The headings in this agreement are for convenience and reference only and
                  do not affect the interpretation of this EULA.
                </li>
                <li>
                  The provisions of the terms of service set out above and privacy policy set out below shall be read
                  together with this EULA and are deemed to be an integral part of this EULA. While this EULA governs
                  your use of the App, the terms of service and privacy policy govern your access and use of the
                  services offered on the WellM website and the WellM App.
                </li>
              </ul>

              <h3 className="hdPrivacy">9. SEVERABILITY</h3>
              <ul className="legalPoints">
                <li>
                  If any provision of this EULA is held to be void, invalid, unenforceable or illegal, by a court of
                  competent jurisdiction then other provisions shall continue in full force and effect. Such invalid or
                  unenforceable provision(s) shall be replaced by (a) valid and enforceable one(s) which achieve(s) to
                  the extent possible the original purpose of the invalid provision(s).
                </li>
              </ul>

              <h3 className="hdPrivacy">10. GOVERNING LAW</h3>
              <ul className="legalPoints">
                <li>
                  The laws of the republic of India govern this EULA. All disputes are subject to the jurisdiction of
                  the courts in Haryana, and you submit to such jurisdiction.
                </li>
              </ul>

              <h3 className="hdPrivacy">12. GRIEVANCE OFFICER</h3>
              <ul className="legalPoints">
                <li>
                  In accordance with Information Technology Act 2000 and rules made there under, the name and contact
                  details of the Grievance Officer are provided below: In the event of any complaints or concerns with
                  respect to the website or our services, please contact our Grievance Redressal Officer Mrs. Shivi
                  Sabharwal at:
                  <a href="mailto:contact@wellm.co">contact@wellm.co</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Termsandcondition;
