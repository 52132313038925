"use client"
import * as React from "react"
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit"
import { PartnershipHeroSection } from "../../Partnership_Hero_Section copy/hero"

export function PartnershipMain(props) {
  return (
    <>
      <PartnershipHeroSection />
      <div className="div">
        <div className="div-2" style={{ minHeight: 0 }}>
          <div
            className="div-14"
            style={{
              fontSize: "1.8rem",
              fontWeight: "300",
            }}
          >
            Unite with WellM for a Healthier, Happier World!
          </div>
        </div>
        <div className="div-15" style={{ width: "80%" }} id="explore-p">
          <div className="div-16">
            <div className="column">
              <div className="div-17">
                <div className="div-18">
                  <div className="div-19">About our Partnership</div>
                  <div className="div-20">
                    Synergy thrives through collaboration. If your business champions
                    scientific and natural wellness approaches, we want you. Let's
                    create a global wellness ecosystem together!
                  </div>
                </div>
                <div className="div-21">
                  <div className="div-22">Ideal partners include:</div>{" "}
                  <div className="div-23">
                    <ul>
                      <li>
                        Wellness Coaches in Psychology, Naturopathy, Ayurveda,
                        Fitness, Nutrition, Yoga, and more
                      </li>
                      <li>Wellness Spas, Hospitality &amp; Institutes</li>
                      <li>Providers of natural wellness products</li>
                      <li>
                        Consultants &amp; Organizations aligned with WellM's vision
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-2">
              <div className="div-24">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd01bc5-de46-4a78-9975-b90dc3c66a03?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-3"
                  alt=""
                />
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a980a0c-b296-4f3f-bc9f-13574db81ae6?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-4"
                  alt=""
                />
              </div>
            </div>
            <div className="column-3">
              <div className="div-25">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e73dfc43-cd44-4fc3-98b3-768dd6ceab84?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-5"
                  alt=""
                />
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f2be951-0c2a-4c98-a5de-154d458b22a8?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                  className="img-6"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="div-26">
          <div className="div-27">Frequently Asked Questions</div>
          <div className="div-28">
            Here's why partnering with WellM is your next strategic move:
          </div>{" "}
          <MDBAccordion initialActive={0} className="accordian1">
            <MDBAccordionItem
              collapseId={1}
              headerTitle="01. How WellM contributes towards Holistic Approach ?"
              className="div-29 "
            >
              Align with our shared values of natural remedies and healthier living
              to build authenticity and trust, setting you apart in the market.
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion initialActive={1} className="accordian2">
            <MDBAccordionItem
              collapseId={1}
              headerTitle="02. How WellM contributes towards Shared Values ?"
              className="div-29-a  div-s"
            >
              Align with our shared values of natural remedies and healthier living
              to build authenticity and trust, setting you apart in the market.
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion initialActive={1} className="accordian3">
            <MDBAccordionItem
              collapseId={1}
              headerTitle="03. Enhanced Services"
              className="div-29-a  div-c"
            >
              Elevate your service range with our mental and emotional health
              offerings, providing customers with a more complete wellness journey.
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion initialActive={1} className="accordian4">
            <MDBAccordionItem
              collapseId={1}
              headerTitle="04. Data-Driven Insights:"
              className="div-29-a div-d"
            >
              Leverage our SaaS platform for personalized assessments and reports,
              offering unparalleled customization to your services.
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion initialActive={1} className="accordian5">
            <MDBAccordionItem
              collapseId={1}
              headerTitle="05. Community Connection :"
              className="div-29-a div-e"
            >
              Foster community engagement through our App, fostering strong customer
              connections that go beyond transactions.
            </MDBAccordionItem>
          </MDBAccordion>
        </div>
      </div>{" "}
      <style jsx>{`
        .accordian1 .accordion-button {
          background-color: #fafac8;
        }
        .div-29 {
          background-color: #fafac8;
        }

        .accordian2 .accordion-button {
          background-color: #fae9c8;
        }
        .div-s {
          background-color: #fae9c8;
        }

        .accordian3 .accordion-button {
          background-color: #fad4c8;
        }
        .div-c {
          background-color: #fad4c8;
        }
        .accordian4 .accordion-button {
          background-color: #c8e2fa;
        }
        .div-d {
          background-color: #c8e2fa;
        }
        .accordian5 .accordion-button {
          background-color: #fac8da;
        }
        .div-e {
          background-color: #fac8da;
        }

        .div {
          display: flex;
          flex-direction: column;
        }
        .div-2 {
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 0 0px;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .div-3 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
          }
        }
        .img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-4 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.41);
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 265px 20px 59px;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
          }
        }
        .div-5 {
          border-radius: 16px;
          backdrop-filter: blur(25px);
          background-color: rgba(255, 255, 255, 0.33);
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 996px;
          flex-direction: column;
          padding: 56px 20px 38px;
        }
        @media (max-width: 991px) {
          .div-5 {
            max-width: 100%;
            margin-top: 180px;
          }
        }
        .div-6 {
          color: #4f4f4f;
          text-align: center;
          font-family: Abhaya Libre Medium, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-size: 38px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-6 {
            text-wrap: initial;
          }
        }

        .div-7 {
          color: #545251;
          text-align: center;
          font-family: Abhaya Libre ExtraBold, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-size: 74.57px;
          font-weight: 800;
          align-self: center;

          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-7 {
            font-size: 40px;
            text-wrap: initial;
            margin-top: 0px;
          }
        }
        .div-8 {
          justify-content: center;
          color: #473e3e;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 40px;
          font-weight: 400;
          align-self: center;
          max-width: 960px;
          margin: 12px 0 0 16px;
        }
        @media (max-width: 991px) {
          .div-8 {
            max-width: 100%;
            margin-left: 0px;
          }
        }
        .div-9 {
          justify-content: center;
          align-items: flex-start;
          align-self: center;
          display: flex;
          margin-top: 24px;
          width: 303px;
          max-width: 100%;
          gap: 20px;
        }
        .div-10 {
          justify-content: center;
          align-items: center;
          border-radius: 5.966px;
          background-color: #e77c40;
          align-self: center;
          display: flex;
          width: 130px;
          max-width: 100%;
          flex-direction: column;
          margin: auto 0;
          padding: 10px 20px;
        }
        .div-11 {
          color: #fff;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-11 {
            text-wrap: initial;
          }
        }
        .div-12 {
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          gap: 19px;
        }
        @media (max-width: 991px) {
          .div-12 {
            display: none;
          }
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 49px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-13 {
          color: #797979;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-13 {
            text-wrap: initial;
          }
        }
        .div-14 {
          justify-content: center;
          color: #646464;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 40px;
          font-weight: 400;
          line-height: 132%;
          letter-spacing: 0.2px;
          align-self: center;
          margin-top: 87px;
          max-width: 1028px;
        }
        @media (max-width: 991px) {
          .div-14 {
            max-width: 100%;
            margin-top: 40px;
            font-size: 30px;
          }
        }
        .div-15 {
          align-self: center;
          margin-top: 6%;
          width: 100%;
          max-width: 1265px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-15 {
            max-width: 100%;
            margin-top: 40px;
            width: 90% !important;
          }
        }
        .div-16 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-16 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 62%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-17 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-17 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-18 {
          align-self: start;
          display: flex;
          width: 679px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-19 {
          color: #e77c40;
          font-family: Outfit, sans-serif;
          font-size: 35px;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.175px;
          align-self: start;
          margin-top: 7px;
          width: 656px;
          max-width: 100%;
        }
        .div-20 {
          color: #383838;
          font-family: Outfit, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 145.833%;
          letter-spacing: 0.12px;
          margin-top: 29px;
        }
        @media (max-width: 991px) {
          .div-20 {
            max-width: 100%;
          }
        }
        .div-21 {
          align-self: start;
          display: flex;
          margin-top: 110px;
          width: 758px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-21 {
            margin-top: 40px;
          }
        }
        .div-22 {
          color: #e77c40;
          font-family: Outfit, sans-serif;
          font-size: 35px;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.175px;
          align-self: start;
          margin-top: 7px;
          width: 656px;
          max-width: 100%;
        }
        .div-23 {
          color: #383838;
          font-family: Outfit, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 145.833%;
          letter-spacing: 0.12px;
          margin-top: 29px;
        }
        @media (max-width: 991px) {
          .div-23 {
            max-width: 100%;
          }
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 20%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-24 {
          display: flex;
          margin-top: 71px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-24 {
            margin-top: 40px;
          }
        }
        .img-3 {
          aspect-ratio: 0.99;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .img-3 {
            width: 90%;
          }
        }
        .img-4 {
          aspect-ratio: 0.67;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 21px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-4 {
            width: 90%;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 18%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-25 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-25 {
            margin-top: 25px;
          }
        }
        .img-5 {
          aspect-ratio: 0.64;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .img-5 {
            width: 90%;
          }
        }
        .img-6 {
          aspect-ratio: 0.83;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 21px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-6 {
            width: 90%;
          }
        }
        .div-26 {
          align-self: stretch;
          display: flex;
          margin-top: 133px;
          width: 100%;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-26 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-27 {
          color: #404040;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 36px;
          font-weight: 500;
          line-height: 122.222%;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-27 {
            max-width: 100%;
            text-wrap: initial;
          }
        }
        .div-28 {
          color: #344051;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 116.667%;
          letter-spacing: 0.12px;
          align-self: center;
          margin-top: 16px;
          max-width: 731px;
        }
        @media (max-width: 991px) {
          .div-28 {
            max-width: 100%;
          }
        }
        .div-29 {
          align-items: space-between;
          align-self: center;
          display: flex;
          margin-top: 68px;
          margin-left: auto;
          margin-right: auto;
          width: 800px;
          max-width: 100%;

          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-29 {
            margin-top: 40px;
          }
        }

        .div-29-a {
          align-items: space-between;
          align-self: center;
          display: flex;
          margin-top: 12px;
          margin-left: auto;
          margin-right: auto;
          width: 800px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-29-a {
            margin-top: 40px;
          }
        }
        .div-30 {
          align-items: center;
          border-radius: 8px;
          border: 1px solid #ced2da;
          background-color: #fafac8;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          padding: 32px 20px;
        }
        @media (max-width: 991px) {
          .div-30 {
            max-width: 100%;
          }
        }
        .div-31 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 16px;
          margin: 0 12px;
        }
        @media (max-width: 991px) {
          .div-31 {
            max-width: 100%;
            margin-right: 10px;
            flex-wrap: wrap;
          }
        }
        .div-32 {
          color: #141c24;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 155.556%;
          align-self: stretch;
          max-width: 748px;
          flex-grow: 1;
          flex-basis: auto;
        }
        @media (max-width: 991px) {
          .div-32 {
            max-width: 100%;
          }
        }
        .img-7 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 24px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .div-33 {
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid #ced2da;
          background-color: #fae9c8;
          align-self: stretch;
          display: flex;
          margin-top: 16px;
          flex-direction: column;
          padding: 32px 20px;
        }
        @media (max-width: 991px) {
          .div-33 {
            max-width: 100%;
          }
        }
        .div-34 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          margin: 0 12px;
        }
        @media (max-width: 991px) {
          .div-34 {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-35 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .div-35 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-36 {
          color: #141c24;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 155.556%;
          align-self: stretch;
          max-width: 765px;
          flex-grow: 1;
          flex-basis: auto;
        }
        @media (max-width: 991px) {
          .div-36 {
            max-width: 100%;
          }
        }
        .img-8 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 24px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .div-37 {
          color: #344051;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-top: 16px;
        }
        @media (max-width: 991px) {
          .div-37 {
            max-width: 100%;
          }
        }
        .div-38 {
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid #ced2da;
          background-color: #fad4c8;
          align-self: stretch;
          display: flex;
          margin-top: 16px;
          flex-direction: column;
          padding: 32px 20px;
        }
        @media (max-width: 991px) {
          .div-38 {
            max-width: 100%;
          }
        }
        .div-39 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          margin: 0 12px;
        }
        @media (max-width: 991px) {
          .div-39 {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-40 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .div-40 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-41 {
          color: #141c24;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 155.556%;
          align-self: stretch;
          max-width: 765px;
          flex-grow: 1;
          flex-basis: auto;
        }
        @media (max-width: 991px) {
          .div-41 {
            max-width: 100%;
          }
        }
        .img-9 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 24px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .div-42 {
          color: #344051;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-top: 16px;
        }
        @media (max-width: 991px) {
          .div-42 {
            max-width: 100%;
          }
        }
        .div-43 {
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid #ced2da;
          background-color: #c8e2fa;
          align-self: stretch;
          display: flex;
          margin-top: 16px;
          flex-direction: column;
          padding: 32px 20px;
        }
        @media (max-width: 991px) {
          .div-43 {
            max-width: 100%;
          }
        }
        .div-44 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          margin: 0 12px;
        }
        @media (max-width: 991px) {
          .div-44 {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-45 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .div-45 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-46 {
          color: #141c24;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 155.556%;
          align-self: stretch;
          max-width: 765px;
          flex-grow: 1;
          flex-basis: auto;
        }
        @media (max-width: 991px) {
          .div-46 {
            max-width: 100%;
          }
        }
        .img-10 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 24px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .div-47 {
          color: #344051;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-top: 16px;
        }
        @media (max-width: 991px) {
          .div-47 {
            max-width: 100%;
          }
        }
        .div-48 {
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid #ced2da;
          background-color: #fac8da;
          align-self: stretch;
          display: flex;
          margin-top: 16px;
          flex-grow: 1;
          flex-direction: column;
          padding: 32px 20px;
        }
        @media (max-width: 991px) {
          .div-48 {
            max-width: 100%;
          }
        }
        .div-49 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          margin: 0 12px;
        }
        @media (max-width: 991px) {
          .div-49 {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-50 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .div-50 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-51 {
          color: #141c24;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 155.556%;
          align-self: stretch;
          max-width: 765px;
          flex-grow: 1;
          flex-basis: auto;
        }
        @media (max-width: 991px) {
          .div-51 {
            max-width: 100%;
          }
        }
        .img-11 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 24px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .div-52 {
          color: #344051;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-top: 16px;
        }
        @media (max-width: 991px) {
          .div-52 {
            max-width: 100%;
          }
        }
        .div-53 {
          align-self: start;
          width: 907px;
          max-width: 100%;
          margin: 30px 0 0 76px;
        }
        .div-54 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-54 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .img-12 {
          aspect-ratio: 0.87;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-12 {
            margin-top: 40px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 65%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .div-55 {
          align-items: center;
          border-radius: 20px;
          background-color: #fff7f0;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: auto;
          padding: 32px 20px 17px;
        }
        @media (max-width: 991px) {
          .div-55 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-56 {
          align-items: center;
          align-self: center;
          display: flex;
          width: 463px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-57 {
          color: #002966;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: 140%;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-57 {
            text-wrap: initial;
          }
        }
        .div-58 {
          color: #002966;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 142.857%;
          margin-top: 12px;
        }
        @media (max-width: 991px) {
          .div-58 {
            max-width: 100%;
          }
        }
        .div-59 {
          color: #fff;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          align-self: center;
          text-wrap: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #e77c40;
          margin-top: 32px;
          width: 98px;
          max-width: 100%;
          padding: 10px 19px 10px 18px;
        }
        @media (max-width: 991px) {
          .div-59 {
            text-wrap: initial;
          }
        }
        .div-60 {
          align-items: center;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 30px;
          width: 100%;
          flex-direction: column;
          padding: 38px 20px 48px;
        }
        @media (max-width: 991px) {
          .div-60 {
            max-width: 100%;
          }
        }
        .div-61 {
          align-items: center;
          background-color: #fff;
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1216px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-61 {
            max-width: 100%;
          }
        }
        .div-62 {
          justify-content: center;
          align-items: center;
          align-self: stretch;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          padding: 16px 20px 64px;
        }
        @media (max-width: 991px) {
          .div-62 {
            max-width: 100%;
          }
        }
        .div-63 {
          color: #141c24;
          font-family: Outfit, sans-serif;
          font-size: 36px;
          font-weight: 600;
          line-height: 122.222%;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-63 {
            text-wrap: initial;
          }
        }
        .div-64 {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-64 {
            max-width: 100%;
          }
        }
        .div-65 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1045px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-65 {
            max-width: 100%;
          }
        }
        .div-66 {
          justify-content: space-between;
          align-items: flex-start;
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1012px;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-66 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .img-13 {
          aspect-ratio: 5.13;
          object-fit: cover;
          object-position: center;
          width: 164px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-14 {
          aspect-ratio: 5.91;
          object-fit: cover;
          object-position: center;
          width: 189px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-15 {
          aspect-ratio: 5.41;
          object-fit: cover;
          object-position: center;
          width: 173px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-16 {
          aspect-ratio: 4.63;
          object-fit: cover;
          object-position: center;
          width: 148px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-67 {
          justify-content: space-between;
          align-items: flex-start;
          align-self: center;
          display: flex;
          margin-top: 48px;
          width: 100%;
          max-width: 1042px;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-67 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .img-17 {
          aspect-ratio: 4.94;
          object-fit: cover;
          object-position: center;
          width: 158px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-18 {
          aspect-ratio: 5.66;
          object-fit: cover;
          object-position: center;
          width: 181px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-19 {
          aspect-ratio: 5.47;
          object-fit: cover;
          object-position: center;
          width: 175px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .img-20 {
          aspect-ratio: 6.69;
          object-fit: cover;
          object-position: center;
          width: 214px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
      `}</style>{" "}
    </>
  )
}
