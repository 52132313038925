"use client"
import React from "react"
import "./therapyStatistics.css"

export function TherapyStatistics({ heading = "We are making a difference" }) {
  return (
    <>
      <div className="container statistics">
        <h2>{heading}</h2>
        <div className="row">
          <div className="col-md-3" style={{ display: "inline-grid" }}>
            <img
              src="/img/impacted.png"
              alt="calendar"
              style={{ width: "20%", margin: "2% auto" }}
            />
            <div className="therapy-stats">20k+</div>
            <p className="therapy-stats-description">lifes impacted</p>
          </div>
          <div className="col-md-3" style={{ display: "inline-grid" }}>
            <img
              src="/img/workshop.png"
              alt="workshop"
              style={{ width: "20%", margin: "2% auto" }}
            />
            <div className="therapy-stats">100+</div>
            <p className="therapy-stats-description">workshops conducted</p>
          </div>
          <div className="col-md-3" style={{ display: "inline-grid" }}>
            <img
              src="/img/personal-wellness/hourglass.svg"
              alt="hourglass"
              style={{ width: "20%", margin: "2% auto" }}
            />
            <div className="therapy-stats">24*7</div>
            <p className="therapy-stats-description">live support available</p>
          </div>
          <div className="col-md-3" style={{ display: "inline-grid" }}>
            <img
              src="/img/personal-wellness/star.svg"
              alt="rating"
              style={{ width: "20%", margin: "2% auto" }}
            />
            <div className="therapy-stats">4.6/5</div>
            <p className="therapy-stats-description">average rating therapists</p>
          </div>
        </div>
      </div>
    </>
  )
}
