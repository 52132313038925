"use client"
import React from "react"
import "./styles.css"

export function CorporateWellnessHow(props) {
  return (
    <>
      <div className="container how-section">
        <h2 className="how-section-heading">
          How We Create <span className="gradient-text">Change</span>
        </h2>
        <div className="row how-section-access">
          <div className="col-md-6 how-section-image how-section-image-mobile">
            <img src="/img/cw-how1.png" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/img/diagnostics.svg" alt="" />
            <h4 className="how-section-subheading">
              <span className="gradient-text">Assess</span>
            </h4>
            <div className="divider" />
            <h3 className="point-heading-1">Joy & Self care Assessments</h3>
            <p className="point-description-1">
              Find out your organisation’s current state of wellness with WellM’s
              state-of-the-art Assesments. Employess can access there emotional and
              lifestyle wellbeing and find there current happiness score.
            </p>
            <h3 className="point-heading-1">Mood Checkins</h3>
            <p className="point-description-1">
              Gain valuable insights into employee engagement and satisfaction
              through mood-based check-ins.Identify patterns and trends in employee
              morale using mood check-ins as a barometer.
            </p>
          </div>
          <div className="col-md-6 how-section-image how-section-image-desktop">
            <img src="/img/cw-how1.png" alt="" />
          </div>
        </div>
        <div className="row how-section-discover">
          <div className="col-md-6 how-section-image">
            <img src="/img/cw-how2.png" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/img/action-plan.svg" alt="" />
            <h4 className="how-section-subheading">
              Action <span className="gradient-text">Plan</span>
            </h4>
            <div className="divider" />
            <h3 className="point-heading-1">Personalized Plan</h3>
            <p className="point-description-1">
              Your employees will get a fully personalized plan based on there
              assessment scores which will facilitates them to improve there
              emotional and lifestyle wellbeing.
            </p>
            <h3 className="point-heading-1">Counselling</h3>
            <p className="point-description-1">
              Your employees can access confidential, convenient and comfortable
              therapy services online or in-person with experienced mental health
              experts.
            </p>
          </div>
        </div>
        <div className="row how-section-engagement">
          <div className="col-md-6 how-section-image  how-section-image-mobile">
            <img src="/img/cw-how3.png" alt="" />
          </div>
          <div className="col-md-6">
            <img src="/img/engagement.svg" alt="" />
            <h4 className="how-section-subheading">
              Ongoing <span className="gradient-text">Engagement</span>
            </h4>
            <div className="divider" />
            <h3 className="point-heading-1">Webinars & Workshops</h3>
            <p className="point-description-1">
              Join our webinars where your team can engage with our mental health
              experts. Gain valuable insights into understanding the needs of your
              colleagues and discover ways to offer support.
            </p>
            <h3 className="point-heading-1">WellM Community</h3>
            <p className="point-description-1">
              Your employees can express there feelings anonymously with there peers
              without the fear of being judged. Expert coaches engage with the
              community and provide valuable advice on concerns shared in the
              community.
            </p>
            <h3 className="point-heading-1">Newsletters</h3>
            <p className="point-description-1">
              Our newsletters, which delve into a range of mental health subjects,
              providing your employees with valuable insights and practical
              strategies to enhance their emotional well-being.
            </p>
          </div>
          <div className="col-md-6 how-section-image  how-section-image-desktop">
            <img src="/img/cw-how3.png" alt="" />
          </div>
        </div>
        <div className="row how-section-help">
          <div className="col-md-6 how-section-image">
            <img src="/img/cw-how4.png" alt="" />
          </div>{" "}
          <div className="col-md-6">
            <img src="/img/help.svg" alt="" />
            <h4 className="how-section-subheading">
              Help & <span className="gradient-text">Support</span>
            </h4>
            <div className="divider" />
            <h3 className="point-heading-1">24*7 Support</h3>
            <p className="point-description-1">
              WellM is available 24*7 at your employees services to talk about their
              mental health concerns by connecting with a professional in less than
              10 minutes.
            </p>
            <h3 className="point-heading-1">Self-Help Library</h3>
            <p className="point-description-1">
              Your employees get access to the premium self-care videos, audios and
              blogs designed to boost mood, tackle specific concerns, and much more.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
