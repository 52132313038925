"use client";
import * as React from "react";
import CardCaraousel from "./cardCaraousel";

export function StudentWellnessHeroBelow(props) {
  return (
    <>
      <div className="div" id="explore-sw">
        <div className="div-13">
          The younger generation represents the forthcoming prospects of our nation, and it falls upon us to lead them
          toward achievement. Regrettably, merely 20% of educational institutions incorporate mental health awareness
          programs.
          <br />
          <br />
          Challenges like exams, peer pressure, and bullying might appear trivial, yet a significant portion of students
          deal with these issues. Unfortunately, due to insufficient awareness, many are unable to voice their concerns.
          <br />
          <br />
          Let’s prevent such circumstances. Let’s guide students in nurturing their inner selves and encouraging them to
          openly discuss their troubles. Let’s relieve them of the weight of concealed burdens.
        </div>
        <div className="div-14">
          Together, let’s pave a promising <span className="gradient-text">future for students!</span>
        </div>

        <CardCaraousel />
      </div>
      <style jsx>{`
        .div {
          display: flex;
          flex-direction: column;
        }
        .div-2 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 761px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .img {
          position: absolute;
          height: 130%;
          width: 130%;
          object-fit: cover;
          object-position: center;
        }
        .div-3 {
          position: relative;
          border-radius: 16px;
          backdrop-filter: blur(9.5px);
          background-color: rgba(255, 255, 255, 0.33);
          align-self: start;
          display: flex;
          width: 676px;
          max-width: 100%;
          flex-direction: column;
          margin: 0 0 -51px 40px;
          margin-top: 290px;
          padding: 23px 20px 29px 10px;
        }
        @media (max-width: 991px) {
          .div-3 {
            margin-bottom: 10px;
            margin-top: 520px;
          }
        }
        .div-4 {
          align-self: start;
          display: flex;
          width: 571px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-3 {
            margin-bottom: 10px;
            width: 80vw;
          }
        }
        .div-5 {
          color: #4f4f4f;
          font-family: Abhaya Libre Medium, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 45px;
          font-weight: 500;
          align-self: start;
          margin-left: 23px;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-5 {
            font-size: 40px;
            text-wrap: initial;
            align-self: center;
          }
        }
        .div-6 {
          color: #274c5b;
          font-family: Abhaya Libre ExtraBold, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 50px;
          font-weight: 800;
          align-self: start;
          margin-top: 8px;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-6 {
            max-width: 100%;
            font-size: 40px;
            text-wrap: initial;
            align-self: center;
            margin-left: 18vw;
          }
        }
        .div-7 {
          color: #3a2f2f;
          font-family: Outfit, sans-serif;
          font-size: 18px;
          font-weight: 500;
          align-self: start;
          width: 358px;
          max-width: 100%;
          margin: 23px 0 0 24px;
        }
        @media (max-width: 991px) {
          .div-7 {
            margin-left: 6vw;
            margin-right: 6vw;
          }
        }
        .div-8 {
          justify-content: center;
          align-items: flex-start;
          align-self: start;
          display: flex;
          margin-top: 37px;
          width: 303px;
          max-width: 100%;
          gap: 20px;
        }
        .div-9 {
          justify-content: center;
          align-items: center;
          border-radius: 5.966px;
          background-color: #e77c40;
          align-self: center;
          display: flex;
          width: 130px;
          max-width: 100%;
          flex-direction: column;
          margin: auto 0;
          padding: 10px 20px;
        }
        .div-10 {
          color: #fff;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-10 {
            text-wrap: initial;
          }
        }
        .div-11 {
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          gap: 19px;
        }
        @media (max-width: 991px) {
          .div-11 {
            display: none;
          }
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 49px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .div-12 {
          color: #797979;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 500;
          align-self: center;
          text-wrap: nowrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-12 {
            text-wrap: initial;
          }
        }
        .div-13 {
          font-family: Outfit, sans-serif;
          line-height: 133.333%;
          letter-spacing: 0.5px;
          align-self: center;
          margin-top: 95px;
          max-width: 942px;

          width: 80%;
          font-size: 1.1rem;
          text-align: center;
          font-weight: 300;
          color: #646464;
          margin: 4% auto;
        }
        @media (max-width: 991px) {
          .div-13 {
            max-width: 100%;
            margin-top: 40px;
            margin-left: 5vw;
            margin-right: 5vw;
          }
        }

        .div-14 {
          color: #646464;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 35px;
          font-weight: 500;
          align-self: center;
          max-width: 951px;
          margin: 30px 0 0 0;
        }
        @media (max-width: 991px) {
          .div-14 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
      `}</style>
    </>
  );
}
