"use client";
import * as React from "react";

import Testimonials from "./ClientReviews";

export function ClientSection(props) {
  return (
    <>
      <div className="div-a">
        <div className="div-a2">
          <div className="div-a3">
            <div className="column-a">
              <div className="div-a4">
                <Testimonials />

                {/* <div className="div-a5">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca043fc-4d6f-434d-a419-f697c32d1173?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-a"
                  />
                  <div className="div-a6">
                    <div className="div-a7">Ram Charan</div>
                    <div className="div-a8">CEO & MD Tech Mahindra</div>
                  </div>
                </div>
                <div className="div-a9">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ecf04da-f3d5-4977-a2f9-d1ee1f24abb9?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-a2"
                  />
                  <div className="div-a10">
                    <div className="div-a11"></div>
                    <div className="div-a12"></div>
                    <div className="div-a13"></div>
                    <div className="div-a14"></div>
                    <div className="div-a15"></div>
                  </div>
                </div>
                <div className="div-a16">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd9d1e77-0f2c-4242-80e0-eb2135bd7a61?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-a3"
                  />
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/688d8b61-942b-4c87-b97d-75c9bd7dac1b?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-a4"
                  />
                </div> */}
              </div>
            </div>
            <div className="column-a2">
              <div className="div-a17">
                <div className="div-a18">Trusted by more than 2k Client</div>
                <div className="div-a19">
                  At WellM, our commitment to fostering positive change and improving lives is at the heart of
                  everything we do. We take pride in the meaningful impact we've had on individuals and communities.
                </div>{" "}
                {/* <ClientLogoSlider /> */}
                {/* <div className="div-a20">
                  <div className="div-a21">
                    <div className="div-a22">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4f5ca20-079e-4591-9fc7-d1a5bb063b22?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a5"
                      />{" "}
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa81bc01-2c9c-42b3-b6dc-c789f3ff3d93?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a6"
                      />{" "}
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a5a1a4a3-e86c-444f-a8e5-8a37af36948c?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a7"
                      />
                    </div>{" "}
                    <div className="div-a23">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ecfab189-8ce6-419f-8a6b-1215638aec86?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a8"
                      />{" "}
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1590a5d6-5cee-4c01-a754-19280dcf93f2?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a9"
                      />{" "}
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9459dba-b20f-4c5f-aa0c-bb97aa8252f3?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                        className="img-a10"
                      />
                    </div>
                  </div>{" "}
                  <div className="div-a24">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80f75da8-ff60-4bf0-9476-2f675a56a578?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                      className="img-a11"
                    />{" "}
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d30edc90-66a2-4e88-951f-cffda48eb987?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                      className="img-a12"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <style jsx>{`
        .div-a {
          background-color: #fcc4c8;
          display: flex;
          padding-right: 20px;
          padding-left: 20px;
          flex-direction: column;
          margin-top: 6vw;
        }
        .div-a2 {
          align-self: center;
          margin-top: 131px;

          width: 100%;
          max-width: 1192px;
        }
        @media (max-width: 991px) {
          .div-a2 {
            max-width: 100%;
            margin-bottom: 90px;
            margin-top: 31px;
          }
          .div-a {
            padding: 0;
          }
        }
        .div-a3 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-a3 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-a {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 58%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-a {
            width: 100%;
          }
        }
        .div-a4 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-a4 {
            max-width: 100%;
            margin-top: 50px;
          }
        }
        .div-a5 {
          align-self: start;
          z-index: 1;
          display: flex;
          margin-left: 26px;
          width: 348px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-a5 {
            margin-left: 10px;
          }
        }
        .img-a {
          aspect-ratio: 0.97;
          object-fit: cover;
          object-position: center;
          width: 111px;
          overflow: hidden;
        }
        .div-a6 {
          align-self: center;
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          flex-direction: column;
        }
        .div-a7 {
          color: #000;
          font-family: Outfit, sans-serif;
          font-size: 24px;
          font-weight: 400;
          line-height: 171%;
          align-self: start;
          width: 152px;
        }
        .div-a8 {
          color: #666363;
          font-family: Outfit, sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 171%;
          align-self: start;
          margin-top: 3px;
          width: 233px;
        }
        .div-a9 {
          disply: flex;
          flex-direction: column;
          fill: #fff;
          filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 340px;
          width: 90%;
          padding-top: 40px;
          padding-right: 20px;
          padding-bottom: 40px;
          padding-left: 20px;
        }
        @media (max-width: 991px) {
          .div-a9 {
            max-width: 100%;
          }
        }
        .img-a2 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-a10 {
          position: relative;
          align-self: end;
          display: flex;
          margin-top: 236px;
          margin-right: 22px;
          margin-bottom: 3px;
          width: 159px;
          max-width: 100%;
          align-items: flex-start;
          gap: 11px;
        }
        @media (max-width: 991px) {
          .div-a10 {
            margin-top: 200px;
            margin-right: 10px;
            justify-content: center;
          }
        }
        .div-a11 {
          color: #efd372;
          text-align: center;
          font-family: Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 900;
          align-self: stretch;
        }
        .div-a12 {
          color: #efd372;
          text-align: center;
          font-family: Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 900;
          align-self: stretch;
        }
        .div-a13 {
          color: #efd372;
          text-align: center;
          font-family: Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 900;
          align-self: stretch;
        }
        .div-a14 {
          color: #efd372;
          text-align: center;
          font-family: Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 900;
          align-self: stretch;
        }
        .div-a15 {
          color: #efd372;
          text-align: center;
          font-family: Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 900;
          align-self: stretch;
          text-wrap: nowrap;
        }
        @media (max-width: 991px) {
          .div-a15 {
            text-wrap: initial;
          }
        }
        .div-a16 {
          align-self: center;
          display: flex;
          margin-top: 34px;
          width: 96px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .img-a3 {
          aspect-ratio: 1.56;
          object-fit: cover;
          object-position: center;
          width: 25px;
          stroke-width: 2px;
          stroke: #404040;
          overflow: hidden;
        }
        .img-a4 {
          aspect-ratio: 1.56;
          object-fit: cover;
          object-position: center;
          width: 25px;
          stroke-width: 2px;
          stroke: #404040;
          overflow: hidden;
          margin-top: 1px;
        }
        .column-a2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 42%;
        }
        @media (max-width: 991px) {
          .column-a2 {
            width: 100%;
          }
        }
        .div-a17 {
          display: flex;
          margin-top: 10px;
          flex-grow: 1;
          flex-direction: column;
          justify-content: start;
          gap: 50px;
        }
        @media (max-width: 991px) {
          .div-a17 {
            max-width: 100%;
            margin-top: 50px;
            max-width:90%;
            margin: 35px auto;

          }
        }
        .div-a18 {
          color: #646464;
          font-size: 2.2rem;
          font-weight: 600;
          align-self: start;
          text-wrap: wrap;
        }
        @media (max-width: 991px) {
          .div-a18 {
            max-width: 100%;
            font-size: 8vw;
            align-self: center;
            margin-left: 13px;
            margin-right: 13px;
          }
        }
        .div-a19 {
          font-size: 1.1rem;
          font-weight: 300;
          color: #646464;
          align-self: start;
          margin-top: 49px;
        }
        @media (max-width: 991px) {
          .div-a19 {
            max-width: 100%;
            margin-top: 0px;
            margin-left: 13px;
            margin-right: 13px;
          }
        }
        .div-a20 {
          align-self: start;
          display: flex;
          margin-top: 46px;
          width: 398px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .div-a21 {
          align-self: start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          width: 293px;
        }
        .div-a22 {
          align-self: stretch;
          display: flex;
          margin-right: 1px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-a22 {
            justify-content: center;
          }
        }
        .img-a5 {
          aspect-ratio: 1.26;
          object-fit: cover;
          object-position: center;
          width: 88px;
          overflow: hidden;
          align-self: center;
          margin-top: auto;
          margin-bottom: auto;
        }
        .img-a6 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 66px;
          overflow: hidden;
          align-self: center;
          margin-top: auto;
          margin-bottom: auto;
        }
        .img-a7 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 77px;
          overflow: hidden;
        }
        .div-a23 {
          align-self: stretch;
          display: flex;
          margin-top: 7px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-a23 {
            justify-content: center;
          }
        }
        .img-a8 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 77px;
          overflow: hidden;
        }
        .img-a9 {
          aspect-ratio: 0.98;
          object-fit: cover;
          object-position: center;
          width: 58px;
          overflow: hidden;
          align-self: center;
          margin-top: auto;
          margin-bottom: auto;
        }
        .img-a10 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 82px;
          overflow: hidden;
          align-self: center;
          margin-top: auto;
          margin-bottom: auto;
        }
        .div-a24 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .img-a11 {
          aspect-ratio: 0.94;
          object-fit: cover;
          object-position: center;
          width: 70%;
          overflow: hidden;
          align-self: start;
        }
        .img-a12 {
          aspect-ratio: 0.98;
          object-fit: cover;
          object-position: center;
          width: 58px;
          overflow: hidden;
          align-self: start;
          margin-top: 18px;
        }
      `}</style>{" "}
    </>
  );
}
