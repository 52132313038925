("use client");
import React, { useState } from "react";
import "./styles.css";
import { Button } from "react-bootstrap";
import AppDownloadModal from "../App_Download_Modal";
import ReactGA from 'react-ga4';


export function CorporateWellnessWhyAssessments(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    
    setShow(true);
  
    ReactGA.event({
      category: 'corporate_wellness_explore_assessments_button',
      action: 'Click',
      label: window.location
      
  });
  }
    


    
   

  return (
    <>
      <AppDownloadModal
        show={show}
        handleClose={handleClose}
        heading="Try out our exlusive assessments and know your emotional and lifestyle stress by downloading our app"
      />
      <div className="corporate-wellness-why">
        <div className="container row">
          <div className="col-md-5">
            <h2 className="corporate-wellness-why-heading">Why Assessments?</h2>
            <div className="corporate-wellness-why-description">
              Innovative Assessments that are professionally crafted to access your overall wellbeing. Discover your
              current state of mental and physical wellness. WellM provide 2 assessments - Joy Care & Self Care, former
              is used to access your emotional state of the employee while the later is used to access the lifestyle of
              the employee with respect to natural laws of wellness.
            </div>
            <Button
              variant="success"
              size="lg"
              style={{
                backgroundColor: "#E77C40",
                borderColor: "transparent",
                borderRadius: "20px",
                marginTop: "6%",
                fontSize: "16px",
              }}
              onClick={handleShow}
            >
              Explore Assesments
            </Button>
          </div>
          <div className="col-md-7">
            <div className="row corporate-wellness-why-areas">
              <div className="col-md-5">
                <div className="corporate-wellness-why-area">
                  <img src="/img/diagnostic.svg" alt="" />
                  <p className="corporate-wellness-why-area-heading">Detailed Report</p>
                  <p className="corporate-wellness-why-area-subheading">
                    Get to the bottom of your diagnosis and identify further course of action.
                  </p>
                </div>
                <div className="corporate-wellness-why-area">
                  <img src="/img/attention-area.svg" alt="" />
                  <p className="corporate-wellness-why-area-heading">Attention Areas</p>
                  <p className="corporate-wellness-why-area-subheading">
                    Understand areas where you need additional support and guidance.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="corporate-wellness-why-area">
                  <img src="/img/mental-progress.svg" alt="" />
                  <p className="corporate-wellness-why-area-heading">Measure your progress</p>
                  <p className="corporate-wellness-why-area-subheading">
                    Find out where you stand and track improvement in functioning.
                  </p>
                </div>
                <div className="corporate-wellness-why-area">
                  <img src="/img/reveal.svg" alt="" />
                  <p className="corporate-wellness-why-area-heading">Reveal strengths & weaknesses</p>
                  <p className="corporate-wellness-why-area-subheading">
                    Identify your strengths to help fortify your treatment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
