("use client");
import React from "react";
import "./styles.css";
import CorporateWellnessServicesCaraousel from "../CW_Services_Carousel/corporate_services";

export function CorporateWellnessServices(props) {
  return (
    <>
      <div className="container corporate-wellness-services">
        <h2 className="corporate-wellness-services-heading">
          Our <span className="gradient-text">Services</span>
        </h2>
        <div className="corporate-wellness-services-subheading">
          A better company culture begins when employees feel that they are heard and valued. Allow us to show you how
          our Employee Well-being Programme can help you do just that.
        </div>
        <CorporateWellnessServicesCaraousel />
      </div>
    </>
  );
}
