"use client"
import React from "react"
import "./feature.css"

export function PersonalWellnessFeature(props) {
  return (
    <>
      <div className="features " id="explore-pw">
        <p className="features-subheading">
          Did you know that a staggering 150 million individuals in India are
          suffering from mental health challenges? Shockingly, only 10-12% of them
          actively pursue treatment. The primary culprit? A lack of awareness and
          limited access to support. Most people aren't even conscious that they’re
          going through mental struggles.
        </p>
        <div className="features-heading">
          But WellM is here to change that with the:
          <br />
          Personal Wellness Program!
        </div>
        <div className="feature-list feature-list-desktop">
          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-assessment.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">Assess, Track & Grow</h3>
                <div className="feature-item-description">
                  How can you develop yourself without knowing your shortcomings?
                  <br />
                  <br />
                  WellM's happiness quiz will help you gain insights into your mental
                  state. Identify the areas for improvement, and embark on a healing
                  journey. 
                  <br />
                  <br />
                  Complement this with a personalized lifestyle plan rooted in
                  natural principles. Ultimately, fostering holistic self-growth. 
                  <br />
                  <br />
                  Move closer to a better you each day!
                </div>
              </div>
            </div>
          </div>
          <div className="feature-item row">
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">Track your Well-being</h3>
                <div className="feature-item-description">
                  Stay on top of your habits and mood with the Wellbeing Tracker. 
                  <br />
                  <br />
                  This daily monitor assists you in keeping tabs on your activities,
                  habits, and emotions. As time progresses, you can review your
                  performance and make necessary enhancements. 
                  <br />
                  And that's not all – WellM's tracker even aids you in forming new
                  habits. Choose a habit, slot it into your calendar, and receive
                  timely reminders for execution. 
                  <br />
                  <br />
                  Consider us your companion on the path to self-improvement.
                  <br />
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="/img/personal-wellness/feature-habit.png"
              className="feature-item-image col-md-6"
              alt="Steps and mood tracker"
            />
          </div>
          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-counselling.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Shape your Mindset with Experts
                </h3>
                <div className="feature-item-description">
                  Take charge of your mental health with our expert coaches. 
                  <br />
                  <br />
                  No more uncomfortable therapy sessions. Receive counselling on your
                  conditions. Explore our roster of adept coaches, and select based
                  on their proficiency.
                  <br />
                  <br />
                  Remember you're in control! Pick your language, schedule, and
                  preferred mode. Shape a session that perfectly suits your ease and
                  liking.
                </div>
              </div>
            </div>
          </div>
          <div className="feature-item row">
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Engage with People Who Get It
                </h3>
                <div className="feature-item-description">
                  WellM's community isn't just any community—it's a place you’ll
                  always belong.
                  <br />
                  <br />
                  Consider it your sanctuary, where you share freely, form genuine
                  bonds, and access the assistance you seek.
                  <br />
                  <br />
                  Requiring time to open up? Not an issue. Share thoughts anonymously
                  and allow others to provide their support.
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="/img/personal-wellness/feature-community.png"
              className="feature-item-image col-md-6"
              alt="Steps and mood tracker"
            />
          </div>

          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-discover.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Access Self-care - Anytime, Anywhere
                </h3>
                <div className="feature-item-description">
                  Self-development isn't a single stride but a collection of small
                  efforts that accumulate over time.
                  <br />
                  <br />
                  In the WellM library, you'll find resources to bolster your
                  well-being.
                  <br />
                  <br />
                  Craving something more enjoyable? WellM's Mind Gym is packed with
                  games and activities to sharpen your mind.
                  <br />
                  <br />
                  All are conveniently available on our app and website, granting you
                  access anytime, anywhere.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-list feature-list-mobile">
          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-assessment.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">Assess, Track & Grow</h3>
                <div className="feature-item-description">
                  How can you develop yourself without knowing your shortcomings?
                  <br />
                  <br />
                  WellM's happiness quiz will help you gain insights into your mental
                  state. Identify the areas for improvement, and embark on a healing
                  journey. 
                  <br />
                  <br />
                  Complement this with a personalized lifestyle plan rooted in
                  natural principles. Ultimately, fostering holistic self-growth. 
                  <br />
                  <br />
                  Move closer to a better you each day!
                </div>
              </div>
            </div>
          </div>
          <div className="feature-item row">
            <img
              loading="lazy"
              src="/img/personal-wellness/feature-habit.png"
              className="feature-item-image col-md-6"
              alt="Steps and mood tracker"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">Track your Well-being</h3>
                <div className="feature-item-description">
                  Stay on top of your habits and mood with the Wellbeing Tracker. 
                  <br />
                  <br />
                  This daily monitor assists you in keeping tabs on your activities,
                  habits, and emotions. As time progresses, you can review your
                  performance and make necessary enhancements. 
                  <br />
                  And that's not all – WellM's tracker even aids you in forming new
                  habits. Choose a habit, slot it into your calendar, and receive
                  timely reminders for execution. 
                  <br />
                  <br />
                  Consider us your companion on the path to self-improvement.
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-counselling.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Shape your Mindset with Experts
                </h3>
                <div className="feature-item-description">
                  Take charge of your mental health with our expert coaches. 
                  <br />
                  <br />
                  No more uncomfortable therapy sessions. Receive counselling on your
                  conditions. Explore our roster of adept coaches, and select based
                  on their proficiency.
                  <br />
                  <br />
                  Remember you're in control! Pick your language, schedule, and
                  preferred mode. Shape a session that perfectly suits your ease and
                  liking.
                </div>
              </div>
            </div>
          </div>
          <div className="feature-item row">
            <img
              loading="lazy"
              src="/img/personal-wellness/feature-community.png"
              className="feature-item-image col-md-6"
              alt="Steps and mood tracker"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Engage with People Who Get It
                </h3>
                <div className="feature-item-description">
                  WellM's community isn't just any community—it's a place you’ll
                  always belong.
                  <br />
                  <br />
                  Consider it your sanctuary, where you share freely, form genuine
                  bonds, and access the assistance you seek.
                  <br />
                  <br />
                  Requiring time to open up? Not an issue. Share thoughts anonymously
                  and allow others to provide their support.
                </div>
              </div>
            </div>
          </div>

          <div className="feature-item row">
            <img
              loading="lazy"
              className="feature-item-image col-md-6"
              src="/img/personal-wellness/feature-discover.png"
              alt="assessment feature"
            />
            <div className="col-md-6" style={{ display: "flex", margin: "auto" }}>
              <div>
                <h3 className="feature-item-heading">
                  Access Self-care - Anytime, Anywhere
                </h3>
                <div className="feature-item-description">
                  Self-development isn't a single stride but a collection of small
                  efforts that accumulate over time.
                  <br />
                  <br />
                  In the WellM library, you'll find resources to bolster your
                  well-being.
                  <br />
                  <br />
                  Craving something more enjoyable? WellM's Mind Gym is packed with
                  games and activities to sharpen your mind.
                  <br />
                  <br />
                  All are conveniently available on our app and website, granting you
                  access anytime, anywhere.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
